import React, { useState } from "react";
import {
  BaseEdge,
  EdgeLabelRenderer,
  // EdgeProps,
  getSmoothStepPath,
} from "reactflow";
import { useDispatch } from "react-redux";
import { getsampleNodeEdgeType } from "../../../store/user/actions";
// import { showNotification } from "../../../util/utility";

export default function CustomEdge({
  id,
  data,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  status,
  markerEnd,
}) {
  // : EdgeProps
  const [isChecked, setChecked] = useState(data.status);
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    // type:"smoothstep",
  });
  // const sampleNodeEdgeType = useSelector((state) => state.sampleNodeEdgeType);
  const dispatch = useDispatch();

  const resetCheckbox = () => {
    // if (status === 1) {
    const newStatus = isChecked ? 0 : 1;
    dispatch(getsampleNodeEdgeType({ id: data.id, status: newStatus }));
    setChecked(!isChecked);
    // } else {
    // showNotification("error", "You are not allowed to access this api");
    // }
  };

  console.log("ID: ", data.id);
  return (
    <>
      <BaseEdge
        type="smoothstep"
        path={edgePath}
        markerEnd={markerEnd}
        style={style}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: "all",
          }}
          className="nodrag nopan"
        >
          <div>
            <label className="switch_slide">
              <input
                type="checkbox"
                name={data.label + id}
                id={data.label + id}
                checked={isChecked}
                disabled={status === 0}
              />
              <div
                className="slider round"
                htmlFor={data.label + id}
                onClick={resetCheckbox}
              >
                <span className="on">AND</span>
                <span className="off">OR</span>
              </div>
            </label>
          </div>
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
