import React, { useEffect, useState } from "react";
import Table from "../../../component/Table/Table";
import { Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { gethistoryData } from "../../../store/user/actions";
import { useParams } from "react-router-dom";
import AudioPlayer from "../../ViewSubmissionDetails/component/AudioPlayer";
import DefaultModal from "../../../component/DefaultModal/DefaultModal";

const HistoryTable = () => {
  const historyData = useSelector((state) => state.historyData);
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(gethistoryData(id));
  }, [dispatch, id]);

  const [show, setShow] = useState(false);
  const [Image, setImage] = useState("");

  const handleImageOpen = (i) => {
    setImage(i);
    setShow(!show);
  };

  const FakeTableData = historyData.data.map((item, i) => {
    // const audioPlayer = item.audio ? (
    //   <AudioPlayer audioSrc={item.voice_note} />
    // ) : (
    //   <div className="diseble_play">
    //     <i className="fa fa-play-circle" aria-hidden="true"></i>
    //   </div>
    // );
    return [
      <>
        <h6>{i + 1}</h6>
      </>,
      <div>
        <h6>{item.node}</h6>
      </div>,
      <div className="view_im">
        <img
          onClick={() => handleImageOpen(item.file)}
          src={item.file}
          alt=""
        />
      </div>,
      <div>
        <h6>{item.sampleRequest}</h6>
      </div>,
      <>
        <h6> {item.remark}</h6>
      </>,
      // <div className='audio_custom'>
      //   <ReactPlayer url={item.voice_note} controls={true} style={{width:"100%",height:"60px"}}/>
      // </div>,
      <AudioPlayer
        audioSrc={item.voice_note}
        disabled={item.voice_note === "" ? true : false}
      />,
      // <div className="audo_io">{audioPlayer}</div>,
    ];
  });

  const TemplateTableData = [
    {
      heading: "Sr.No.",
    },
    {
      heading: "Node",
    },
    {
      heading: "Image",
    },
    {
      heading: "Sample Request",
    },
    {
      heading: "Remark",
    },
    {
      heading: "Voice Note",
    },
  ];

  console.log("HISTORY DATA: ", historyData);
  return (
    <div>
      <div className="table-bg">
        <Table
          gridTemplateColumnsHeader="10% 20% 10% 20% 20% 20%"
          gridTemplateColumnsBody="10% 20% 10% 20% 20% 20%"
          columns={TemplateTableData}
          data={FakeTableData}
        />
      </div>
      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="remark-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <img className="Image-row-submission" src={Image} alt="" />
      </DefaultModal>
      {/* Modal Component ends here */}
    </div>
  );
};

export default HistoryTable;
