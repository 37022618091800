import React from "react";

import { Helmet } from "react-helmet";
import NodeComplete from "./components/NodeComplete";
import DashboardCard from "./components/DashboardCard";
import CollectionComplete from "./components/CollectionComplete";
import WorkloadList from "./components/WorkloadList";
import CostRangeWiseSample from "./components/CostRangeWiseSample";

const NodeWiseTask = () => {
  return (
    <div className="dashboard-bg">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dashboard | Sample Dashboard</title>
      </Helmet>
      <DashboardCard />
      <WorkloadList />
      <NodeComplete />
      <CollectionComplete />
      <CostRangeWiseSample />
    </div>
  );
};

export default NodeWiseTask;
