import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Table from "../../../component/Table/Table";
import { Pagination } from "antd";
import { Select } from "antd";
import Loader from "../../../component/Loader";
const { Option } = Select;

const NodeSelectTable = ({
  requestList,
  removeRequest,
  handleSearch,
  handleChange,
  userList,
  userWorkload,
  submitHendle,
  isLoading,
}) => {
  const FakeTableData = requestList.data.designation_list.map((item, i) => [
    <>
      <h6>{i + 1} </h6>
    </>,
    <div>
      <h6>{item.designation_name}</h6>
    </div>,
    <>
      <Select
        showSearch
        placeholder="Select a value"
        optionFilterProp="children"
        onSearch={(value) =>
          handleSearch(value, i, item.operator_designation_id)
        }
        onChange={(value) =>
          handleChange(value, i, item.operator_designation_id)
        }
        defaultActiveFirstOption={false}
        filterOption={false}
        options={userList[i]}
      />
    </>,
    <>
      <h6 style={{ color: "#00BB5A" }}>{userWorkload[i]?.availableHours}hr</h6>
    </>,
    <>
      <h6>{userWorkload[i]?.allocatedHours}hr</h6>
    </>,
    <>
      <h6 style={{ color: "#FF0000" }}>{userWorkload[i]?.pandingHours}hr</h6>
    </>,
  ]);

  const TemplateTableData = [
    {
      heading: "Sr.No.",
    },
    {
      heading: "Designation",
    },
    {
      heading: "Select User",
    },
    {
      heading: "Available Time",
    },
    {
      heading: "Allocated Time",
    },
    {
      heading: "Pending Time",
    },
  ];

  return (
    <div>
      <div className="position-relative">
        {/* {
        requestList.isLoading && <div className="loader_bg"><Loader /></div>
      } */}
        <div className="node_box">
          <div className="node-flex">
            {requestList.data.list.map((item, i) => (
              <div className="node-im" key={i}>
                <div>
                  <img className="main-img" src={item.samplePicture} />
                  <img
                    className="close_icon"
                    src="/Images/close_icon.svg"
                    onClick={() => removeRequest(item.id)}
                  />
                </div>
                <h6>{item.sampleRequestId}</h6>
              </div>
            ))}
          </div>
        </div>
        <div className="">
          <Table
            gridTemplateColumnsHeader="10% 20% 20% 20% 20% 10%"
            gridTemplateColumnsBody="10% 20% 20% 20% 20% 10%"
            columns={TemplateTableData}
            //   data={tableData}
            data={FakeTableData}
          />
        </div>
        {requestList.data.list.length > 0 && (
          <div className="remark-buttons">
            <button className="dark-but" type="button" onClick={submitHendle}>
              {isLoading ? <Loader /> : "Submit"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default NodeSelectTable;
