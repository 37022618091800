import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../../component/Table/Table";
import { Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";
import AddParameterValue from "./AddParameterValue";
import DefaultModal from "../../../component/DefaultModal/DefaultModal";
import { getsampleCategoryList } from "../../../store/user/actions";

const RequestTable = (props) => {
  const [current, setCurrent] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [show, setShow] = useState(false);
  const [currentId, setCurrentId] = useState(0);
  const [parameterValue, setParameterValue] = useState([]);
  const sampleCategoryList = useSelector((state) => state.sampleCategoryList);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getsampleCategoryList({}));
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setCurrent(1);
    const payload = { ...props.selectBox, page: 1 };
    payload.parameter = JSON.stringify(payload.parameter);
    if (!show) {
      dispatch(getsampleCategoryList(payload));
    }
    // eslint-disable-next-line
  }, [props.selectBox, show]);
  // console.log(sampleCategoryList);
  const onChange = (page) => {
    setCurrent(page);

    const payload = { ...props.selectBox, page: page };
    if (Array.isArray(payload.parameter)) {
      payload.parameter = JSON.stringify(payload.parameter);
    }
    dispatch(getsampleCategoryList(payload));
  };
  useEffect(() => {
    setTotalData(sampleCategoryList.data.total);
    // let sno = (current - 1) * 10 + 1;
    const FakeTableData =
      sampleCategoryList &&
      sampleCategoryList.data.data.map((item, i) => {
        return [
          <>
            <h6>{i + 1 + 10 * (current - 1)}</h6>
          </>,
          <div>{item.category_name}</div>,
          <>
            <h6>{item.trail}</h6>
          </>,
          <>
            <h6>{item.node_name}</h6>
          </>,
          <>
            {item?.parameter_value?.map((parameter) => (
              <>
                {parameter.name}:{parameter.value},{" "}
              </>
            ))}
          </>,
          <div className="sample-but">
            <Link
              onClick={() => {
                setCurrentId(item.id);
                setParameterValue(item.parameter_value);
                setShow(true);
              }}
            >
              Add PV
            </Link>
          </div>,
        ];
      });
    setTableData(FakeTableData);
  }, [sampleCategoryList]);
  const TemplateTableData = [
    {
      heading: "Sr.No.",
    },
    {
      heading: "Sample Category",
    },
    {
      heading: "Trail",
    },
    {
      heading: "Node",
    },
    {
      heading: "Parameters values",
    },
    {
      heading: "Action",
    },
  ];

  console.log("FILTER VALUE: ", props.selectBox);
  return (
    <div className="table_text">
      <div className="position-relative">
        {/* {
        collectionDelay.isLoading && <div className='loader_bg'><Loader /></div>
      }
     */}
        <div className="table-bg">
          <Table
            gridTemplateColumnsHeader="10% 15% 20% 20% 20% 15%"
            gridTemplateColumnsBody="10% 15% 20% 20% 20% 15%"
            columns={TemplateTableData}
            data={tableData}
          />
          <Pagination current={current} onChange={onChange} total={totalData} />
        </div>
      </div>
      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="remark-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Add Parameter Value</h4>
          <div className="close-icon">
            <img
              alt=""
              src="/Images/closeBlack.svg"
              onClick={() => setShow(false)}
            />
          </div>
        </div>
        <AddParameterValue
          categoryId={currentId}
          parameterValue={parameterValue}
          mClose={setShow}
        />
      </DefaultModal>
    </div>
  );
};

export default RequestTable;
