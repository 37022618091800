import React from 'react'
import { Helmet } from 'react-helmet'
import SettingTab from './component/SettingTab'

const Settings = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Settings | Sample Dashboard</title>
      </Helmet>
      <SettingTab/>
    </div>
  )
}

export default Settings
