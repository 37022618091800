import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { AddSampleCollectionActions } from "../../../store/user/actions";
import { toast } from "react-toastify";
import { getToken } from "../../../util/Common";
import axios from "axios";
import Loader from "../../../component/Loader";

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const SampleCollectionModal = () => {
  const dispatch = useDispatch();
  const addsamplecollect = useSelector((state) => state.addsamplecollect);

  const [file, setFile] = useState(null);
  const [name, setName] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const AddSampleCollection = async (e) => {
    e.preventDefault();
    if (!name) {
      toast.error("Sample Collection Name Is Required");
      return;
    }

    if (!file) {
      toast.error("Image Is Required");
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.set("name", name);
    formData.set("image", file);

    await axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL + `/add_sample_collection`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: formData,
    })
      .then((response) => {
        console.log(response?.data);
        if (response?.data?.status === 1) {
          toast.success(response?.data?.message);
          setIsLoading(false);
        } else if (response?.data?.status === 0) {
          //   console.log(
          //     "RESPONSE: ",
          //     response?.data?.data?.error?.reference_url[0]
          //   );
          toast.error(response?.data?.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
      });

    // dispatch(AddSampleCollectionActions({ name: name, image: file }));
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    multiple: false, // Allow only one image to be selected at a time
    onDrop: (acceptedFiles) => {
      const selectedFile = acceptedFiles[0];
      setFile(
        Object.assign(selectedFile, {
          preview: URL.createObjectURL(selectedFile),
        })
      );
    },
  });

  //   useEffect(() => {
  //     if (addsamplecollect && addsamplecollect?.isSuccess === true && IsCreated) {
  //       toast.success("Success");
  //       setIsCreated(false);
  //     }
  //     if (
  //       addsamplecollect &&
  //       addsamplecollect?.isSuccess === false &&
  //       IsCreated
  //     ) {
  //       toast.error(addsamplecollect?.errorMessage || "Error Occurred");
  //       setIsCreated(false);
  //     }
  //   }, [addsamplecollect, IsCreated]);

  console.log("SAMPLE COLLECTION DATA: ", addsamplecollect);

  return (
    <div className="collection-modal-sample">
      <form onSubmit={(e) => AddSampleCollection(e)}>
        <div>
          <label htmlFor="">Sample Collection Name</label>
          <br />
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <div {...getRootProps({ className: "upload-file" })}>
            <input {...getInputProps()} />
            {!file ? (
              <div>
                <img src="/Images/cloud.svg" alt="" />
                <h5>Upload Image</h5>
                <p>
                  Drag and Drop file here Or <span>Choose File</span>
                </p>
                <small>Support formats: JPG, PNG | Minimum size: 5 MB</small>
              </div>
            ) : (
              <div style={thumb} key={file.name}>
                <div style={thumbInner}>
                  <img
                    src={file.preview}
                    style={img}
                    alt=""
                    onLoad={() => {
                      URL.revokeObjectURL(file.preview);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="remark-buttons">
          <button className="dark-but" type="submit">
            {isLoading ? <Loader /> : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SampleCollectionModal;
