import React, { useEffect, useMemo, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Link } from "react-router-dom";
import { getcalendarData, getmonthPending } from "../../../store/user/actions";
import { useDispatch, useSelector } from "react-redux";

const localizer = momentLocalizer(moment);

const SampleCalender = ({ selectBox }) => {
  const [events, setEvents] = useState([]);
  const [defaultView, setDefaultView] = useState("month");
  const [defaultDate, setDefaultDate] = useState(new Date());
  const monthPending = useSelector((state) => state.monthPending);
  const calendarData = useSelector((state) => state.calendarData);
  const dispatch = useDispatch();
  const { views } = useMemo(
    () => ({
      views: {
        month: true,
        week: true,
        day: true,
      },
    }),
    []
  );

  const { leftView } = useMemo(
    () => ({
      leftView: {
        Today: true,
        next: false,
        prev: false,
      },
    }),
    []
  );
  useEffect(() => {
    setEvents([]);
    const event = calendarData.data.map((item) => {
      return {
        start: moment(item.start).toDate(),
        end: moment(item.end).toDate(),
        title: (
          <div className="event-box">
            <div className="event-title">
              <img
                src={item.image === "" ? "/Images/dummy.jpg" : item.image}
                alt=""
              />
              {/* <img src={item.image} /> */}
              <h6 style={{ color: "color: " }}>{item.name}</h6>
              {/* <h6>{item.start}</h6>
    <h6>{item.end}</h6> */}
            </div>
            {/* <Link>7 More</Link> */}
            <div className="event-disc">
              <img
                alt=""
                src={
                  item.userImage === "" ? "/Images/dummy.jpg" : item.userImage
                }
              />
              {/* <img src={item.userImage} /> */}
              <h5>{item.userName}</h5>
            </div>
          </div>
        ),
      };
    });

    setEvents(event);
  }, [calendarData]);

  useEffect(() => {
    dispatch(getmonthPending({}));
    //calender Data
    dispatch(getcalendarData(selectBox));
    // eslint-disable-next-line
  }, [selectBox]);

  const monthList = (month) => {
    setDefaultDate(new Date(month));
    setDefaultView("month");
  };
  const handleViewChange = (newView) => {
    setDefaultView(newView);
  };
  const handleSelectSlot = (slotInfo) => {
    setDefaultDate(new Date(slotInfo));
  };

  return (
    <div className="custom-calender">
      <div className="row" style={{ padding: "0px 11px" }}>
        {monthPending.data.map((item) => {
          return (
            <div
              className="col-lg-2 col-md-2"
              onClick={() => monthList(item.month)}
            >
              <div className="month-box">
                <h6>{item.month}</h6>
                <Link className="pending-Link">
                  {item.totalPanding} Pending
                </Link>
              </div>
            </div>
          );
        })}
      </div>
      <Calendar
        onNavigate={(event) => handleSelectSlot(event)}
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "100%" }}
        views={views}
        view={defaultView}
        onView={(view) => handleViewChange(view)}
        date={defaultDate}
        leftView={leftView}
        dayLayoutAlgorithm="no-overlap"
        // dayLayoutAlgorithm={(params) => {
        //   return overlap({ ...params, minimumStartDifference: 15 })
        // }}
      />
    </div>
  );
};

export default SampleCalender;
