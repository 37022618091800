import React, { useEffect, useState } from "react";
import Table from "../../../component/Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { getnodeCriticalityUpdateBulk } from "../../../store/user/actions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../util/Common";
import axios from "axios";
import Loader from "../../../component/Loader";

const AssignTable = ({ requestList, removeRequest, ids }) => {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState([]);
  const dispatch = useDispatch();
  const nodeCriticalityUpdateBulk = useSelector(
    (state) => state.nodeCriticalityUpdateBulk
  );

  const handleChange = (index, node_group_id, name, value) => {
    const oldValue = { ...formValues };
    if (!oldValue[index]) {
      oldValue[index] = {
        criticality_id: "",
        group_id: node_group_id,
        buffer_time: "",
      };
    }
    oldValue[index][name] = value;
    oldValue[index]["group_id"] = node_group_id;
    setFormValues(oldValue);
  };
  const FakeTableData = requestList?.data?.group_list?.map((item, i) => [
    <>
      <h6>{i + 1} </h6>
    </>,
    <div>
      <h6>{item.group_name}</h6>
    </div>,
    <></>,
    <div className="custom-select-table">
      <select
        className="selectArea assign_select"
        onChange={(e) =>
          handleChange(i, item.node_group_id, "criticality_id", e.target.value)
        }
        defaultValue=""
      >
        <option value="">Please Select</option>
        {requestList.data.criticalitylist.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>
    </div>,
    <></>,
    <>
      <input
        placeholder="Enter Buffer Time"
        className="buffer_input"
        onChange={(e) =>
          handleChange(i, item.node_group_id, "buffer_time", e.target.value)
        }
      />
    </>,
  ]);

  // const [isUpdated, setIsUpdated] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const submitHendle = async () => {
    // setIsUpdated(true);
    setIsLoading(true);

    await axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL + `/nodegroup_criticality_update_bulk`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: {
        request_id: ids,
        criticality: formValues,
      },
    })
      .then((response) => {
        console.log("RESPONSE: ", response);
        if (response && response?.data?.status === 1) {
          toast.success(response?.data?.message);
          navigate("/node-wise-task");
          setIsLoading(false);
          // console.log("RESPONSE: ", response?.data?.data);
        } else if (response && response?.data?.status === 0) {
          toast.error(response && response?.data?.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
      });

    // dispatch(
    //   getnodeCriticalityUpdateBulk({ request_id: ids, criticality: formValues })
    // );
  };

  const TemplateTableData = [
    {
      heading: "Sr.No.",
    },
    {
      heading: "Node Group Name",
    },
    {
      heading: "",
    },
    {
      heading: "Choose Criticality",
    },
    {
      heading: "",
    },
    {
      heading: "Buffer Time (Min)",
    },
  ];

  console.log("nodeCriticalityUpdateBulk", nodeCriticalityUpdateBulk);

  // useEffect(() => {
  //   if (
  //     nodeCriticalityUpdateBulk &&
  //     nodeCriticalityUpdateBulk?.isSuccess === true &&
  //     isUpdated
  //   ) {
  //     navigate("/node-wise-task");
  //     toast.success("Success");
  //     setIsUpdated(false);
  //   } else if (
  //     nodeCriticalityUpdateBulk &&
  //     nodeCriticalityUpdateBulk?.isSuccess === false &&
  //     isUpdated
  //   ) {
  //     toast.error(nodeCriticalityUpdateBulk?.errorMessage);
  //     setIsUpdated(false);
  //   }
  // }, [nodeCriticalityUpdateBulk, isUpdated, navigate]);

  return (
    <div>
      <div className="position-relative">
        <div className="node_box">
          <div className="node-flex">
            {requestList.data.list.map((item, i) => (
              <div className="node-im" key={i}>
                <div>
                  <img className="main-img" src={item.samplePicture} />
                  <img
                    className="close_icon"
                    src="/Images/close_icon.svg"
                    onClick={() => removeRequest(item.id)}
                  />
                </div>
                <h6>{item.sampleRequestId}</h6>
              </div>
            ))}
          </div>
        </div>

        <div className="">
          <Table
            gridTemplateColumnsHeader="8% 22% 15% 20%  15% 20%"
            gridTemplateColumnsBody="8% 22% 15% 20% 15% 20%"
            columns={TemplateTableData}
            //   data={tableData}
            data={FakeTableData}
          />
        </div>
        {requestList.data.list.length > 0 && (
          <div className="remark-buttons">
            <button className="dark-but" type="button" onClick={submitHendle}>
              {isLoading ? <Loader /> : "Submit"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AssignTable;
