import React, { useState } from "react";
import RejectionTable from "./component/RejectionTable";
import RejectionFilter from "./component/RejectionFilter";
import { Helmet } from "react-helmet";

const RejectionRequest = () => {
  const [selectBox, setSelectBox] = useState();

  const filterValue = (selectValue) => {
    setSelectBox(selectValue);
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rejection Request | Sample Dashboard</title>
      </Helmet>

      <div className="heading">
        <h2>Rejection Request</h2>
      </div>
      <RejectionFilter filterValue={filterValue} />
      <RejectionTable selectBox={selectBox} />
    </div>
  );
};

export default RejectionRequest;
