import React, { Fragment, useEffect } from "react";
import "../../css/fit-variation.css";
import DataTable from "../../component/DataTable/Table.component";
import { Pagination } from "antd";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getGarmentFitShow } from "../../store/user/actions";
import Loader from "../../component/Loader";

const Header = [
  `Measurement Name`,
  `Measurement Point From`,
  `Measurement Point To`,
  `Measurement in Inches`,
  `Grading`,
  `Remarks`,
];

const FitVariations = () => {
  const dispatch = useDispatch();

  //   garment Fit Show State
  const garmentFitShow = useSelector((state) => state.garmentFitShow);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getGarmentFitShow(id));
    }
  }, [id, dispatch]);

  console.log("GARMENT FIT SHOW: ", garmentFitShow);

  return (
    <div>
      <div className="fit-variations">
        <h1>Fit Variations</h1>
        {garmentFitShow?.isLoading ? (
          <div className="loader_icons">
            <Loader />
          </div>
        ) : (
          <Fragment>
            <div className="table-container-pagination">
              <DataTable
                header={Header}
                garmentFitShow={garmentFitShow && garmentFitShow?.data}
                isVariation={true}
              />
            </div>
            <div className="mt-3">
              {/* <Pagination current={1} onChange={onChange} total={totalData} /> */}
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default FitVariations;
