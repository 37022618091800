import React, { useEffect, useState } from "react";
import { Button, Select } from "antd";
import "../../../css/setting.css";
import { useDispatch, useSelector } from "react-redux";
import {
  createCategorySizeRatio,
  getCategorySizeDataActions,
  getfilterData,
} from "../../../store/user/actions";
import { convertDropdown } from "../../CreateFit/CreateFit";
import axios from "axios";
import { getToken } from "../../../util/Common";
import { toast } from "react-toastify";
import Loader from "../../../component/Loader";
import { GrSubtractCircle } from "react-icons/gr";

const CategorySizeRatio = () => {
  const dispatch = useDispatch();

  const filterData = useSelector((state) => state.filterData);
  const getCategorySizeData = useSelector((state) => state.getCategorySizeData);

  // category size Ratio Create
  const categorySizeRatio = useSelector((state) => state.createCategory);

  const [product_cat, setProduct_cat] = useState([]);
  const [size_arr, setSizes_arr] = useState([]);
  const [categories, setCategories] = useState([{ id: "", size: [] }]);

  useEffect(() => {
    dispatch(getfilterData({}));
  }, [dispatch]);

  useEffect(() => {
    if (filterData && filterData?.isSuccess === true) {
      let product_categories_arr = convertDropdown(
        filterData?.data?.product_categories
      );
      setProduct_cat(product_categories_arr);
    }
  }, [filterData]);

  const [category_Id, setCategory_Id] = useState("");

  const handleInputChange = (val, catIndex, sizeId) => {
    // console.log("VAL: ", val);
    setCategories((prevCategories) => {
      const updatedCategories = [...prevCategories];
      const category = updatedCategories[catIndex];

      const sizeIndex = category.size.findIndex((size) => size.id === sizeId);
      if (sizeIndex >= 0) {
        category.size[sizeIndex].ratio = Number(val);
      } else {
        category.size.push({ id: sizeId, ratio: Number(val) });
      }

      updatedCategories[catIndex] = category;
      return updatedCategories;
    });
  };

  console.log("Size Arr: ", size_arr);

  const addCategory = () => {
    // Initialize size array with empty ratios
    const newSizes = size_arr.map((size) => ({
      id: size.value,
      ratio: 0,
      label: size.label,
    }));
    setCategories((prevCategories) => [
      ...prevCategories,
      { id: "", size: [] },
    ]);
  };

  // const [category_Fy, setCategory_Fy] = useState("");

  const handleCategoryChange = (value, index) => {
    setCategory_Id(value);
    console.log("VALUE: ", value, index);
    dispatch(getCategorySizeDataActions(value));
    setCategories((prevCategories) => {
      const updatedCategories = [...prevCategories];
      updatedCategories[index].id = value;
      return updatedCategories;
    });
  };

  const [isCreated, setIsCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const transformData = (data) => {
    return {
      category: data.map((item) => ({
        id: String(item.id),
        size: item.size.map((s) => ({
          id: s.id,
          ratio: parseInt(s.ratio, 10),
        })),
      })),
    };
  };

  const handleSubmit = () => {
    setIsCreated(true);

    const transformedData = transformData(categories);
    console.log("TRANSFORMED DATA: ", transformedData.category?.length);

    let hasInvalidCategory = false;
    let hasValidCategory = false;

    transformedData.category.forEach((i) => {
      if (i.id === "" && Array.isArray(i.size) && i.size.length <= 0) {
        hasInvalidCategory = true;
      } else if (i.id !== "" && Array.isArray(i.size) && i.size.length > 0) {
        hasValidCategory = true;
      }
    });

    if (hasInvalidCategory) {
      toast.error("Please Choose Data");
    } else if (hasValidCategory) {
      // No invalid categories, but at least one valid category exists
      dispatch(createCategorySizeRatio(transformedData));
    } else {
      // No valid categories
      toast.error("Please Choose Data");
    }
  };

  // console.log("Category Size Data: ", getCategorySizeData);

  useEffect(() => {
    if (getCategorySizeData && getCategorySizeData?.isSuccess === true) {
      let size_arr = convertDropdown(getCategorySizeData?.data);

      setCategories((prevCategories) => {
        const updatedCategories = prevCategories.map((category) => {
          // console.log("CATEGORY ID MAPPING: ", category.id);
          // console.log("CATEGORY ID", category_Id);
          // console.log("SIZE ARR: ", size_arr);
          if (category.id === category_Id) {
            category.size = size_arr.map((size) => ({
              id: size.id,
              ratio: 0,
              label: size.label,
            }));
          }
          return category;
        });
        return updatedCategories;
      });

      setSizes_arr(size_arr);
    }
  }, [getCategorySizeData, category_Id]);
  console.log("CATEGORIES: ", category_Id);

  useEffect(() => {
    const getcategoryRatio = async () => {
      setIsLoading(true);
      const token = await getToken();
      const headers = {
        Authorization: "Bearer " + token,
      };
      axios
        .post(
          process.env.REACT_APP_API_URL + `/sample/category_size_ratio_list`,
          {},
          { headers }
        )
        .then((response) => {
          setCategories(response?.data);
          setIsLoading(false);
          // setIsLoading(response)
          // console.log("RESPONSE RATIO: ", response);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("Error: ", err);
        });
    };

    getcategoryRatio();
  }, []);

  console.log("Category Size Ratio: ", categorySizeRatio);

  useEffect(() => {
    if (
      categorySizeRatio &&
      categorySizeRatio?.isSuccess === true &&
      isCreated
    ) {
      toast.success("Category Size Ratio Created Successfully");
      setIsCreated(false);
    }
  }, [categorySizeRatio, isCreated]);

  console.log("CATEGORIES: ", categories);

  const [isCategoryDelete_Loading, SetisCategoryDelete_Loading] =
    useState(false);

  const handleCategoryRemove = async (cat, index) => {
    // toast.success(index);
    // console.log("CATEGORY ID: ", cat.id);
    // toast.success(cat.id);

    if (cat.id === "") {
      setCategories((prevCategories) =>
        prevCategories.filter((_, i) => i !== index)
      );
      return;
    }

    SetisCategoryDelete_Loading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/sample/category_size_ratio_delete/${cat.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      console.log("RESPONSE:", response);

      if (response && response.data && response.status === 200) {
        // Handle success scenario
        toast.success("Data Deleted Successfully.");
        SetisCategoryDelete_Loading(false);
        const getcategoryRatio = async () => {
          setIsLoading(true);
          const token = await getToken();
          const headers = {
            Authorization: "Bearer " + token,
          };
          axios
            .post(
              process.env.REACT_APP_API_URL +
                `/sample/category_size_ratio_list`,
              {},
              { headers }
            )
            .then((response) => {
              setCategories(response?.data);
              setIsLoading(false);
              // setIsLoading(response)
              // console.log("RESPONSE RATIO: ", response);
            })
            .catch((err) => {
              setIsLoading(false);
              console.log("Error: ", err);
            });
        };

        getcategoryRatio();
      } else {
        // Handle error scenario
        toast.error(response.data.message || "Failed to submit data.");
        SetisCategoryDelete_Loading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.response?.data?.message || "Failed to submit data.");
      SetisCategoryDelete_Loading(false);
    }
  };

  return (
    <>
      <div>
        {isLoading ? (
          <div className="loader_icons">
            <Loader />
          </div>
        ) : (
          <>
            {categories.map((cat, catIndex) => (
              <div key={`cat-${catIndex}`}>
                <div className="row">
                  <div className="col-md-4 col-lg-4 col-12 mb-2 px-4">
                    <label className="select-label-bold">
                      Category - (Product Category)
                    </label>
                    <Select
                      defaultValue={cat.name || cat.id}
                      options={product_cat}
                      onChange={(value) =>
                        handleCategoryChange(value, catIndex)
                      }
                    />
                  </div>
                  <div className="col-md-4 col-lg-4 col-12 mb-2 px-4 delete-button">
                    <Button onClick={() => handleCategoryRemove(cat, catIndex)}>
                      <GrSubtractCircle />
                    </Button>
                  </div>
                </div>
                <div className="category-size-ratio-grid-row">
                  {cat?.size?.map((size, sizeIndex) => (
                    <div key={`${size.id}-${sizeIndex}`}>
                      <label className="select-label-bold">
                        {size.name || size.label}
                      </label>
                      <input
                        placeholder={size.name || size.label}
                        className="input-custom"
                        value={size.ratio}
                        onChange={(e) =>
                          handleInputChange(e.target.value, catIndex, size.id)
                        }
                        type="number"
                        style={{
                          height: "48px",
                          width: "100%",
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}

            <div className="remark-buttons category-size-row">
              <button
                className="dark-but mx-2"
                type="button"
                onClick={addCategory}
              >
                Add
              </button>
              <button className="dark-but" type="button" onClick={handleSubmit}>
                {categorySizeRatio?.isLoading === true ? <Loader /> : "Submit"}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CategorySizeRatio;
