import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import DataTable from "../../component/DataTable/Table.component";
import { useDispatch, useSelector } from "react-redux";
import { getNewLaunchesReportActions } from "../../store/user/actions";

const Header = [
  "Sr.No.",
  "Picture",
  "Basic Info",
  "Phase",
  "Total Qty",
  "Colors and Qty",
  <>
    Inventory Date <br /> and Qty
  </>,
  <>
    Qty at <br /> Nodes Currently
  </>,
  <>
    Sales and <br /> Returns
  </>,
];

const xyz = [
  {
    sample_request_id: 139,
    image:
      "files/SampleRequest/139S8hMdI4OsUTT3xAJAnnF801Nk7zzwbqvgtJk8Fwr.png",
    base_info: {
      sample_request_no: "SR-135-2024",
      group_name: "T16",
      name: "",
      name1: "",
    },
    other: [
      {
        phase: "Phase 1",
        qty: "1216",
        manufacture_order_id: "1",
        color_ratio: "NBWH-1200, BLWH-0",
        inventory_date_and_qty: "",
        qty_current_node: "",
        sale_and_return: {
          sale: 74769,
          return: 0,
          final_inventry: 0,
        },
      },
      {
        phase: "Phase 1",
        qty: "1216",
        manufacture_order_id: "1",
        color_ratio: "NBWH-1200, BLWH-0",
        inventory_date_and_qty: "",
        qty_current_node: "",
        sale_and_return: {
          sale: 74769,
          return: 0,
          final_inventry: 0,
        },
      },
    ],
  },
  {
    sample_request_id: 138,
    image:
      "files/SampleRequest/138UIaLUOF3nCEWkFWjwG7Gm0dNMk9GyuWr3iyEH3Ol.png",
    base_info: {
      sample_request_no: "SR-134-2024",
      group_name: "T1",
      name: "",
      name1: "",
    },
    other: [],
  },
  {
    sample_request_id: 137,
    image:
      "files/SampleRequest/137zPVN8b710ckva6KU3SX5NCC693LyCa2jQQJKcyCy.jpg",
    base_info: {
      sample_request_no: "SR-133-2024",
      group_name: "T51",
      name: "",
      name1: "",
    },
    other: [],
  },
  {
    sample_request_id: 136,
    image:
      "files/SampleRequest/136t6Bk5hBuns50H4na5JDDiscuiTosVzJUnT72nh0k.png",
    base_info: {
      sample_request_no: "SR-132-2024",
      group_name: "T51",
      name: "",
      name1: "",
    },
    other: [],
  },
  {
    sample_request_id: 135,
    image:
      "files/SampleRequest/135jijvQQVGYRUf5W4NagzPsW19nCAJqDcdRsLHwrzN.png",
    base_info: {
      sample_request_no: "SR-131-2024",
      group_name: "T25",
      name: "",
      name1: "",
    },
    other: [],
  },
  {
    sample_request_id: 134,
    image:
      "files/SampleRequest/134Pk2vapI1bKzg1F30pUfbIN94gAPqoNFoS1qVg4M2.png",
    base_info: {
      sample_request_no: "SR-130-2024",
      group_name: "T1",
      name: "",
      name1: "",
    },
    other: [],
  },
  {
    sample_request_id: 133,
    image:
      "files/SampleRequest/1336Aob0R5oLz4S5ZdbTt8TkG8mDJVqtuA3Ps19TgMy.jpg",
    base_info: {
      sample_request_no: "SR-129-2024",
      group_name: "T1",
      name: "",
      name1: "",
    },
    other: [],
  },
  {
    sample_request_id: 132,
    image:
      "files/SampleRequest/132v9022YcsmWsPJJ8Dr7sdZHkqFVdENcPQm8nY3Ovc.png",
    base_info: {
      sample_request_no: "SR-128-2024",
      group_name: "T1",
      name: "",
      name1: "",
    },
    other: [],
  },
  {
    sample_request_id: 131,
    image:
      "files/SampleRequest/1310iQS1XhfGBVwfsnmOzvCNQ6TQyEqddCV41D85JjQ.png",
    base_info: {
      sample_request_no: "SR-127-2024",
      group_name: "T2",
      name: "",
      name1: "",
    },
    other: [],
  },
  {
    sample_request_id: 130,
    image:
      "files/SampleRequest/130kL3pYPNbWe81qBre7xxkcQYslgtcCsMMpPAs5x46.png",
    base_info: {
      sample_request_no: "SR-126-2024",
      group_name: "T51",
      name: "",
      name1: "",
    },
    other: [],
  },
  {
    sample_request_id: 129,
    image:
      "files/SampleRequest/129QQzwk4WybYQWKwfmDG4r5ygzbiklbLMT4k3k6iX7.png",
    base_info: {
      sample_request_no: "SR-125-2024",
      group_name: "T3",
      name: "",
      name1: "",
    },
    other: [],
  },
  {
    sample_request_id: 128,
    image:
      "files/SampleRequest/128xm8E974uGXnLmaOS47OMfWCdtggHruQhliUMoGzY.png",
    base_info: {
      sample_request_no: "SR-125-2024",
      group_name: "T3",
      name: "",
      name1: "",
    },
    other: [],
  },
  {
    sample_request_id: 127,
    image:
      "files/SampleRequest/127qq6OqEukPJaigqWmEtqZWgVWeSviRr43LwNfWoHr.png",
    base_info: {
      sample_request_no: "SR-124-2024",
      group_name: "T3",
      name: "",
      name1: "",
    },
    other: [],
  },
  {
    sample_request_id: 126,
    image:
      "files/SampleRequest/1269NlRpiykiI4Vn3DNxIdSHsOEn9SBpudD7pcDgM0b.png",
    base_info: {
      sample_request_no: "SR-123-2024",
      group_name: "T1",
      name: "",
      name1: "",
    },
    other: [],
  },
  {
    sample_request_id: 125,
    image:
      "files/SampleRequest/1251gRkBywcar3NneAs5J71AvaAnkFGQoCIMSKgKgou.png",
    base_info: {
      sample_request_no: "SR-122-2024",
      group_name: "T3",
      name: "",
      name1: "",
    },
    other: [],
  },
];

const NewLaunchesConsolidated = () => {
  const dispatch = useDispatch();

  const newLaunchesConsolidated = useSelector(
    (state) => state.newLaunchesConsolidated
  );

  useEffect(() => {
    dispatch(getNewLaunchesReportActions());
  }, [dispatch]);

  console.log(
    "NEW LAUNCHES CONSOLIDATED REPORT: ",
    newLaunchesConsolidated?.data?.list
  );

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>New Launches Consolidated Report | Sample Dashboard</title>
      </Helmet>
      <div className="d-flex flex-row align-items-center justify-content-between">
        <div className="heading">
          <h2>New Launches Consolidated Report</h2>
        </div>
        <div className="download-but">
          <button>
            <img src="Images/document-download.svg" alt="" />
            Export csv
          </button>
        </div>
      </div>

      <div className="table-wrapper-pagination mt-4">
        <div className="table-container-pagination">
          <DataTable
            header={Header}
            // new_launches_arr={newLaunchesConsolidated?.data?.list}
            new_launches_arr={xyz}
            // garments={garmentFitList && garmentFitList?.data?.data}
            // itemsPerPage={10}
            // current={page}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default NewLaunchesConsolidated;
