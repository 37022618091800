import { Select } from "antd";
import React, { useEffect, useState } from "react";
import {
  getnodeUserUpdateBulk,
  getrequestList,
  getuserListByDesignation,
  getuserWorkLoadData,
} from "../../../store/user/actions";
import { useDispatch, useSelector } from "react-redux";
const { Option } = Select;

const NodeSelectBox = () => {
  const initialValues = {
    sample_category_node_transaction_id: "",
  };
  const [selectedValue, setSelectedValue] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userWorkload, setUserWorkload] = useState([]);
  const [payload, setPayload] = useState([]);
  const requestList = useSelector((state) => state.requestList);
  const dispatch = useDispatch();
  const userListByDesignation = useSelector(
    (state) => state.userListByDesignation
  );
  const userWorkLoadData = useSelector((state) => state.userWorkLoadData);

  useEffect(() => {
    const localdata = JSON.parse(localStorage.getItem("selectedPayload"));
    setPayload(localdata);
    dispatch(getrequestList(localdata));
  }, []);

  useEffect(() => {
    if (requestList.isSuccess) {
      const req_ids = requestList.data.list.map((item) => item.id);
      const olddata = { ...payload };
      olddata.ids = req_ids.join(",");
      setPayload(olddata);
    }
  }, [requestList]);
  const handleSearch = (value, index, operator_designation_id) => {
    const oldData = [...userList];
    oldData[index] = [];
    setUserList(oldData);
    dispatch(
      getuserListByDesignation({
        designation_id: operator_designation_id,
        search: value,
        index,
      })
    );
  };

  useEffect(() => {
    const oldData = [...userList];
    oldData[userListByDesignation.data.index] = userListByDesignation.data.list;
    setUserList(oldData);
  }, [userListByDesignation]);

  const handleChange = (id, index, deig_id) => {
    const oldData = [...selectedValue];
    oldData[index] = { userid: id, deig_id: deig_id };
    setSelectedValue(oldData);
    dispatch(getuserWorkLoadData({ id, index }));
  };

  useEffect(() => {
    const oldData = [...userWorkload];
    oldData[userWorkLoadData.data.index] = userWorkLoadData.data.list;
    setUserWorkload(oldData);
  }, [userWorkLoadData]);

  const submitHendle = () => {
    dispatch(
      getnodeUserUpdateBulk({
        selectedUser: selectedValue,
        request_id: payload.ids,
      })
    );
  };

  const removeRequest = async (id) => {
    const updatedid = payload.ids.split(",").filter((number) => number != id);
    setPayload({ ids: updatedid.join(",") });
    dispatch(getrequestList({ ids: updatedid.join(",") }));
    setSelectedValue([]);
    setUserWorkload([]);
    setUserList([]);
  };
  return (
    <div className="node_box">
      <div className="node-flex">
        {requestList.data.list.map((item) => (
          <div className="node-im">
            <div>
              <img className="main-img" src={item.samplePicture} />
              <img
                className="close_icon"
                src="/Images/close_icon.svg"
                onClick={() => removeRequest(item.id)}
              />
            </div>
            <h6>{item.sampleRequestId}</h6>
          </div>
        ))}
      </div>
      {requestList.data.designation_list.map((item, i) => (
        <div className="row mt-4">
          <div className="col-lg-4 pb-4">
            <label>Designation</label>
            <div className="gray_box_input">
              <p className="mb-0">{item.designation_name}</p>
            </div>
          </div>
          <div className="col-lg-4 pb-4">
            <label>Select User</label>
            <Select
              showSearch
              placeholder="Select a value"
              optionFilterProp="children"
              onSearch={(value) =>
                handleSearch(value, i, item.operator_designation_id)
              }
              onChange={(value) =>
                handleChange(value, i, item.operator_designation_id)
              }
              // style={{ width: 200 }}
              // value={searchValue}
              defaultActiveFirstOption={false}
              filterOption={false}
              options={userList[i]}
            />
          </div>
          <div className="col-lg-4 pb-4">
            <label>Workload (Future)</label>
            <div className="gray_box_input">
              <h5 className="mb-0">0 hr</h5>
            </div>
          </div>
          <div className="col-lg-4 pb-4">
            <label>Workload (After Assign)</label>
            <div className="gray_box_input">
              <h5 className="mb-0">0 hr</h5>
            </div>
          </div>
          <div className="col-lg-4 pb-4">
            <label>Available Time</label>
            <div className="gray_box_input">
              <h5 className="mb-0" style={{ color: "#00BB5A" }}>
                {userWorkload[i]?.availableHours} hr
              </h5>
            </div>
          </div>
          <div className="col-lg-4 pb-2">
            <label>Allocated Time</label>
            <div className="gray_box_input">
              <h5 className="mb-0">{userWorkload[i]?.allocatedHours} hr</h5>
            </div>
          </div>
          <div className="col-lg-4 pb-2">
            <label>Pending Time</label>
            <div className="gray_box_input">
              <h5 className="mb-0" style={{ color: "#FF0000" }}>
                {userWorkload[i]?.pandingHours} hr
              </h5>
            </div>
          </div>
        </div>
      ))}
      <div className="remark-buttons">
        <button className="dark-but" type="button" onClick={submitHendle}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default NodeSelectBox;
