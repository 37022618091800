import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { Link } from "react-router-dom";
import {
  getCostRangeMasterList,
  getfilterData,
} from "../../../store/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { convertDropdown } from "../../CreateFit/CreateFit";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

const FormatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}/${month}/${day}`;
};

const RequestSelect = ({
  filterValue,
  setfit_name,
  setcategorie_id,
  setcreated_by,
  setcreated_from,
  setcreated_to,
  fit_name,
  categorie_id,
  created_by,
  created_from,
  created_to,
}) => {
  const dispatch = useDispatch();

  const filterData = useSelector((state) => state.filterData);

  const [cost_range_arr, setCostRange_arr] = useState([]);
  const [product_cat_arr, setProductCat_arr] = useState([]);
  const [user_arr, setUser_arr] = useState([]);

  useEffect(() => {
    dispatch(getfilterData());
  }, []);

  const [rangePickerDate, setrangePickerDate] = useState([
    new Date(),
    new Date(),
  ]);

  const onChange = (date) => {
    setrangePickerDate(date);
    const from_date = FormatDate(date[0]);
    setcreated_from(from_date);
    const to_date = FormatDate(date[1]);
    setcreated_to(to_date);
  };

  const handleCostRangeName = (val) => {
    setfit_name(val);
  };

  const handleCategorie = (val) => {
    setcategorie_id(val);
  };

  const handleCreatedBy = (val) => {
    setcreated_by(val);
  };

  useEffect(() => {
    if (filterData && filterData?.isSuccess === true) {
      let costRange_arr = convertDropdown(filterData?.data?.cost_range);
      setCostRange_arr(costRange_arr);

      let productCat_arr = convertDropdown(
        filterData?.data?.product_categories
      );
      setProductCat_arr(productCat_arr);

      let user_arr = convertDropdown(filterData?.data?.user);
      setUser_arr(user_arr);
    }
  }, [filterData]);

  console.log("FILTER DATA: ", filterData);

  const handleClear = () => {
    setfit_name("");
    setcategorie_id("");
    setcreated_by("");
    setrangePickerDate([new Date(), new Date()]);
    dispatch(getCostRangeMasterList({ page: 1 }));
  };

  return (
    <div className="mt-4">
      {/* <RequestSelect/> */}
      <div className="row">
        <div className="col-md-3 col-lg-3 col-12 mb-2">
          {/* <label className="select-label-bold">Fit Name</label> */}
          <Select
            // defaultValue="Trial"
            placeholder="Cost Range Name"
            value={fit_name || "Cost Range Name"}
            // value={formValues.trail}
            onChange={(val) => handleCostRangeName(val)}
            options={cost_range_arr}
            showSearch
            optionFilterProp="children"
            filterOption={(input, userOption) =>
              ((userOption?.label).toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(userOption) =>
              (userOption?.label ?? "")
                .toLowerCase()
                .localeCompare((userOption?.label ?? "").toLowerCase())
            }
          />
        </div>
        <div className="col-md-3 col-lg-3 col-12 mb-2">
          {/* <label className="select-label-bold">Sample Category</label> */}
          <Select
            // defaultValue="Sample Category"
            placeholder="Category"
            value={categorie_id || "Category"}
            // value={formValues.sample_category_id}
            // onChange={(e) => handleChange("sample_category_id", e)}
            onChange={(val) => handleCategorie(val)}
            options={product_cat_arr}
            showSearch
            optionFilterProp="children"
            filterOption={(input, userOption) =>
              ((userOption?.label).toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(userOption) =>
              (userOption?.label ?? "")
                .toLowerCase()
                .localeCompare((userOption?.label ?? "").toLowerCase())
            }
          />
        </div>
        <div className="col-md-3 col-lg-3 col-12 mb-2">
          {/* <label className="select-label-bold">Sample Category</label> */}
          <Select
            // defaultValue="Sample Category"
            value={created_by || "Created By"}
            placeholder="Created By"
            // value={formValues.sample_category_id}
            onChange={(val) => handleCreatedBy(val)}
            options={user_arr}
            showSearch
            optionFilterProp="children"
            filterOption={(input, userOption) =>
              ((userOption?.label).toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(userOption) =>
              (userOption?.label ?? "")
                .toLowerCase()
                .localeCompare((userOption?.label ?? "").toLowerCase())
            }
          />
        </div>
        <div className="col-md-3 col-lg-3 col-12 mb-2 from-to-date">
          <label className="select-label">From & To Date</label>
          <DateRangePicker
            // placeholder="Actual Date"
            onChange={(e) => onChange(e)}
            value={rangePickerDate}
            clearIcon={false}
          />
        </div>
        {/* Sample Category Node Parameter Relationship */}

        <div className="clearDatas mb-3">
          <Link onClick={() => handleClear()} className="clear_data">
            Clear Data
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RequestSelect;
