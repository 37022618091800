import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getpersionWiseData } from "../../../store/user/actions";
import Loader from "../../../component/Loader";
import UserNameImage from "../../../component/UserNameImage/UserNameImage";

const WorkloadList = () => {
  const persionWiseData = useSelector((state) => state.persionWiseData);
  const dispatch = useDispatch();
  const optionsSelect = [
    {
      value: "Today",
      lable: "Today",
    },
    {
      value: "This Week",
      lable: "This Week",
    },
    {
      value: "Last Week",
      lable: "Last Week",
    },
    {
      value: "This Month",
      lable: "This Month",
    },
    {
      value: "Last Month",
      lable: "Last Month",
    },
    {
      value: "Last 3 Month",
      lable: "Last 3 Month",
    },
  ];
  const persionValue = (value) => {
    dispatch(getpersionWiseData({ period: value }));
  };
  useEffect(() => {
    dispatch(getpersionWiseData({}));
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <div className="wordload-card">
        <div className="heading_select">
          <h2>Person Wise Workload</h2>
          <div className="custom-select">
            <select
              className="selectArea"
              onChange={(e) => persionValue(e.target.value)}
            >
              {optionsSelect &&
                optionsSelect.map((item) => (
                  <option value={item.value}>{item.lable}</option>
                ))}
            </select>
          </div>
        </div>
        <div className="border-wordload"></div>
        {/* <WorkloadList /> */}
        <div className="position-relative">
          {persionWiseData.isLoading && (
            <div className="loader_bg">
              <Loader />
            </div>
          )}
        </div>
        <div className="word-row">
          {persionWiseData &&
            persionWiseData.data.map((item) => (
              <div className="word-colum">
                <div className="word-green word-img">
                  <UserNameImage
                    width="60px"
                    height="62px"
                    margin="0 auto"
                    name={item.name}
                    image={item.image}
                    title={item.name}
                  />

                  <h6 className="word-status">
                    <span></span>
                  </h6>
                </div>

                <p className="user-name">
                  {item.name} - {item.id}
                </p>

                <h6 className="word-title pt-2">
                  {item.hours} Hr <br />
                  Workload
                </h6>
                <div className="d-flex justify-content-center mt-2">
                  <div className="trail-button-o text-center">
                    <span style={{ color: "#33DABD" }}>
                      {item.reward_point}
                    </span>
                    <span style={{ color: "#DEDEDE" }}>|</span>
                    <span style={{ color: "#F00" }}>{item.strike_point}</span>
                  </div>
                  <div className="trail-button-green text-center">
                    ₹{item.incentive}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default WorkloadList;
