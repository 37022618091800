import React, { useState } from "react";
import DonutChart from "./component/DonutChart";
import CollectionTable from "./component/CollectionTable";
import { Helmet } from "react-helmet";
import { Button } from "antd";
import DefaultModal from "../../component/DefaultModal/DefaultModal";
import SampleCollectionModal from "./component/SampleCollectionModal";

const Collections = () => {
  const [show, setShow] = useState(false);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Collections | Sample Dashboard</title>
      </Helmet>
      {/* {show && ( */}
      <div>
        <div className="heading-collections-row">
          <div>
            <h1>Collections</h1>
          </div>
          <div>
            <Button onClick={() => setShow(true)}>Add collection form</Button>
          </div>
        </div>
        <DonutChart />
        <CollectionTable />
      </div>
      {/* )}
      {!show && <GalleryScreen Open={Open} />} */}

      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="remark-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Sample Collection</h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setShow(false)} />
          </div>
        </div>
        <SampleCollectionModal mClose={show} />
      </DefaultModal>
    </div>
  );
};

export default Collections;
