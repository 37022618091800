import React from "react";
import { useNavigate } from "react-router-dom";
import NodeSelectBox from "./component/NodeSelectBox";
import NodeSelectTable from "./component/NodeSelectTable";
import SelectTab from "./component/SelectTab";
const SelectNode = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div>
      <div className="d-flex align-items-baseline pb-4">
        <div className="heading">
          <div onClick={handleGoBack}>
            <img src="/Images/arrow-left.svg" />
          </div>
        </div>
        <h2>Select</h2>
      </div>
      <SelectTab />
      {/* <NodeSelectTable/> */}
      {/* <NodeSelectBox /> */}
    </div>
  );
};

export default SelectNode;
