import React, { useEffect, useState } from "react";
import { Select, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getaddNode,
  getfilterData,
  getcriticalityList,
  getnodeGroup,
} from "../../../store/user/actions";
import Item from "antd/es/list/Item";

const AddModal = React.memo(
  ({ selectCategory, selectTrail, AddTraileModal, categoryNodeRefresh }) => {
    const initialValues = {
      sample_category_id: selectCategory,
      trail_id: selectTrail,
      sample_node_id: "",
      sample_form_id: "",
      leadtime: "",
      incentive: "",
      fast_track: "",
      strike: "",
      designation: "",
      sample_bundle_id: "",
    };
    const [formErrors, setFormErrors] = useState({});
    const [formValues, setFormValue] = useState(initialValues);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [newNode, setNewNode] = useState(false);
    const filterData = useSelector((state) => state.filterData.data);
    const criticalityList = useSelector((state) => state.criticalityList.data);
    const nodeGroup = useSelector((state) => state.nodeGroup);
    const addNode = useSelector((state) => state.addNode);
    const dispatch = useDispatch();

    const [isRefreshed, setIsRefreshed] = useState(false);

    console.log("ADDNODES: ", addNode);

    const sampleNodesOption = Object.entries(filterData.sample_nodes).map(
      ([value, label]) => ({
        value: parseInt(value, 10),
        label,
      })
    );
    sampleNodesOption.unshift({
      value: "0",
      label: "Add New Node",
    });

    const designations = Object.entries(filterData.designations).map(
      ([value, label]) => ({
        value: parseInt(value, 10),
        label,
      })
    );

    const handleChange = (name, value) => {
      let data = { ...formValues };
      data[name] = value;
      setFormValue(data);
    };

    const leadtimehandleChange = (id, value) => {
      let data = { ...formValues };
      if (data["leadtime"] && data["leadtime"][id]) {
        data["leadtime"][id] = value;
      } else {
        data["leadtime"] = { ...data["leadtime"], [id]: value };
      }
      setFormValue(data);
    };

    const nodehandleChange = (name, value) => {
      if (value == 0) {
        setNewNode(true);
      } else {
        setNewNode(false);
        let data = { ...formValues };
        data[name] = value;
        setFormValue(data);
      }
    };

    const handleSubmit = (e, name, value) => {
      setError(null);
      setLoading(true);
      let check = validate(formValues);
      let data = { ...initialValues };
      data[name] = value;
      e.preventDefault();
      setFormErrors(check.errors);
      if (check.isValid) {
        setLoading(false);
        setIsRefreshed(true);
        dispatch(getaddNode(formValues));
        // AddTraileModal(false)
      }
    };
    // validation-start
    useEffect(() => {
      if (Object.keys(formErrors).length > 0) {
      }
    }, [formErrors]);
    const validate = (values) => {
      let isValid = true;
      const errors = {};
      if (!values.sample_node_id) {
        let err = 0;
        if (!values.node_name) {
          isValid = false;
          errors.node_name = "Enter node name is required";
          err = 1;
        } else if (!values.node_group) {
          isValid = false;
          errors.node_group = "Select node group is required";
          err = 1;
        }
        if ((err = 0)) {
          isValid = false;
          errors.sample_node_id = "select node is required";
        }
      }

      if (!values.leadtime) {
        isValid = false;
        errors.leadtime = "leadtime is required ";
      }
      if (!values.incentive) {
        isValid = false;
        errors.incentive = "incentive is required ";
      }
      if (!values.fast_track) {
        isValid = false;
        errors.fast_track = "fast track is required ";
      }
      if (!values.strike) {
        isValid = false;
        errors.strike = "strike is required ";
      }
      if (!values.designation) {
        isValid = false;
        errors.designation = "strike is required ";
      }
      return {
        isValid,
        errors,
      };
    };

    useEffect(() => {
      dispatch(getfilterData({}));
      dispatch(getcriticalityList({}));
      dispatch(getnodeGroup({}));
    }, [dispatch]);

    useEffect(() => {
      if (addNode.isSuccess && isRefreshed) {
        AddTraileModal(false);
        categoryNodeRefresh();

        dispatch(getfilterData({}));
        dispatch(getcriticalityList({}));
        dispatch(getnodeGroup({}));

        setIsRefreshed(false);
      }
    }, [addNode.isSuccess, isRefreshed, dispatch]);

    return (
      <div className="remark-select">
        <div>
          <h6>Sample Node</h6>
          <Select
            defaultValue="Select Node"
            onChange={(e) => nodehandleChange("sample_node_id", e)}
            options={sampleNodesOption}
            showSearch
            optionFilterProp="children"
            filterOption={(input, sampleTrailOption) =>
              (sampleTrailOption?.label.toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(sampleTrailOption) =>
              (sampleTrailOption?.label ?? "")
                .toLowerCase()
                .localeCompare((sampleTrailOption?.label ?? "").toLowerCase())
            }
          />
          <span className="error-msg">{formErrors.sample_node_id}</span>
        </div>
        {newNode ? (
          <>
            <div>
              <h6>Node Name</h6>
              <input
                placeholder="Node Name"
                className="input-custom form-control"
                onChange={(e) => handleChange("node_name", e.target.value)}
                style={{
                  height: "48px",
                  width: "100%",
                }}
              />
              <span className="error-msg">{formErrors.node_name}</span>
            </div>
            <div>
              <h6>Node Group</h6>
              <Select
                defaultValue="Select Node"
                onChange={(e) => handleChange("node_group", e)}
                options={nodeGroup.data}
                showSearch
                optionFilterProp="children"
                filterOption={(input, sampleTrailOption) =>
                  (sampleTrailOption?.label.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                filterSort={(sampleTrailOption) =>
                  (sampleTrailOption?.label ?? "")
                    .toLowerCase()
                    .localeCompare(
                      (sampleTrailOption?.label ?? "").toLowerCase()
                    )
                }
              />
              <span className="error-msg">{formErrors.node_group}</span>
            </div>
          </>
        ) : (
          ""
        )}

        <div>
          {criticalityList.map((item) => (
            <>
              <h6>{item.name} Leadtime (in Hour)</h6>
              <input
                placeholder="Leadtime"
                className="input-custom form-control"
                onChange={(e) => leadtimehandleChange(item.id, e.target.value)}
              />
              <span className="error-msg">{formErrors.leadtime}</span>
            </>
          ))}
        </div>
        <div>
          <h6>Operator Designation</h6>
          <Select
            defaultValue="Select option"
            // onChange={handleChange}
            showSearch
            optionFilterProp="children"
            options={designations}
            onChange={(e) => handleChange("designation", e)}
            filterOption={(input, sampleTrailOption) =>
              (sampleTrailOption?.label.toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(sampleTrailOption) =>
              (sampleTrailOption?.label ?? "")
                .toLowerCase()
                .localeCompare((sampleTrailOption?.label ?? "").toLowerCase())
            }
          />
          <span className="error-msg">{formErrors.designation}</span>
        </div>
        <div>
          <h6>Manager Designation </h6>
          <Select
            defaultValue="Select option"
            // onChange={handleChange}
            showSearch
            optionFilterProp="children"
            options={designations}
            onChange={(e) => handleChange("manager_designation", e)}
            filterOption={(input, sampleTrailOption) =>
              (sampleTrailOption?.label.toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(sampleTrailOption) =>
              (sampleTrailOption?.label ?? "")
                .toLowerCase()
                .localeCompare((sampleTrailOption?.label ?? "").toLowerCase())
            }
          />
          <span className="error-msg">{formErrors.designation}</span>
        </div>
        <div>
          <h6>Incentive(Rupees)</h6>
          <input
            placeholder="Incentive"
            type="number"
            step={1}
            min={0}
            onChange={(e) => handleChange("incentive", e.target.value)}
            className="input-custom form-control"
          />
          <span className="error-msg">{formErrors.incentive}</span>
        </div>
        <div>
          <h6>Fast track incentive factor (1X)</h6>
          <input
            placeholder=""
            type="number"
            onChange={(e) => handleChange("fast_track", e.target.value)}
            step={0.1}
            min={1}
            className="input-custom form-control"
          />
          <span className="error-msg">{formErrors.fast_track}</span>
        </div>
        <div>
          <h6>Strike (Per day point)</h6>
          <input
            placeholder=""
            type="number"
            step={1}
            min={0}
            className="input-custom"
            onChange={(e) => handleChange("strike", e.target.value)}
          />
          <span className="error-msg">{formErrors.strike}</span>
        </div>

        <div className="remark-buttons mt-4">
          <button className="dark-but" onClick={handleSubmit}>
            submit
          </button>
        </div>
      </div>
    );
  }
);

export default AddModal;
