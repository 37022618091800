// import React from 'react'

// const SubmissionModal = () => {
//   return (
//     <div className='submission-modal'>

//       <button>Re-Submit</button>
//     </div>
//   )
// }

// export default SubmissionModal

import React, { useEffect, useState } from "react";
import { Select, DatePicker } from "antd";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import {
  getnodeSubmitData,
  getremarkData,
  getsampleNodeData,
  getuserlistWithWorkload,
} from "../../../store/user/actions";
import { Link } from "react-router-dom";
import { resetNodeSubmitDataState } from "../../../store/user/userSlice";
import Loader from "../../../component/Loader";
import AudioRemark from "../../Dashboard/component/AudioRemark";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const SubmissionModal = (props) => {
  const initialValues = {
    sample_category_node_transaction_id: props.transtionId,
    // voice_note:'',
    files: "",
    documentFiles: "",
    voice_note: "",
  };
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [patternMaking, setPatternMaking] = useState([]);
  const [documentFiles, setDocumentFiles] = useState([]);
  const [formValues, setFormValue] = useState(initialValues);
  const [audioBox, setAudioBox] = useState(true);
  const [isDropzoneVisible, setDropzoneVisibility] = useState(true);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const nodeSubmitData = useSelector((state) => state.nodeSubmitData);
  const dispatch = useDispatch();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    multiple: true,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      if (acceptedFiles.length > 0) {
        // Assuming you only want to display the first uploaded image
        setUploadedImage(acceptedFiles[0]);
        setDropzoneVisibility(false); // Hide the dropzone
      }
    },
  });
  const showDropzone = () => {
    setUploadedImage(null); // Clear the uploaded image
    setDropzoneVisibility(true); // Show the dropzone again
  };
  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  //document-upload
  const {
    getRootProps: getDocumentRootProps,
    getInputProps: getDocumentInputProps,
  } = useDropzone({
    accept: "application/pdf, application/msword", // Specify accepted document file types
    multiple: true,
    onDrop: (acceptedFiles) => {
      setDocumentFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        )
      );
    },
  });

  const thumbsDocument = documentFiles.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src="/Images/document-icon.png" // Add your document icon source
          style={img}
        />
      </div>
    </div>
  ));
  const onChange = (name, value) => {
    let data = { ...initialValues };
    data[name] = value;
    setFormValue();
  };
  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    setFiles([...selectedFiles]);
  };

  const handleSubmit = (e, name, value) => {
    setError(null);
    setLoading(true);
    let check = validate(formValues);
    let data = { ...initialValues };
    data[name] = value;
    e.preventDefault();
    setFormErrors(check.errors);
    if (check.isValid) {
      setLoading(false);
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`file[${index}]`, file);
      });
      documentFiles.forEach((document, index) => {
        formData.append(`document[${index}]`, document);
      });
      if (audioBox[0]) {
        formData.append("voice_note", audioBox[0].mediaBlob);
      }
      formData.append("remark", formValues.remark);
      formData.append(
        "sample_category_node_transactions_id",
        formValues.sample_category_node_transaction_id
      );
      //   formData.append("sample_category_node_transaction_id", formValues.sample_category_node_transaction_id)
      dispatch(getnodeSubmitData(formData));
      if (!nodeSubmitData.isLoading) {
      }
    }
  };
  useEffect(() => {
    if (nodeSubmitData.isSuccess) {
      dispatch(resetNodeSubmitDataState());
      props.refreshNodeData();
      props.mClose(false);
    }
  }, [nodeSubmitData]);

  // validation-start
  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
    }
  }, [formErrors]);

  const validate = (values) => {
    let isValid = true;
    const errors = {};
    if (!values.remark) {
      isValid = false;
      errors.remark = "remark is required ";
    }
    if (!files.length) {
      isValid = false;
      errors.files = "files is required";
    }
    if (!documentFiles.length) {
      isValid = false;
      errors.documentFiles = "document is required";
    }
    // if (!audioBox.length) {
    //     isValid = false;
    //     errors.voice_note = "voice is required";
    //   }
    return {
      isValid,
      errors,
    };
  };
  const audioValue = (selectValue) => {
    setAudioBox(selectValue);
  };
  return (
    <div className="remark-select">
      <form onSubmit={handleSubmit}>
        <h6>Remarks</h6>
        <div>
          <textarea
            onChange={(e) => handleChange("remark", e.target.value)}
            className="card_box"
          />
          <span className="error-msg">{formErrors.remark}</span>
        </div>
        <div>
          <div {...getRootProps({ className: "upload-file" })}>
            <input {...getInputProps()} multiple onChange={handleFileChange} />
            {files.length == 0 ? (
              <div>
                <img src="/Images/cloud.svg" />
                <h5>Upload Image</h5>
                <p>
                  Drag and Drop file here Or <b>Choose file</b>
                </p>
                <small>Support formats: JPG, PNG | Minimum size: 5 MB</small>
              </div>
            ) : (
              <></>
            )}
            <aside style={thumbsContainer}>{thumbs}</aside>
          </div>
          <span className="error-msg">{formErrors.files}</span>
        </div>
        <div>
          <div {...getDocumentRootProps({ className: "upload-file" })}>
            <input {...getDocumentInputProps()} multiple />
            {documentFiles.length === 0 ? (
              <div className="upload-file-content">
                <img src="/Images/cloud.svg" alt="Upload Document" />
                <h5>Upload Document</h5>
                <p>
                  Drag and Drop file here Or <b>Choose file</b>
                </p>
                <small>Support formats: PDF, DOC | Minimum size: 5 MB</small>
              </div>
            ) : (
              <></>
            )}
            <aside style={thumbsContainer}>{thumbsDocument}</aside>
          </div>
          <span className="error-msg">{formErrors.documentFiles}</span>
        </div>
        <div>
          <AudioRemark audioValue={audioValue} />
          {/* <span className='error-msg'>{formErrors.voice_note}</span> */}
        </div>
        <div className="remark-buttons">
          {nodeSubmitData.isLoading ? (
            <div className="position-relative">
              <div className="loader_bg">
                <Loader />
              </div>
            </div>
          ) : (
            <button className="dark-but" type="submit">
              Submit
            </button>
          )}
        </div>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default SubmissionModal;
