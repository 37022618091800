import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RiMenu2Fill } from "react-icons/ri";
import { getUser, removeUserSession } from "../util/Common";
import IMG_DEFAULT from "../no-image.jpg";

const Header = (props) => {
  // const user = useSelector(selectUser)
  const navigate = useNavigate();
  const user = getUser();
  const handleLogout = (e) => {
    removeUserSession();
    navigate("/login");
  };

  const [isBodyClassNameAdded, setIsBodyClassNameAdded] = useState(false);

  const handleClick = () => {
    setIsBodyClassNameAdded(!isBodyClassNameAdded);
  };

  useEffect(() => {
    if (isBodyClassNameAdded) {
      document.body.classList.add("sidebar-open");
    } else {
      document.body.classList.remove("sidebar-open");
    }
  }, [isBodyClassNameAdded]);

  console.log("USER: ", user);
  return (
    <div className="header-bg">
      <div>
        <RiMenu2Fill
          style={{ marginLeft: isBodyClassNameAdded ? "300px" : "0px" }}
          onClick={handleClick}
          size={32}
        />
      </div>
      {/* <Searchbar 
            value={value}
            setValue={(e) => setValue(e.target.value)}
            placeholder="Search"
            fontSize="12px"
            backgroundColor="#F5F5F5"
            borderRadius="4px"
            searchIcon
            width="549px"
            height="48px"
            position="relative"
            padding="0px 20px"

          /> */}
      <div className="logout-profile">
        <img
          src={user.profile_image || IMG_DEFAULT}
          alt="logo"
          onError={(e) => (e.target.src = IMG_DEFAULT)}
        />
        <div>
          <h6>{user.firstname || user?.User?.name}</h6>
          <button onClick={(e) => handleLogout(e)}>Logout</button>
        </div>
      </div>
    </div>
  );
};

export default Header;
