import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getfilterData, getGarmentList } from "../../../store/user/actions";
import { convertDropdown } from "../../CreateFit/CreateFit";
const RequestSelect = ({
  filterValue,
  setFit_Name,
  setCategorie_id,
  setFit_type_id,
  setCreated_by,
  setSize_id,
  setfabric_kind,
  setReference_Url,
  fit_name,
  categorie_id,
  fit_type_id,
  created_bys,
  type_id,
  fabric_kinds,
  reference_url,
}) => {
  const dispatch = useDispatch();
  const filterData = useSelector((state) => state.filterData);

  useEffect(() => {
    dispatch(getfilterData());
  }, [dispatch]);

  // const initialValues = {
  //   sample_category_id: "",
  //   sample_node_id: "",
  //   trail: "",
  //   parameter: [],
  // };
  // const [formValues, setFormValue] = useState(initialValues);

  // const handleChange = (name, value) => {
  //   let data = { ...formValues };
  //   data[name] = value;
  //   setFormValue(data);
  // };

  console.log("FILTER DATA: ", filterData);

  const [fit_Name, setFitName] = useState([]);
  const [product_category, setProductCategory] = useState([]);
  const [fit_Type, setFitType] = useState([]);
  const [created_by, setCreatedBy] = useState([]);
  const [size, setSize] = useState([]);
  // const [style, setStyle] = useState([]);
  const [fabric_kind, setFabric_kind] = useState([]);

  const handleFitName = (val) => {
    console.log("VAL: ", val);
    setFit_Name(val);
  };

  const handleCategory = (val) => {
    console.log("VAL: ", val);
    setCategorie_id(val);
  };

  const handleReferenceURL = (val) => {
    setReference_Url(val);
  };

  const handleFitType = (val) => {
    setFit_type_id(val);
  };

  const handleCreatedBy = (val) => {
    setCreated_by(val);
  };

  const handleSize = (val) => {
    setSize_id(val);
  };

  const handleFabricKind = (val) => {
    setfabric_kind(val);
  };

  useEffect(() => {
    if (filterData && filterData?.isSuccess === true) {
      let fit_name_arr = convertDropdown(filterData?.data?.garment_fit);
      setFitName(fit_name_arr);

      let product_category = convertDropdown(
        filterData?.data?.product_categories
      );
      console.log("PRODUCT CATEGORIES: ", product_category);
      setProductCategory(product_category);

      let fit_type_arr = convertDropdown(filterData?.data?.fit_type);
      console.log("FIT TYPE ARR: ", fit_type_arr);
      setFitType(fit_type_arr);

      let created_by_arr = convertDropdown(filterData?.data?.user);
      console.log("CREATED BY ARR: ", created_by_arr);
      setCreatedBy(created_by_arr);

      let reference_size_arr = convertDropdown(filterData?.data?.size);
      console.log("REFERENCE SIZE ARR: ", reference_size_arr);
      setSize(reference_size_arr);

      // let style_arr = convertDropdown(filterData?.data?.style);
      // console.log("STYLE ARR: ", style_arr);
      // setStyle(style_arr);

      let fabric_kind_arr = convertDropdown(filterData?.data?.fabric_kind);
      setFabric_kind(fabric_kind_arr);
    }
  }, [filterData]);

  const handleReset = () => {
    setFit_Name("");
    setCategorie_id("");
    setReference_Url("");
    setFit_type_id("");
    setCreated_by("");
    setSize_id("");
    setfabric_kind("");
    dispatch(getGarmentList({ page: 1 }));
  };

  return (
    <div className="mt-4">
      {/* <RequestSelect/> */}
      <div className="row">
        <div className="col-md-3 col-lg-3 col-12 mb-2">
          {/* <label className="select-label-bold">Fit Name</label> */}
          <Select
            // defaultValue="Trial"
            placeholder="Fit Name"
            value={fit_name || "Fit Name"}
            // value={formValues.trail}
            onChange={(val) => handleFitName(val)}
            options={fit_Name}
            showSearch
            optionFilterProp="children"
            filterOption={(input, userOption) =>
              ((userOption?.label).toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(userOption) =>
              (userOption?.label ?? "")
                .toLowerCase()
                .localeCompare((userOption?.label ?? "").toLowerCase())
            }
          />
        </div>
        <div className="col-md-3 col-lg-3 col-12 mb-2">
          {/* <label className="select-label-bold">Sample Category</label> */}
          <input
            className="input-custom"
            type="text"
            name=""
            id=""
            value={reference_url}
            onChange={(e) => handleReferenceURL(e.target.value)}
            placeholder="Fit Reference URL"
          />
        </div>
        <div className="col-md-3 col-lg-3 col-12 mb-2">
          {/* <label className="select-label-bold">Sample Category</label> */}
          <Select
            // defaultValue="Sample Category"
            placeholder="Category"
            value={categorie_id || "Category"}
            // value={formValues.sample_category_id}
            onChange={(val) => handleCategory(val)}
            options={product_category}
            showSearch
            optionFilterProp="children"
            filterOption={(input, userOption) =>
              ((userOption?.label).toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(userOption) =>
              (userOption?.label ?? "")
                .toLowerCase()
                .localeCompare((userOption?.label ?? "").toLowerCase())
            }
          />
        </div>
        <div className="col-md-3 col-lg-3 col-12 mb-2">
          {/* <label className="select-label-bold">Sample Category</label> */}
          <Select
            // defaultValue="Sample Category"
            placeholder="Fit Type"
            value={fit_type_id || "Fit Type"}
            // value={formValues.sample_category_id}
            onChange={(val) => handleFitType(val)}
            options={fit_Type}
            showSearch
            optionFilterProp="children"
            filterOption={(input, userOption) =>
              ((userOption?.label).toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(userOption) =>
              (userOption?.label ?? "")
                .toLowerCase()
                .localeCompare((userOption?.label ?? "").toLowerCase())
            }
          />
        </div>
        <div className="col-md-3 col-lg-3 col-12 mb-2">
          {/* <label className="select-label-bold">Sample Category</label> */}
          <Select
            value={created_bys || "Created By"}
            // defaultValue="Sample Category"
            placeholder="Created By"
            // value={formValues.sample_category_id}
            onChange={(val) => handleCreatedBy(val)}
            options={created_by}
            showSearch
            optionFilterProp="children"
            filterOption={(input, userOption) =>
              ((userOption?.label).toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(userOption) =>
              (userOption?.label ?? "")
                .toLowerCase()
                .localeCompare((userOption?.label ?? "").toLowerCase())
            }
          />
        </div>
        <div className="col-md-3 col-lg-3 col-12 mb-2">
          {/* <label className="select-label-bold">Sample Category</label> */}
          <Select
            value={type_id || "Reference Size"}
            // defaultValue="Sample Category"
            placeholder="Reference Size"
            // value={formValues.sample_category_id}
            onChange={(val) => handleSize(val)}
            options={size}
            showSearch
            optionFilterProp="children"
            filterOption={(input, userOption) =>
              ((userOption?.label).toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(userOption) =>
              (userOption?.label ?? "")
                .toLowerCase()
                .localeCompare((userOption?.label ?? "").toLowerCase())
            }
          />
        </div>
        {/* <div className="col-md-3 col-lg-3 col-12 mb-2">
          <Select
            placeholder="Styles"
            onChange={(val) => handleStyle(val)}
            options={style}
            showSearch
            optionFilterProp="children"
          />
        </div> */}
        <div className="col-md-3 col-lg-3 col-12 mb-2">
          {/* <label className="select-label-bold">Sample Category</label> */}
          <Select
            value={fabric_kinds || "Designed for Fabric Kind"}
            // defaultValue="Sample Category"
            placeholder="Designed for Fabric Kind"
            // value={formValues.sample_category_id}
            onChange={(val) => handleFabricKind(val)}
            options={fabric_kind}
            showSearch
            optionFilterProp="children"
            filterOption={(input, userOption) =>
              ((userOption?.label).toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(userOption) =>
              (userOption?.label ?? "")
                .toLowerCase()
                .localeCompare((userOption?.label ?? "").toLowerCase())
            }
          />
        </div>
        {/* Sample Category Node Parameter Relationship */}

        <div className="clearDatas mb-3">
          <Link onClick={() => handleReset()} className="clear_data">
            Clear Data
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RequestSelect;
