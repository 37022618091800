import React, { useEffect, useState, useCallback } from "react";
import ReactFlow, {
  Controls,
  useEdgesState,
  Position,
  useNodesState,
  addEdge,
  fitView,
} from "reactflow";
import { Link, useParams } from "react-router-dom";
// import ToggleSwitch from './ToggleSwitch'
// import AndOrToggleSwitch from './AndOrToggleSwitch'
// import ButtonEdge from './ButtonEdge';
import { useDispatch, useSelector } from "react-redux";
import { getsampleNodeEdgeConnect } from "../../../store/user/actions";
import UserNameImage from "../../../component/UserNameImage/UserNameImage";
import { toast } from "react-toastify";

function Flow({ sampleNode }) {
  const [edges, setEdges] = useEdgesState([]);
  const [nodes, setNodes] = useNodesState([]);
  const [prevSelectedTrailId, setPrevSelectedTrailId] = useState(null);
  const [data, setData] = useState(sampleNode);
  const [boxHeight, setBoxHeight] = useState(100);
  const [selectedTrail, setSelectedTrail] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setData(sampleNode);
  }, [sampleNode]);
  useEffect(() => {
    setNodes([]);
    setEdges([]);
    const group = [];
    var edgesgroup = [];
    var yaxis = 100;
    var i = 1;
    for (let [trail, node] of Object.entries(data)) {
      const isSelected = selectedTrail === trail;
      group.push({
        id: "TrailTitle-" + trail,
        trailId: trail,
        data: { label: "" + trail },
        position: { x: 10, y: 5 },
        parentNode: "Trail-" + i,
        extent: "parent",
        className: isSelected ? "selected" : "",
        labelElement: <h6 className="label-style">{trail}</h6>,
      });
      var nodexaxis = 10;
      node.map((nodeData) => {
        group.push({
          id: "nodeGroup" + trail + nodeData.id,
          type: "group",
          trailId: trail,
          position: { x: nodexaxis, y: 60 },
          parentNode: "Trail-" + i,
          extent: "parent",
          selected: false,
          className: "light",
          style: {
            width: 150,
            height: 280,
            backgroundColor: "rgba(240,240,240,0.25)",
            boxShadow: "0 1rem 3rem rgba(0,0,0,.175)",
            paddingRight: 0,
            paddingLeft: 0,
            borderWidth: 0,
          },
        });
        nodexaxis += 220;
        group.push({
          id: "N" + nodeData.id,
          type: nodeData.status == 1 ? "selectorNode" : "input",
          trailId: trail,
          data: {
            label: (
              <div
                className={`trail-col ${isSelected ? "selected" : ""}`}
                onClick={() => onElementClick(null, { trailId: trail })}
              >
                <div className="trail-img">
                  {nodeData.status == 2 ? (
                    <div
                      className={
                        nodeData.status != 1 ? "inactiveNode opacity-5" : ""
                      }
                    >
                      <UserNameImage
                        name={nodeData.node.name}
                        image={nodeData.image}
                        width="150px"
                        height="150px"
                      />
                      <h6 className="trail-status">
                        <span>{nodeData.node_message}</span>
                      </h6>
                    </div>
                  ) : (
                    <UserNameImage
                      name={nodeData.node.name}
                      image={nodeData.image}
                      width="150px"
                      height="150px"
                    />
                  )}

                  {/* <img src={nodeData.image} /> */}
                  <h6 className="trail-status">{nodeData.node_message}</h6>
                </div>
              </div>
            ),
          },
          position: { x: 0, y: 0 },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          parentNode: "nodeGroup" + trail + nodeData.id,
          extent: "parent",
          className: "light",
          style: {
            paddingRight: 0,
            paddingLeft: 0,
            borderWidth: 0,
          },
        });

        group.push({
          id: "nodebody" + nodeData.id,
          type: "input",
          trailId: trail,
          data: {
            label: (
              <div className="trail-category-text">
                <h5 className="pb-2">{nodeData.node.name}</h5>
              </div>
            ),
          },
          position: { x: 0, y: 160 },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          parentNode: "nodeGroup" + trail + nodeData.id,
          extent: "parent",
          className: "light",
        });
        var tempedgesgroup = nodeData.todos.map((edge) => {
          var tata = [...edgesgroup];
          if (edge.status == 1) {
            tata.push({
              id: "e-" + trail + nodeData.id,
              source: "N" + edge.todo_after,
              target: "N" + edge.sample_category_node_previews_id,
              animated: false,
              label: edge.type == 1 ? "AND" : "OR",
              // sourceHandle: 'a',
              style: { stroke: "#000", strokeWidth: "3px" },
              type: "smoothstep",
              data: { label: edge.type, id: edge.id, status: edge.type == 1 },
              // type: 'buttonedge'
            });
          }
          edgesgroup = tata;
          setEdges(tata);
        });
      });
      group.push({
        id: "Trail-" + i,
        type: "group",
        trailId: trail,
        position: { x: 0, y: yaxis },
        style: {
          width: nodexaxis + 220,
          height: 350,
          borderWidth: "",
          // borderWidth: selectedTrail === trail ? '2px' : '1px',
          // border: selectedTrail === trail ? '2px solid #00f' : '1px solid #f00',
          border: "1px solid #D6D6D6 !important",
          backgroundColor: "#fff !important",
          borderRadius: "4px",
          boxShadow: "0 1rem 3rem rgba(0,0,0,.175)",
          paddingRight: 15,
          paddingLeft: 15,
        },
      });
      group.push({
        id: "nodeGroup-new" + i,
        type: "group",
        trailId: trail,
        position: { x: nodexaxis, y: 60 },
        parentNode: "Trail-" + i,
        extent: "parent",
        className: "light",
        selected: false,
        style: {
          width: 150,
          height: 280,
          backgroundColor: "rgba(240,240,240,0.25)",
          boxShadow: "0 1rem 3rem rgba(0,0,0,.175)",
          paddingRight: 0,
          paddingLeft: 0,
          borderWidth: 0,
        },
      });

      yaxis += 500;

      i++;
    }
    setBoxHeight(yaxis + 100);
    setNodes(group);
  }, [data, selectedTrail]);

  const onElementClick = (event, object) => {
    const selectedTrailId = object.trailId;

    setNodes((prevNodes) =>
      prevNodes.map((node) => {
        if (node.id.startsWith("Trail-")) {
          // Remove 'selected' class from the previously selected trail
          if (node.trailId === prevSelectedTrailId) {
            return {
              ...node,
              className: "notselected",
            };
          }
          // Toggle the 'selected' class for the clicked trail
          if (node.trailId === selectedTrailId) {
            return {
              ...node,
              className: "selected",
            };
          }
        }
        return node;
      })
    );

    setPrevSelectedTrailId(selectedTrailId);
  };
  const onConnect = useCallback((params) => {
    if (
      params.source.startsWith("N-new") ||
      params.target.startsWith("N-new")
    ) {
      // alert("Connected with not a node")
      toast.error("Connected with not a node");
    } else {
      const source = params.source.replace("N", "");
      const target = params.target.replace("N", "");
      dispatch(getsampleNodeEdgeConnect({ source, target }));
    }
  }, []);

  return (
    <div>
      <div
        className="flow-section flow-section-category"
        style={{ height: boxHeight + "px" }}
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          // minZoom={1}
          // maxZoom={1}
          onNodeClick={onElementClick}
          onConnect={onConnect}
          //   edgeTypes={edgeTypes}
          // fitView
        >
          <Controls />
        </ReactFlow>
      </div>
    </div>
  );
}

export default Flow;
