import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { getcollectionWiseCompletion } from "../../../store/user/actions";

const CollectionComplete = () => {
  const collectionWiseCompletion = useSelector(
    (state) => state.collectionWiseCompletion
  );
  const dispatch = useDispatch();
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Completed",
        data: collectionWiseCompletion.data.map((item) => item.totalCompleted),
      },
      {
        name: "Pending",
        data: collectionWiseCompletion.data.map((item) => item.totalPending),
      },
      {
        name: "Partially Completed",
        data: collectionWiseCompletion.data.map(
          (item) => item.totalPartiallyPending
        ),
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "26%",
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: false,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        categories: [],
        labels: {
          rotate: -90,
          rotateAlways: true,
          style: {
            fontSize: "12px",
            fontWeight: 500,
            fontFamily: "Poppins",
          },
        },
      },
      grid: {
        padding: {
          bottom: 30,
        },
      },
      colors: ["#962DFF", "#4A3AFF", "#E0C6FD"],
      legend: {
        position: "bottom",
        offsetY: 10,
        horizontalAlign: "center",
      },
      fill: {
        opacity: 1,
      },
      dataLabels: {
        enabled: false,
      },
    },
  });
  const optionsSelect = [
    {
      value: "Today",
      lable: "Today",
    },
    {
      value: "This Week",
      lable: "This Week",
    },
    {
      value: "Last Week",
      lable: "Last Week",
    },
    {
      value: "This Month",
      lable: "This Month",
    },
    {
      value: "Last Month",
      lable: "Last Month",
    },
    {
      value: "Last 3 Month",
      lable: "Last 3 Month",
    },
  ];

  const persionValue = (value) => {
    dispatch(getcollectionWiseCompletion({ period: value }));
  };
  useEffect(() => {
    dispatch(getcollectionWiseCompletion({}));
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setChartData((prevData) => ({
      ...prevData,
      series: [
        {
          name: "Completed",
          data: collectionWiseCompletion.data.map(
            (item) => item.totalCompleted
          ),
        },
        {
          name: "Pending",
          data: collectionWiseCompletion.data.map((item) => item.totalPending),
        },
        {
          name: "Partially Completed",
          data: collectionWiseCompletion.data.map(
            (item) => item.totalPartiallyPending
          ),
        },
      ],
      options: {
        ...prevData.options,
        xaxis: {
          ...prevData.options.xaxis,
          categories: collectionWiseCompletion.data.map((item) => item.name),
        },
      },
    }));
  }, [collectionWiseCompletion]);

  return (
    <div className="node_wise_graph">
      <div className="wordload-card">
        <h6>Statistics</h6>
        <div className="heading_select">
          <h2>Collection Wise Completion</h2>
          <div className="custom-select">
            <select
              className="selectArea"
              onChange={(e) => persionValue(e.target.value)}
            >
              {optionsSelect &&
                optionsSelect.map((item) => (
                  <option value={item.value}>{item.lable}</option>
                ))}
            </select>
          </div>
        </div>
        <div className="border-wordload"></div>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={350}
        />
      </div>
    </div>
  );
};

export default CollectionComplete;
