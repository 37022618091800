import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ImageRequestActions } from "../../../store/user/actions";
import Loader from "../../../component/Loader";

const ImageViewModal = ({ mClose, imageId }) => {
  const dispatch = useDispatch();

  //   state for Image View
  const ImagerequestView = useSelector((state) => state.ImagerequestView);

  useEffect(() => {
    if (imageId) {
      dispatch(ImageRequestActions(imageId));
    }
  }, [dispatch, imageId]);

  console.log("IMAGE DATA: ", ImagerequestView);

  return (
    <Fragment>
      {ImagerequestView?.isLoading ? (
        <div className="loader_icons">
          <Loader />
        </div>
      ) : (
        <Fragment>
          <div className="image-view-grid-row">
            {ImagerequestView?.data?.image?.map((i) => (
              <Fragment key={i.id}>
                <div>
                  <img src={process.env.REACT_APP_IMG_URL + i.file} alt="" />
                </div>
              </Fragment>
            ))}
          </div>
          <div className="reference-url-row">
            <p>
              {ImagerequestView?.data?.reference_url?.length > 0 &&
                ImagerequestView?.data?.reference_url?.replace(/,/g, " | ")}
            </p>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ImageViewModal;
