import React, { Fragment, useEffect, useState } from "react";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getsampleCategoryParameterAdd,
  getsettingParameter,
} from "../../../store/user/actions";
import { resetsampleCategoryParameterAddState } from "../../../store/user/userSlice";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import Loader from "../../../component/Loader";

const AddParameterValue = ({ categoryId, parameterValue, mClose }) => {
  const settingParameter = useSelector((state) => state.settingParameter);
  const sampleCategoryParameterAdd = useSelector(
    (state) => state.sampleCategoryParameterAdd
  );

  const [parameter, setParameter] = useState([]);
  const [parameterSettings, setParameterSettings] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getsettingParameter({}));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (sampleCategoryParameterAdd.isSuccess) {
      dispatch(resetsampleCategoryParameterAddState());
      mClose(false);
    }
    // eslint-disable-next-line
  }, [sampleCategoryParameterAdd]);

  useEffect(() => {
    setParameterSettings(settingParameter?.data);
    setParameter(parameterValue || []);

    // Initialize selectedOption state based on parameterValue
    const initialSelectedOption = {};
    parameterValue.forEach((item) => {
      initialSelectedOption[item.id] = item.value;
    });
    setSelectedOption(initialSelectedOption);
  }, [settingParameter?.data, parameterValue]);

  const handleChange = (id, value) => {
    const oldData = [...parameter];
    const index = oldData.findIndex((obj) => obj.id === id);
    if (index >= 0) {
      oldData[index] = { id: id, value: value };
    } else {
      oldData.push({ id: id, value: value });
    }
    setParameter(oldData);
  };

  const handleSubmit = () => {
    if (parameter.length <= 0) {
      return toast.error("Please Fill Out The Field");
    }
    dispatch(
      getsampleCategoryParameterAdd({ id: categoryId, data: parameter })
    );
  };

  const handleclear = (x) => {
    const updatedSettings = parameter.filter((i) => i.id !== x);
    setParameter(updatedSettings);
  };

  const handleOptionChange = (id, value) => {
    setSelectedOption((prev) => ({ ...prev, [id]: value }));
    handleChange(id, value);
  };

  const handleInputChange = (id, e) => {
    const { value } = e.target;
    setSelectedOption((prev) => ({ ...prev, [id]: value }));
    handleChange(id, value);
  };

  return (
    <div>
      {parameterSettings?.map((item, index) => {
        const valuesArray = item.value.split(",");
        const transformedArray = valuesArray.map((value) => ({
          id: value,
          value: value,
        }));

        return (
          <div key={index} className="col-md-12 col-lg-12 col-12 mb-2">
            <label className="select-label-bold">{item.text_value}</label>
            {item.input_type === "radio_button" ||
            item.input_type === "check_box" ? (
              <Fragment>
                <br />
                <div className="radio-grid-row">
                  {transformedArray.map((option) => (
                    <label key={option.id}>
                      <input
                        type="radio"
                        value={option.value}
                        checked={selectedOption[item.id] === option.value}
                        onChange={(e) =>
                          handleOptionChange(item.id, e.target.value)
                        }
                      />
                      &nbsp;&nbsp;{option.value}
                    </label>
                  ))}
                </div>
              </Fragment>
            ) : item.input_type === "input" ? (
              <Fragment>
                <br />
                <input
                  className="input-parameter-relation"
                  type="text"
                  value={selectedOption[item.id] || ""}
                  onChange={(e) => handleInputChange(item.id, e)}
                />
              </Fragment>
            ) : (
              <div style={{ position: "relative", top: 0 }}>
                <Select
                  defaultValue="Select"
                  onChange={(value) => handleChange(item.id, value)}
                  options={transformedArray}
                  showSearch
                  optionFilterProp="children"
                  value={selectedOption[item.id] || "Select"}
                />
                <button
                  onClick={() => handleclear(item.id)}
                  className="clear-cicons"
                >
                  <MdDelete />
                </button>
              </div>
            )}
          </div>
        );
      })}
      <div className="remark-buttons">
        <button className="dark-but" type="submit" onClick={handleSubmit}>
          {sampleCategoryParameterAdd?.isLoading ? <Loader /> : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default AddParameterValue;
