import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IdealConsumptionsAddActions } from "../../store/user/actions";
import Loader from "../../component/Loader";
import DoneIcon from "@mui/icons-material/Done";
import IMG_DEFAULT from "../../no-image.jpg";
import { Button } from "antd";
import { useParams } from "react-router-dom";
import axios from "axios";
import { getToken } from "../../util/Common";
import { toast } from "react-toastify";

const AddConsumptionProduct = () => {
  const dispatch = useDispatch();
  const { id, style_id } = useParams();

  const IdealConsumptionAddProduct = useSelector(
    (state) => state.IdealConsumptionAddProduct
  );

  useEffect(() => {
    dispatch(IdealConsumptionsAddActions(style_id));
  }, [dispatch, style_id]);

  console.log("IDEAL CONSUMPTION ADD DATA: ", IdealConsumptionAddProduct);

  const [ids, setIds] = useState([]);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (
      IdealConsumptionAddProduct &&
      IdealConsumptionAddProduct?.isSuccess === true &&
      !isClicked
    ) {
      IdealConsumptionAddProduct &&
        IdealConsumptionAddProduct?.data?.forEach((i) => {
          if (i.select === true && !ids.includes(i.id)) {
            setIds((prev) => [...prev, i.id]);
          }
        });
    }
  }, [IdealConsumptionAddProduct, ids, isClicked]);

  console.log("ID: ", ids);

  const handleImg = (id) => {
    setIsClicked(true);
    if (!ids.includes(id)) {
      setIds((prev) => [...prev, id]);
    } else {
      let filterIds = ids.filter((i) => i !== id);
      setIds(filterIds);
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    const formData = new FormData();
    ids.forEach((i, index) => {
      formData.append(`product_ids[${index}]`, i);
    });

    formData.append("request_id", style_id);
    formData.append("product_sub_group_id", id);

    setIsLoading(true);
    await axios({
      method: "POST",
      url:
        process.env.REACT_APP_API_URL +
        `/sample/submit_ideal_consumption_product`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: formData,
    })
      .then((response) => {
        if (response?.data?.status === 0) {
          toast.error(response?.data?.message);
          setIsLoading(false);
        } else if (response?.data?.status === 1) {
          toast.success("Data Saved Successfully");
          setIsLoading(false);
        }
        // console.log(response);
        // toast.success(response?.data?.message);
      })
      .catch((error) => {
        setIsLoading(false);
        // console.log(error.response.data.message);
        toast.error(error?.response?.data?.message);
      });
  };

  console.log("IDS: ", ids, id, style_id);

  return (
    <div>
      <div className="d-flex align-items-baseline justify-content-between pb-2">
        <div className="heading">
          <h2>Add Consumption Product</h2>
        </div>
        <div className="download-but"></div>
      </div>
      <div className="ideal-consumption-wrapper"></div>

      <div className="images-add-consumption-grid-row">
        {IdealConsumptionAddProduct?.isLoading ? (
          <div className="loader_icons">
            <Loader />
          </div>
        ) : (
          <div>
            <div className="add-ideal-consumption-grid-row">
              {IdealConsumptionAddProduct &&
                IdealConsumptionAddProduct?.data?.length > 0 &&
                IdealConsumptionAddProduct?.data?.map((i, index) => (
                  <Fragment key={index}>
                    <div
                      className="add-ideal-consumption-grid-col"
                      onClick={() => handleImg(i.id)}
                    >
                      {ids.includes(i.id) && (
                        <div>
                          <DoneIcon fontSize="12px" />{" "}
                        </div>
                      )}

                      <img
                        style={{
                          border:
                            ids.includes(i.id) &&
                            "3px solid rgba(0, 54, 245, 1)",
                        }}
                        src={
                          i.image === null
                            ? IMG_DEFAULT
                            : process.env.REACT_APP_IMG_URL +
                              i?.image?.thumbnail
                        }
                        alt=""
                      />
                      <p>{i.sku}</p>
                    </div>
                  </Fragment>
                ))}
            </div>

            <div className="submit-cta-ideal-consumption">
              <Button onClick={handleSubmit}>
                {isLoading ? <Loader /> : "submit"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddConsumptionProduct;
