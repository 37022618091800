import React, { useEffect, useState } from "react";
import { Select } from "antd";
import "../../../css/setting.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getfilterData,
  getnodeAutoSubmissionList,
  nodeAutoSubmission,
} from "../../../store/user/actions";
import { convertDropdown } from "../../CreateFit/CreateFit";
import { toast } from "react-toastify";
import Loader from "../../../component/Loader";
import { GrSubtractCircle } from "react-icons/gr";
import { getToken } from "../../../util/Common";
import axios from "axios";

const NodeAutoSubmission = () => {
  const dispatch = useDispatch();

  // Add Node Auto Submission state
  const nodeAuto = useSelector((state) => state.nodeAuto);

  // Get Node Auto Submission State
  const nodeAutoList = useSelector((state) => state.nodeAutoList);

  // { isLoading, data, isSuccess }

  const filterData = useSelector((state) => state.filterData);

  useEffect(() => {
    dispatch(getfilterData({}));
    dispatch(getnodeAutoSubmissionList({}));
  }, [dispatch]);

  const [nodesArr, setNodesArr] = useState([]);
  const [productionNodesArr, setProductionNodesArr] = useState([]);
  const [formData, setFormData] = useState([
    { id: "", node_id: "", production_node_id: "", percentage: "" },
  ]);

  useEffect(() => {
    if (filterData && filterData?.isSuccess === true) {
      let sampleNodesArr = convertDropdown(
        filterData && filterData?.data?.sample_nodes
      );
      setNodesArr(sampleNodesArr);

      let productionNodesArr = convertDropdown(
        filterData && filterData?.data?.production_node
      );
      setProductionNodesArr(productionNodesArr);
    }
  }, [filterData]);

  const handleInputChange = (index, field, value) => {
    const newFormData = [...formData];
    newFormData[index][field] = value;
    setFormData(newFormData);
  };

  const addFormField = () => {
    setFormData([
      ...formData,
      { id: "", node_id: "", production_node_id: "", percentage: "" },
    ]);
  };

  const [isRemoved, setIsRemoved] = useState(false);

  const handleRemoveFormField = async (index, nodes) => {
    console.log("REMOVE DATA: ", index, nodes);

    if (nodes.id === "") {
      setIsRemoved(true);
      const newFormData = formData.filter((_, i) => i !== index);
      setFormData(newFormData);
      return;
    }

    if (nodes.id !== "") {
      // Code For Removing From Database
      const token = await getToken();
      const headers = {
        Authorization: "Bearer " + token,
      };
      axios
        .post(
          process.env.REACT_APP_API_URL +
            `/sample/auto_submit_node_setting_delete/${nodes.id}`,
          {},
          { headers }
        )
        .then((response) => {
          console.log("RESPONSE RATIO: ", response);
          if (response && response?.data?.status === 1) {
            toast.success(response?.data?.message);
            dispatch(getnodeAutoSubmissionList({}));
          } else {
            toast.error(response?.data?.message);
          }
        })
        .catch((err) => {
          console.log("Error: ", err);
        });
    }
  };

  console.log("Current formData:", formData); // Log current formData

  const validateFormData = () => {
    let isValid = true;
    const nodeIds = new Set();

    formData.forEach((form, index) => {
      if (!form.node_id) {
        toast.error(`Choose Node is required at row ${index + 1}`);
        isValid = false;
      } else if (nodeIds.has(form.node_id)) {
        toast.error(`Node ID must be unique at row ${index + 1}`);
        isValid = false;
      } else {
        nodeIds.add(form.node_id);
      }

      if (!form.production_node_id) {
        toast.error(`Production Node is required at row ${index + 1}`);
        isValid = false;
      }

      if (
        form.percentage < 1 ||
        form.percentage > 100 ||
        form.percentage === ""
      ) {
        toast.error(`Percentage must be between 1 and 100 at row ${index + 1}`);
        isValid = false;
      }
    });

    return isValid;
  };

  const [isCreated, setIsCreated] = useState(false);
  const handleSubmit = () => {
    const updatedData = formData.map((obj) => {
      // Create a new object with keys where the values are not empty strings
      return Object.fromEntries(
        Object.entries(obj).filter(([key, value]) => value !== "")
      );
    });
    console.log("FormData before submit:", updatedData); // Log before submit
    if (validateFormData()) {
      setIsCreated(true);
      dispatch(nodeAutoSubmission({ data: updatedData }));
      // console.log("FormData dispatched:", formData); // Log dispatched data
      // Submit formData to your API or handle it as required
    }
  };

  useEffect(() => {
    if (
      nodeAutoList?.isSuccess &&
      nodeAutoList?.data?.length > 0 &&
      !isRemoved
    ) {
      const mappedData = nodeAutoList?.data?.map((item) => ({
        id: item.id,
        node_id: item.sample_node_id,
        production_node_id: item.operation_type_id,
        percentage: item.auto_submission_percentage,
      }));
      setFormData(mappedData);
    }
  }, [nodeAutoList?.data, nodeAutoList?.isSuccess, isRemoved]);

  useEffect(() => {
    if (nodeAuto && nodeAuto?.isSuccess === true && isCreated) {
      toast.success("Node Auto Submission Created Successfully!");
      setIsCreated(false);
    }
  }, [nodeAuto, isCreated]);

  console.log("NODE AUTO LIST: ", nodeAuto, nodeAutoList);

  return (
    <div>
      {formData.map((form, index) => (
        <div className="row node-auto-submission" key={index}>
          <div className="col-md-4 col-lg-4 col-12 mb-2 px-4">
            <label className="select-label-bold">Choose Node</label>
            <Select
              placeholder="Choose Node"
              options={nodesArr}
              value={form.node_id}
              onChange={(value) => handleInputChange(index, "node_id", value)}
            />
          </div>
          <div className="col-md-4 col-lg-4 col-12 mb-2">
            <label className="select-label-bold">Production Node</label>
            <Select
              placeholder="Production Node"
              options={productionNodesArr}
              value={form.production_node_id}
              onChange={(value) =>
                handleInputChange(index, "production_node_id", value)
              }
            />
          </div>
          <div className="col-md-4 col-lg-4 col-12 mb-2">
            <label className="select-label-bold">
              % Completion Required for Auto Sub..
            </label>
            <input
              placeholder="% Completion Required for Auto Sub.."
              className="input-custom"
              type="number"
              min="1"
              max="100"
              value={form.percentage}
              onChange={(e) =>
                handleInputChange(index, "percentage", e.target.value)
              }
              style={{
                height: "48px",
                width: "100%",
              }}
            />
          </div>
          <div className="col-md-4 col-lg-4 col-12 mb-2 subtract-button">
            <button
              className="btn btn-danger"
              type="button"
              onClick={() => handleRemoveFormField(index, form)}
            >
              <GrSubtractCircle />
            </button>
          </div>
        </div>
      ))}
      <div className="remark-buttons category-size-row">
        <button className="dark-but mx-2" type="button" onClick={addFormField}>
          Add
        </button>
        <button className="dark-but" type="button" onClick={handleSubmit}>
          {nodeAuto?.isLoading && isCreated ? <Loader /> : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default NodeAutoSubmission;
