import React, { useEffect, useState } from "react";
import Searchbar from "../../../component/ListSearchBar/Searchbar";
import {
  getfilterData,
  getresponsiblePerson,
  getuserlistWithWorkload,
} from "../../../store/user/actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../component/Loader";
import UserNameImage from "../../../component/UserNameImage/UserNameImage";
import { resetresponsiblePersonState } from "../../../store/user/userSlice";

const PersonModal = ({
  selectNode,
  viewPersonModal,
  refreshNodeData,
  assignuserId,
}) => {
  const [value, setValue] = useState("");
  const [userlist, setUserlist] = useState([]);
  // const filterData = useSelector(state => state.filterData.data)
  const responsiblePerson = useSelector((state) => state.responsiblePerson);
  const userlistWithWorkload = useSelector(
    (state) => state.userlistWithWorkload
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getuserlistWithWorkload({ selectNode }));
  }, []);

  // const userOption = Object.entries(filterData.user).map(([value, label]) => ({
  //   value: parseInt(value, 10),
  //   label,
  // }));
  useEffect(() => {
    const users = userlistWithWorkload.data;
    const filteredData = users.filter((user) => {
      return user.firstname.indexOf(value) !== -1;
    });
    setUserlist(filteredData);
  }, [value]);

  useEffect(() => {
    setUserlist(userlistWithWorkload.data);
  }, [userlistWithWorkload]);

  const assined = (userId) => {
    dispatch(getresponsiblePerson({ nodeId: selectNode, userId }));
    if (responsiblePerson.isLoading == false) {
      //  viewPersonModal(false)
    }
  };
  useEffect(() => {
    if (responsiblePerson.isSuccess) {
      dispatch(resetresponsiblePersonState());
      refreshNodeData();
      viewPersonModal(false);
    }
  }, [responsiblePerson]);

  return (
    <div className="mt-4">
      <Searchbar
        value={value}
        setValue={(e) => setValue(e.target.value)}
        placeholder="Search"
        fontSize="12px"
        backgroundColor="#F5F5F5"
        borderRadius="4px"
        searchIcon
        width="354px"
        height="48px"
        position="relative"
        padding="0px 20px"
      />
      <div className="position-relative">
        {userlistWithWorkload.isLoading && (
          <div className="loader_bg">
            <Loader />
          </div>
        )}
        {responsiblePerson.isLoading && (
          <div className="loader_bg">
            <Loader />
          </div>
        )}
      </div>
      <div className="persion-scroll">
        {userlist.map((item) => (
          <div className="persion-flex response-flex-row">
            <div className="d-flex">
              <UserNameImage
                width="42px"
                height="42px"
                margin="0 auto"
                name={item.firstname + " " + item.lastname}
                image={item.profile_image_thumbnail}
              />
              {/* <img src={item.profile_image_thumbnail} /> */}
              <div>
                <h6>{item.firstname + " " + item.lastname}</h6>
                <p>Current Workload - {item.workload}</p>
              </div>
            </div>
            <button className="assigne" onClick={() => assined(item.id)}>
              {assignuserId == item.id ? "Assigned" : "Assign"}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PersonModal;
