import React, { useEffect, useState } from "react";
import "./Styles/ImageColorAddition.css";
import { useDispatch, useSelector } from "react-redux";
import {
  ImageColorAdditionActions,
  getImageSelectionDatActions,
} from "../../store/user/actions";
import { Button } from "antd";
import axios from "axios";
import { getToken } from "../../util/Common";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../component/Loader";

const ImageColorSelection = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [selections, setSelections] = useState([]);

  // Get Image color Addition State
  const ImageSelectionData = useSelector(
    (state) => state.ImageSelectionData.data
  );

  useEffect(() => {
    dispatch(getImageSelectionDatActions(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (ImageSelectionData?.color_image) {
      setSelections(
        ImageSelectionData.color_image.map((item) => ({
          color_type_id: item?.color?.id,
          ratio: "",
          image: JSON.parse(item.image).map((img) => ({
            ...img,
            select: false,
          })),
        }))
      );
    }
  }, [ImageSelectionData]);

  const handleCheckboxChange = (index) => {
    const newSelections = [...selections];
    newSelections[index].checked = !newSelections[index].checked;
    setSelections(newSelections);
  };

  const handleImageSelect = (itemIndex, imageIndex) => {
    const newSelections = [...selections];
    newSelections[itemIndex].image = newSelections[itemIndex].image.map(
      (img, idx) => ({
        ...img,
        select: idx === imageIndex,
      })
    );
    setSelections(newSelections);
  };

  const handleRatioChange = (index, value) => {
    const newSelections = [...selections];
    newSelections[index].ratio = value;
    setSelections(newSelections);
  };

  const [isLoading, setIsLoading] = useState(false);

  const data = selections
    .filter((item) => item.checked)
    .map((item) => ({
      color_type_id: item.color_type_id,
      ratio: item.ratio,
      image: item.image,
    }));

  console.log({ data }, { selections });

  const handleSubmit = async () => {
    const data = selections
      .filter((item) => item.checked)
      .map((item) => ({
        color_type_id: item.color_type_id,
        ratio: item.ratio,
        // image: item.image.filter((img) => img.select),
        image: item.image,
      }));

    setIsLoading(true);
    await axios({
      method: "POST",
      url:
        process.env.REACT_APP_API_URL +
        `/sample_request/color_image_selection_add/${id}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: { data },
    })
      .then((response) => {
        if (response?.data?.status === 0) {
          toast.error(response?.data?.message);
          setIsLoading(false);
        } else if (response?.data?.status === 1) {
          toast.success("Data Saved Successfully");
          setIsLoading(false);
          navigate("/pending-task");
        }
        // console.log(response);
        // toast.success(response?.data?.message);
      })
      .catch((error) => {
        // console.log(error.response.data.message);
        setIsLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <div className="create-fit-wrapper">
      <div className="d-flex align-items-baseline justify-content-between pb-2">
        <div className="heading heading-row-master-fit">
          <img
            onClick={() => navigate(-1)}
            src="/Images/arrow-left.svg"
            alt="Back_Icon"
          />
          <h2>Image & Color Selection</h2>
        </div>
        <div className="download-but-excel"></div>
      </div>

      <div className="select-addition-wrapper">
        <div className="select-addition-grid-row">
          <div>
            <label className="labelx" htmlFor="">
              Sample Collection Name
            </label>
            <br />
            <input
              disabled={true}
              type="text"
              value={ImageSelectionData.collection}
            />
          </div>
          <div>
            <label className="labelx" htmlFor="">
              Product Group
            </label>
            <br />
            <input
              disabled={true}
              type="text"
              value={ImageSelectionData.product_group}
            />
          </div>
          <div>
            <label className="labelx" htmlFor="">
              Child Category
            </label>
            <br />
            <input
              disabled={true}
              type="text"
              value={ImageSelectionData.categorie}
            />
          </div>
        </div>
      </div>

      {ImageSelectionData?.color_image?.map((item, itemIndex) => (
        <div className="selecting-checkbox-wrapper my-3" key={item.id}>
          <div className="color-variation-grid-row">
            <div>
              <div className="checkbox-grid-row">
                <div>
                  {/* <input
                    type="checkbox"
                    id={`checkbox-${item.id}`}
                    checked={selections[itemIndex]?.checked || false}
                    onChange={() => handleCheckboxChange(itemIndex)}
                  />
                  <label htmlFor={`checkbox-${item.id}`}></label> */}

                  <label htmlFor={`checkbox-${item.id}`} class="checkbox-btn">
                    <input
                      type="checkbox"
                      id={`checkbox-${item.id}`}
                      checked={selections[itemIndex]?.checked || false}
                      onChange={() => handleCheckboxChange(itemIndex)}
                    />
                    <span></span>
                  </label>
                </div>
                <div>
                  <label className="labelx" htmlFor={`input-${item.id}`}>
                    {item?.color?.name}
                  </label>
                  <input
                    type="text"
                    value={item?.color?.name}
                    disabled={true}
                    name=""
                    id={`input-${item.id}`}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="images-grid-row-checkbox">
                {JSON.parse(item?.image)?.map((img, imageIndex) => (
                  <img
                    key={imageIndex}
                    src={process.env.REACT_APP_IMG_URL + img.url}
                    alt=""
                    onClick={() => handleImageSelect(itemIndex, imageIndex)}
                    style={{
                      border: selections[itemIndex]?.image[imageIndex]?.select
                        ? "2px solid blue"
                        : "none",
                    }}
                  />
                ))}
              </div>
            </div>
            <div>
              <label htmlFor={`ratio-${item.id}`} className="labelx">
                Enter Color Variation Ratio
              </label>
              <br />
              <input
                className="input-checkbox"
                type="text"
                name=""
                id={`ratio-${item.id}`}
                value={selections[itemIndex]?.ratio || ""}
                onChange={(e) => handleRatioChange(itemIndex, e.target.value)}
              />
            </div>
          </div>
        </div>
      ))}

      <div className="image-color-addition-cta">
        <Button onClick={handleSubmit}>
          {isLoading ? <Loader /> : "SUBMIT"}
        </Button>
      </div>

      {/* <button onClick={handleSubmit}>Submit</button> */}
    </div>
  );
};

export default ImageColorSelection;
