import React from "react";
import IMG_DEFAULT from "../../no-image.jpg";

const UserNameImage = ({
  image,
  name,
  width,
  height,
  margin,
  title,
  fontSize,
}) => {
  const getInitials = (name) => {
    const words = name.split(" ");
    const initials = words.map((word) => word[0]);
    return initials.join("");
  };

  console.log("IMAGE: ", image);
  return (
    <div style={{ width, height, margin, fontSize }} title={title}>
      {image == "" ? (
        <div className="user_image_box">{getInitials(name)}</div>
      ) : (
        <img
          src={process.env.REACT_APP_IMG_URL + image}
          onError={(e) => (e.target.src = IMG_DEFAULT)}
        />
      )}
    </div>
  );
};

export default UserNameImage;
