import React, { useEffect, useState } from "react";
import { getfilterData } from "../../../store/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import { Link } from "react-router-dom";

const TargetSelect = ({ selectBox }) => {
  const initialValues = {
    collectionId: "",
    nodeId: "",
    userId: "",
  };

  // const rangeDate = {
  //   originalDate: [new Date(), new Date()],
  // };

  const [FromPercentage, setFromPercentage] = useState("");
  const [Topercentage, setToPercentage] = useState("");

  const [formValues, setFormValue] = useState(initialValues);
  const [rangePickerDate, setrangePickerDate] = useState([]);
  const filterData = useSelector((state) => state.filterData.data);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getfilterData(formValues));
    // filterValue(formValues)
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    selectBox(formValues);
    // eslint-disable-next-line
  }, [formValues]);
  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };

  const onChange = (name, date) => {
    let data = { ...rangePickerDate };
    data[name] = date;
    setrangePickerDate(data);
    var currentDate = new Date(date[0]);
    // Get the year, month, and day from the date
    var year = currentDate.getFullYear();
    var month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-based
    var day = String(currentDate.getDate()).padStart(2, "0");
    // Format the date in "Y-m-d" format
    var toDate = `${year}-${month}-${day}`;
    // eslint-disable-next-line
    var currentDate = new Date(date[1]);
    // Get the year, month, and day from the date
    // eslint-disable-next-line
    var year = currentDate.getFullYear();
    // eslint-disable-next-line
    var month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-based
    // eslint-disable-next-line
    var day = String(currentDate.getDate()).padStart(2, "0");

    // Format the date in "Y-m-d" format
    var fromDate = `${year}-${month}-${day}`;
    let formdata = { ...formValues };
    formdata[name + "From"] = toDate;
    formdata[name + "To"] = fromDate;
    // formdata[name]=date
    setFormValue(formdata);
  };
  const sampleNodesOption = Object.entries(filterData.sample_nodes).map(
    ([value, label]) => ({
      value: parseInt(value, 10),
      label,
    })
  );
  const sampleCollectionOption = Object.entries(
    filterData.sample_collection
  ).map(([value, label]) => ({
    value: parseInt(value, 10),
    label,
  }));
  const userList = Object.entries(filterData.user).map(([value, label]) => ({
    value: parseInt(value, 10),
    label,
  }));

  const percentArray = Array.from({ length: 101 }, (_, index) => index);

  // Map over the array and format each item into an object
  const percentObjects = percentArray.map((value, index) => ({
    value: index,
    label: `${value} %`,
  }));

  console.log(percentObjects);

  const today = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(today.getMonth() - 1);

  return (
    <div>
      <div className="row mt-4 mb-4">
        <div className="col-md-3 col-lg-3 col-12 mb-2">
          <label className="select-label">Collection</label>
          <Select
            defaultValue="Collection Name"
            value={formValues.collectionId}
            onChange={(e) => handleChange("collectionId", e)}
            options={sampleCollectionOption}
            showSearch
            optionFilterProp="children"
            filterOption={(input, userOption) =>
              ((userOption?.label).toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(userOption) =>
              (userOption?.label ?? "")
                .toLowerCase()
                .localeCompare((userOption?.label ?? "").toLowerCase())
            }
          />
        </div>
        <div className="col-md-3 col-lg-3 col-12 mb-2">
          <label className="select-label">Assign User</label>
          <Select
            defaultValue="Collection Name"
            value={formValues.userId}
            onChange={(e) => handleChange("userId", e)}
            options={userList}
            showSearch
            optionFilterProp="children"
            filterOption={(input, userOption) =>
              ((userOption?.label).toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(userOption) =>
              (userOption?.label ?? "")
                .toLowerCase()
                .localeCompare((userOption?.label ?? "").toLowerCase())
            }
          />
        </div>
        <div className="col-md-3 col-lg-3 col-12 mb-2">
          <label className="select-label">Node</label>
          <Select
            defaultValue="Next Node"
            value={formValues.nodeId}
            onChange={(e) => handleChange("nodeId", e)}
            options={sampleNodesOption}
            showSearch
            optionFilterProp="children"
            filterOption={(input, userOption) =>
              ((userOption?.label).toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(userOption) =>
              (userOption?.label ?? "")
                .toLowerCase()
                .localeCompare((userOption?.label ?? "").toLowerCase())
            }
          />
        </div>
        <div className="col-md-3 col-lg-3 col-12 mb-2">
          <label className="select-label">Date From and To</label>
          <DateRangePicker
            placeholder="Actual Date"
            onChange={(e) => onChange("originalDate", e)}
            value={rangePickerDate.originalDate}
            clearIcon={false}
            minDate={oneMonthAgo}
          />
        </div>
        <div className="col-md-6 col-lg-6 col-12 mb-2">
          <label className="select-label">
            Highlights columns where targets Competition is from percentage{" "}
          </label>
          <Select
            defaultValue="%"
            // value={formValues.sample_collection_id}
            onChange={(e) => {
              setFromPercentage(e);
              handleChange("percentage", e);
            }}
            options={percentObjects}
            showSearch
            optionFilterProp="children"
            value={FromPercentage}
            filterOption={(input, userOption) =>
              ((userOption?.label).toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(userOption) =>
              (userOption?.label ?? "")
                .toLowerCase()
                .localeCompare((userOption?.label ?? "").toLowerCase())
            }
          />
        </div>
        <div className="col-md-6 col-lg-6 col-12 mb-2">
          <label className="select-label">
            Highlights columns where targets Competition is to percentage{" "}
          </label>
          <Select
            defaultValue="%"
            // value={formValues.sample_collection_id}
            onChange={(e) => {
              setToPercentage(e);
              handleChange("Topercentage", e);
            }}
            value={Topercentage}
            options={percentObjects}
            showSearch
            optionFilterProp="children"
            filterOption={(input, userOption) =>
              ((userOption?.label).toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(userOption) =>
              (userOption?.label ?? "")
                .toLowerCase()
                .localeCompare((userOption?.label ?? "").toLowerCase())
            }
          />
        </div>
        <div className="clearDatas mb-3">
          <Link
            onClick={() => {
              setFromPercentage("");
              setToPercentage("");
              setrangePickerDate([]);
              setFormValue(initialValues);
            }}
            className="clear_data"
          >
            Clear Data
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TargetSelect;
