import React from "react";
import HistoryTable from "./component/HistoryTable";

const History = () => {
  return (
    <div>
      <HistoryTable />
    </div>
  );
};

export default History;
