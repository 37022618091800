import React, { useEffect, useState } from "react";
import { DatePickerProps } from "antd";
import { Select, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getfilterData } from "../../../store/user/actions";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import { Link } from "react-router-dom";
// import 'react-calendar/dist/Calendar.css';

// import moment from "moment";

const submission_type_arr = [
  {
    id: 1,
    value: 1,
    label: "Auto",
  },
  {
    id: 2,
    value: 2,
    label: "Manual",
  },
];

const SubmissionSelect = ({ filterValue, hiddenInput = { user: false } }) => {
  const initialValues = {
    userId: "",
    sampleRequestNo: "",
    sample_collection_id: "",
    sampleCategoryId: "",
    currentPendingNodeId: "",
    nextNodeId: "",
    previousNodeId: "",
    actualDateTo: "",
    actualDate: null,
    trail: "",
    delay: "",
    submissionNumber: "",
    submission_type: "",
  };
  const rangeDate = {
    actualDate: [new Date(), new Date()],
    planDate: [new Date(), new Date()],
    originalDate: [new Date(), new Date()],
  };
  const [formValues, setFormValue] = useState(initialValues);
  const [rangePickerDate, setrangePickerDate] = useState(rangeDate);
  const filterData = useSelector((state) => state.filterData.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getfilterData(formValues));
    filterValue(formValues);
  }, [formValues]);

  const onChange = (name, date) => {
    let data = { ...rangePickerDate };
    data[name] = date;
    setrangePickerDate(data);
    var currentDate = new Date(date[0]);
    // Get the year, month, and day from the date
    var year = currentDate.getFullYear();
    var month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-based
    var day = String(currentDate.getDate()).padStart(2, "0");
    // Format the date in "Y-m-d" format
    var toDate = `${year}-${month}-${day}`;

    var currentDate = new Date(date[1]);
    // Get the year, month, and day from the date
    var year = currentDate.getFullYear();
    var month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-based
    var day = String(currentDate.getDate()).padStart(2, "0");

    // Format the date in "Y-m-d" format
    var fromDate = `${year}-${month}-${day}`;
    let formdata = { ...formValues };
    formdata[name + "From"] = toDate;
    formdata[name + "To"] = fromDate;
    // formdata[name]=date
    setFormValue(formdata);
  };

  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };

  const userOption = Object.entries(filterData.user).map(([value, label]) => ({
    value: parseInt(value, 10),
    label,
  }));
  const sampleRequestOption = Object.entries(filterData.sample_request).map(
    ([value, label]) => ({
      value: parseInt(value, 10),
      label,
    })
  );
  const sampleCollectionOption = Object.entries(
    filterData.sample_collection
  ).map(([value, label]) => ({
    value: parseInt(value, 10),
    label,
  }));
  const sampleCategoriesOption = Object.entries(
    filterData.sample_categories
  ).map(([value, label]) => ({
    value: parseInt(value, 10),
    label,
  }));
  const sampleNodesOption = Object.entries(filterData.sample_nodes).map(
    ([value, label]) => ({
      value: parseInt(value, 10),
      label,
    })
  );
  const sampleTrailOption = Object.entries(filterData.sample_trail).map(
    ([value, label]) => ({
      value: value,
      label,
    })
  );

  const sampleSubmissionOption = [
    {
      value: "1",
      lable: "1",
    },
    {
      value: "2",
      lable: "2",
    },
    {
      value: "3",
      lable: "3",
    },
    {
      value: "4",
      lable: "4",
    },
    {
      value: "5",
      lable: "5",
    },
    {
      value: "6",
      lable: "6",
    },
    {
      value: "7",
      lable: "7",
    },
    {
      value: "8",
      lable: "8",
    },
    {
      value: "9",
      lable: "9",
    },
    {
      value: "10",
      lable: "10",
    },
  ];
  // const handleDateChange = (dates) => {
  //   setSelectedDates(dates);
  // };submissionNumber
  return (
    <div className="row mt-4">
      {/* {hiddenInput.user !== undefined && hiddenInput.user == true?'':
     
 } */}
      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Sample Request Number</label>
        <Select
          defaultValue="Sample Request Number"
          value={formValues.sampleRequestNo}
          onChange={(e) => handleChange("sampleRequestNo", e)}
          options={sampleRequestOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleRequestOption) =>
            (sampleRequestOption?.label ?? "").includes(input)
          }
          filterSort={(sampleRequestOption) =>
            (sampleRequestOption?.label ?? "")
              .toLowerCase()
              .localeCompare((sampleRequestOption?.label ?? "").toLowerCase())
          }
        />
      </div>

      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Collection Name</label>
        <Select
          defaultValue="Collection Name"
          value={formValues.sample_collection_id}
          onChange={(e) => handleChange("sample_collection_id", e)}
          options={sampleCollectionOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, userOption) =>
            ((userOption?.label).toLowerCase() ?? "").includes(
              input.toLowerCase()
            )
          }
          filterSort={(userOption) =>
            (userOption?.label ?? "")
              .toLowerCase()
              .localeCompare((userOption?.label ?? "").toLowerCase())
          }
        />
      </div>
      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Username</label>
        <Select
          showSearch
          placeholder="Username / Id"
          value={formValues.userId}
          onChange={(e) => handleChange("userId", e)}
          options={userOption}
          optionFilterProp="children"
          filterOption={(input, userOption) =>
            ((userOption?.label).toLowerCase() ?? "").includes(
              input.toLowerCase()
            )
          }
          filterSort={(userOption) =>
            (userOption?.label ?? "")
              .toLowerCase()
              .localeCompare((userOption?.label ?? "").toLowerCase())
          }
        />
      </div>
      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Submission Number</label>
        <Select
          showSearch
          placeholder="Submission Number"
          value={formValues.submissionNumber}
          onChange={(e) => handleChange("submissionNumber", e)}
          options={sampleSubmissionOption}
          optionFilterProp="children"
          filterOption={(input, sampleSubmissionOption) =>
            (sampleSubmissionOption?.lable ?? "").includes(input)
          }
          filterSort={(sampleSubmissionOption) =>
            (sampleSubmissionOption?.lable ?? "")
              .toLowerCase()
              .localeCompare(
                (sampleSubmissionOption?.lable ?? "").toLowerCase()
              )
          }
        />
      </div>
      {/* <div className='col-md-3 col-lg-3 col-12 mb-2'>
      <label className='select-label'>Current Pending Node</label>
        <Select
          defaultValue="Current Pending Node"
          value={formValues.currentPendingNodeId}
          onChange={(e) => handleChange("currentPendingNodeId", e)}
          options={sampleNodesOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleNodesOption) => (sampleNodesOption?.label ?? '').includes(input)}
          filterSort={(sampleNodesOption) =>
            (sampleNodesOption?.label ?? '').toLowerCase().localeCompare((sampleNodesOption?.label ?? '').toLowerCase())
          }
        />
      </div> */}
      {/* <div className='col-md-3 col-lg-3 col-12 mb-2'>
      <label className='select-label'>Next Node</label>
        <Select
          defaultValue="Next Node"
          value={formValues.nextNodeId}
          onChange={(e) => handleChange("nextNodeId", e)}
          options={sampleNodesOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleNodesOption) => (sampleNodesOption?.label ?? '').includes(input)}
          filterSort={(sampleNodesOption) =>
            (sampleNodesOption?.label ?? '').toLowerCase().localeCompare((sampleNodesOption?.label ?? '').toLowerCase())
          }
        />
      </div> */}
      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Actual Date</label>
        <DateRangePicker
          //  placeholder="Actual Date"
          onChange={(e) => onChange("actualDate", e)}
          value={rangePickerDate.actualDate}
          clearIcon={false}
        />
      </div>
      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Current Planned Date</label>
        <DateRangePicker
          placeholder="Current Planned Date"
          onChange={(e) => onChange("planDate", e)}
          value={rangePickerDate.planDate}
          yearAriaLabel="Year"
          clearIcon={false}
        />
      </div>
      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Original Planned Date</label>
        <DateRangePicker
          placeholder="Original Planned Date"
          onChange={(e) => onChange("originalDate", e)}
          value={rangePickerDate.originalDate}
          yearAriaLabel="Year"
          clearIcon={false}
        />
      </div>
      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Previous Node</label>
        <Select
          defaultValue="Previous Node"
          value={formValues.previousNodeId}
          onChange={(e) => handleChange("previousNodeId", e)}
          options={sampleNodesOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, userOption) =>
            ((userOption?.label).toLowerCase() ?? "").includes(
              input.toLowerCase()
            )
          }
          filterSort={(userOption) =>
            (userOption?.label ?? "")
              .toLowerCase()
              .localeCompare((userOption?.label ?? "").toLowerCase())
          }
        />
      </div>
      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Submission Type</label>
        <Select
          defaultValue="Previous Node"
          value={formValues.submission_type}
          onChange={(e) => handleChange("submission_type", e)}
          options={submission_type_arr}
          showSearch
          optionFilterProp="children"
          filterOption={(input, userOption) =>
            ((userOption?.label).toLowerCase() ?? "").includes(
              input.toLowerCase()
            )
          }
          filterSort={(userOption) =>
            (userOption?.label ?? "")
              .toLowerCase()
              .localeCompare((userOption?.label ?? "").toLowerCase())
          }
        />
      </div>
      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Trial</label>
        <Select
          defaultValue="Trial"
          value={formValues.trail}
          onChange={(e) => handleChange("trail", e)}
          options={sampleTrailOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, userOption) =>
            ((userOption?.label).toLowerCase() ?? "").includes(
              input.toLowerCase()
            )
          }
          filterSort={(userOption) =>
            (userOption?.label ?? "")
              .toLowerCase()
              .localeCompare((userOption?.label ?? "").toLowerCase())
          }
        />
      </div>
      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Sample Category</label>
        <Select
          defaultValue="Sample Category"
          value={formValues.sampleCategoryId}
          onChange={(e) => handleChange("sampleCategoryId", e)}
          options={sampleCategoriesOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, userOption) =>
            ((userOption?.label).toLowerCase() ?? "").includes(
              input.toLowerCase()
            )
          }
          filterSort={(userOption) =>
            (userOption?.label ?? "")
              .toLowerCase()
              .localeCompare((userOption?.label ?? "").toLowerCase())
          }
        />
      </div>
      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Delay Duration</label>
        <input
          placeholder="Delay Duration"
          className="input-custom"
          onChange={(e) => handleChange("delay", e.target.value)}
          value={formValues.delay}
          style={{
            height: "48px",
            width: "100%",
            border: "none",
          }}
        />
      </div>
      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <div className="clearDatas">
          <Link
            onClick={() => setFormValue(initialValues)}
            className="clear_data"
          >
            Clear Data
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SubmissionSelect;
