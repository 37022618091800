import React, { useEffect, useState } from "react";
import Flow from "./components/Flow";

import { Link } from "react-router-dom";
import DefaultModal from "../../component/DefaultModal/DefaultModal";
import AddModal from "./components/AddModal";
import AddTrail from "./components/AddTrail";
import { getcategoryNode, getfilterData } from "../../store/user/actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../component/Loader";

const SampleNodes = () => {
  const initialValues = {
    sampleCategoryId: "",
  };
  const [formValues, setFormValue] = useState(initialValues);
  const [show, setShow] = useState(false);
  const [trailModel, setTrailModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [trail, setTrail] = useState([]);
  const [selectTrail, setSelectTrail] = useState(0);
  const [sampleTrail, setSampleTrail] = useState([]);
  const [refreshNode, setRefreshNode] = useState(true);
  const categoryNode = useSelector((state) => state.categoryNode.data);
  const filterData = useSelector((state) => state.filterData.data);
  const dispatch = useDispatch();
  useEffect(() => {
    if (formValues.sampleCategoryId != "") {
      dispatch(
        getcategoryNode({ id: formValues.sampleCategoryId, trail: trail })
      );
    }
  }, [formValues, trail]);
  useEffect(() => {
    const trailOption = Object.entries(filterData.trail).map(
      ([value, label]) => ({
        value: parseInt(value, 10),
        label,
      })
    );
    trailOption.unshift({
      value: "0",
      label: "Add New Trail",
    });
    setSampleTrail(trailOption);
  }, [filterData]);
  useEffect(() => {
    dispatch(getfilterData({}));
  }, [trailModel]);

  const trailData = (value) => {
    setTrail([...trail, value]);
  };

  return (
    <div>
      <div className="d-flex align-items-baseline justify-content-between pb-4">
        <div className="d-flex align-items-baseline">
          <div className="heading">
            <Link to="/">
              <img src="/Images/arrow-left.svg" />
            </Link>
          </div>
          <h2>Sample Node</h2>
        </div>
        <div className="d-flex">
          <div className="view-but">
            <Link onClick={() => setTrailModel(true)}>Add trail</Link>
          </div>
          <div
            className="view-but ml-2"
            style={{ marginLeft: "10px", marginRight: "10px" }}
          >
            <Link onClick={() => setAddModel(true)}>Add Node</Link>
          </div>
          <div className="view-but-gray">
            <Link onClick={() => setShow(true)}>View connection</Link>
          </div>
        </div>
      </div>
      {categoryNode.isLoading ? (
        <div className="">
          <Loader />
        </div>
      ) : (
        <div className="trail">
          <Flow
            trail={trail}
            trailChange={setSelectTrail}
            refreshNode={refreshNode}
            addNode={setAddModel}
          />
        </div>
      )}
      <DefaultModal
        show={addModel}
        onHide={() => setAddModel(false)}
        className="remark-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>ADD</h4>
          <div className="close-icon">
            <img
              src="/Images/closeBlack.svg"
              onClick={() => setAddModel(false)}
            />
          </div>
        </div>
        {/* {categoryNode.isLoading && (
          <div className="">
            <Loader />
          </div>
        )} */}
        {selectTrail ? (
          <AddModal
            AddTraileModal={setAddModel}
            selectTrail={selectTrail}
            refreshNode={() => setRefreshNode(!refreshNode)}
          />
        ) : (
          <div className="text-center">Please Select Category and Trail</div>
        )}
      </DefaultModal>

      <DefaultModal
        show={trailModel}
        onHide={() => setTrailModel(false)}
        className="remark-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>ADD Trail</h4>
          <div className="close-icon">
            <img
              src="/Images/closeBlack.svg"
              onClick={() => setTrailModel(false)}
            />
          </div>
        </div>
        <AddTrail
          sampleTrail={sampleTrail}
          trail={trailData}
          trailAddM={setTrailModel}
        />
      </DefaultModal>
      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="connection-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4></h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setShow(false)} />
          </div>
        </div>

        <Flow isInsideModal={true} trail={trail} />
      </DefaultModal>
    </div>
  );
};

export default SampleNodes;
