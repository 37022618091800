import React, { useEffect, useState } from "react";
import RequestSelect from "./component/RequestSelect";
import "./style.css";
import IMG_DEFAULT from "../../no-image.jpg";
import { Fragment } from "react";
import { useDropzone } from "react-dropzone";
import PlusIcon from "../../component/Icon/plus_icon.png";
import { useNavigate, useParams } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button } from "antd";
import Loader from "../../component/Loader";
import { toast } from "react-toastify";
import axios from "axios";
import { getToken } from "../../util/Common";
import DefaultModal from "../../component/DefaultModal/DefaultModal";
import AddProductIdeal from "./component/AddProductIdeal";
import { Helmet } from "react-helmet";

const IdealConsumption = () => {
  const { id, isTrue } = useParams();
  const navigate = useNavigate();
  const [IdealConsumption_Arr, setIdealConsumption_Arr] = useState([]);

  const [product_group_id, setProduct_group_id] = useState("");
  const [show, setShow] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
  });
  // const [files, setFiles] = useState([]);

  const [qty, setQty] = useState("");
  // const [product_ids, setProduct_ids] = useState("");

  const handleQty = (val, id, pId) => {
    // const updatedArr = [...IdealConsumption_Arr];
    console.log("UPDATED ARR: ", val, id, pId);
    setQty(val);
  };

  const [Loading, setLoading] = useState(false);

  console.log("IDEAL CONSUMPTION ARR: ", IdealConsumption_Arr);

  const handleRedirect = (event, id) => {
    event.preventDefault();
    // toast.success("OK");
    navigate(`/add-consumption-product/${id}/${product_group_id}`);
  };

  // const [isLoading, setIsLoading] = useState(false);

  const handleEnterPress = async (event, id, pId) => {
    if (event.key === "Enter") {
      const formData = new FormData();

      formData.append(`product_ids[0]`, pId);
      formData.append("request_id", product_group_id);
      formData.append("product_sub_group_id", id);
      formData.append("qty", qty);

      // setIsLoading(true);
      await axios({
        method: "POST",
        url:
          process.env.REACT_APP_API_URL +
          `/sample/submit_ideal_consumption_product`,
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
        data: formData,
      })
        .then((response) => {
          if (response?.data?.status === 0) {
            toast.error(response?.data?.message);
            // setIsLoading(false);
          } else if (response?.data?.status === 1) {
            toast.success("Data Saved Successfully");
            // setIsLoading(false);
          }
        })
        .catch((error) => {
          // setIsLoading(false);
          toast.error(error?.response?.data?.message);
        });
    }
  };

  const [product_ids, setproduct_ids] = useState("");
  const [product_sub_group_id, setproduct_sub_group_id] = useState("");

  const handleModal = (id, pId) => {
    setShow(!show);
    setproduct_ids(id);
    setproduct_sub_group_id(pId);
  };

  console.log("ID ISTRUE: ", id, isTrue);

  const [sample_req_number, setSample_req_number] = useState("");
  const [style_number, setStyleNumber] = useState("");

  useEffect(() => {
    const fetchIdealConsumption = () => {
      const token = getToken();
      const headers = { Authorization: "Bearer " + token };
      axios
        .post(
          process.env.REACT_APP_API_URL +
            `/sample/list_ideal_consumption/${id}`,
          {},
          { headers }
        )
        .then((response) => {
          console.log("RESPONSE: ", response?.data?.data);
          setIdealConsumption_Arr(response?.data?.data?.list);
          setSample_req_number(response?.data?.data?.sample_request_id);
          setStyleNumber(response?.data?.data?.style_type_id);
        })
        .catch((err) => {
          console.log("ERROR: ", err);
        });
    };

    if (id !== null && id !== undefined) {
      fetchIdealConsumption();
    }
  }, [id]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ideal Consumption | Sample Dashboard</title>
      </Helmet>
      <div className="d-flex align-items-baseline justify-content-between pb-2">
        <div className="heading">
          <h2>Ideal Consumption</h2>
        </div>
        <div className="download-but">
          <button>
            <img src="Images/document-download.svg" alt="" />
            Download Excel
          </button>
        </div>
      </div>
      <div className="ideal-consumption-wrapper">
        <RequestSelect
          setIdealConsumption_Arr={setIdealConsumption_Arr}
          setProduct_group_id={setProduct_group_id}
          setLoading={setLoading}
          isActives={id}
          sample_req_number={sample_req_number}
          style_number={style_number}
        />

        {/* 70 30 grid col component starts here */}
        {Loading ? (
          <div className="loader_icons">
            <Loader />
          </div>
        ) : (
          <div>
            {IdealConsumption_Arr && IdealConsumption_Arr?.length > 0 ? (
              <div>
                {IdealConsumption_Arr &&
                  IdealConsumption_Arr?.length > 0 &&
                  IdealConsumption_Arr?.map((i, index) => (
                    <div key={index} className="grid-ideal-consumption-row">
                      <div>
                        <div className="ideal-img-grid-row">
                          <div>
                            <img
                              src={
                                i.thumbnail !== null
                                  ? process.env.REACT_APP_IMG_URL + i.thumbnail
                                  : IMG_DEFAULT
                              }
                              alt=""
                            />
                            <div className="product-name-ideal-consumption">
                              <p>{i.name}</p>
                            </div>
                          </div>
                          <div>
                            <div
                              {...getRootProps({
                                className: "upload-file redirect-file",
                                onClick: (event) => handleRedirect(event, i.id),
                              })}
                            >
                              <input {...getInputProps()} multiple />
                              <div className="consumption-row">
                                <img src={PlusIcon} alt="" />
                                <h5>Add Consumption Product</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        {/* grid component starts here */}
                        <div className="ideal-consumption-grid-row">
                          {i?.product?.map((j, Jindex) => (
                            <Fragment key={Jindex}>
                              <div className="ideal-consumption-grid-col">
                                <img
                                  src={
                                    j.image !== null
                                      ? process.env.REACT_APP_IMG_URL +
                                        j?.image?.thumbnail
                                      : IMG_DEFAULT
                                  }
                                  alt=""
                                />
                                <div className="input-grid-row">
                                  <input
                                    type="text"
                                    value={j.qty}
                                    // onKeyDown={(e) =>
                                    //   handleEnterPress(
                                    //     e,
                                    //     i.id,
                                    //     j.product_id,
                                    //     index
                                    //   )
                                    // }
                                    onKeyPress={(e) =>
                                      handleEnterPress(
                                        e,
                                        i.id,
                                        j.product_id,
                                        index
                                      )
                                    }
                                    onChange={(e) =>
                                      handleQty(
                                        e.target.value,
                                        i.id,
                                        j.product_id,
                                        index
                                      )
                                    }
                                  />
                                  <Button
                                    onClick={() =>
                                      handleModal(i.id, j.product_id)
                                    }
                                  >
                                    <MoreVertIcon />
                                  </Button>
                                </div>
                              </div>
                            </Fragment>
                          ))}
                        </div>
                        {/* grid component ends here */}
                      </div>
                    </div>
                  ))}
              </div>
            ) : null}
          </div>
        )}
        {/* 70 30 grid col component ends here */}
        {parseInt(isTrue) === 1 ? (
          <div className="submit-cta-container">
            <Button>Submit</Button>
          </div>
        ) : null}
      </div>

      {/* Modal Component starts here */}
      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="remark-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>ADD PRODUCT IDEAL</h4>
          <div className="close-icon">
            <img
              alt=""
              src="/Images/closeBlack.svg"
              onClick={() => setShow(false)}
            />
          </div>
        </div>
        <AddProductIdeal
          mClose={setShow}
          request_id={product_group_id}
          product_ids={product_ids}
          product_sub_group_id={product_sub_group_id}
        />
      </DefaultModal>
      {/* Modal Component ends here */}
    </div>
  );
};

export default IdealConsumption;
