/* eslint-disable import/order */
import React from "react";
import PropTypes from "prop-types";
import styled, { useTheme } from "styled-components";
import { space, position, system, color, border, layout } from "styled-system";
import { getThemeColor } from "../../util/styledComponent";
import useHover from "../../customHooks/useHover";

import { ReactComponent as QuestionMark } from "./question-mark.svg";
import { ReactComponent as Search } from "./search.svg";
import { ReactComponent as CloseIcon } from "./close.svg";
import { ReactComponent as Theme } from "./theme.svg";
import { ReactComponent as FilledClose } from "./filledClose.svg";
import { ReactComponent as Dashboard } from "./dashboard.svg";
import { ReactComponent as SearchhBg } from "./searchhBg.svg";
import { ReactComponent as BlackSearch } from "./blackSearch.svg";
import { ReactComponent as Edit } from "./edit.svg";
import { ReactComponent as Collections } from "./collections.svg";
import { ReactComponent as Calendar } from "./calendar.svg";
import { ReactComponent as Workload } from "./workload.svg";
import { ReactComponent as MyTask } from "./myTask.svg";
import { ReactComponent as AllTask } from "./allTask.svg";
import { ReactComponent as SampleCategory } from "./sampleCategory.svg";
import { ReactComponent as SubmissionScreen } from "./submissionScreen.svg";
import { ReactComponent as Setting } from "./setting.svg";
import { ReactComponent as NodeWise } from "./nodeWise.svg";
import { ReactComponent as ProfileDelete } from "./profileDelete.svg";
import { ReactComponent as Chart } from "./chart.svg";
import { ReactComponent as Request } from "./request.svg";
import { ReactComponent as Tshirt } from "./tshirt.svg";
import { ReactComponent as Ranking } from "./ranking.svg";
import { ReactComponent as AddSquare } from "./addSquare.svg";
import { ReactComponent as Bubble } from "./bubble.svg";
import { ReactComponent as GarmentFit } from "./ruler.svg";
import { ReactComponent as CostRangeMaster } from "./moneys.svg";
import { ReactComponent as IdealConsumption } from "./hashtag.svg";

// Always keep this list alphabetically sorted
export const iconMap = {
  theme: Theme,
  close: CloseIcon,
  search: Search,
  filledClose: FilledClose,
  dashboard: Dashboard,
  searchhBg: SearchhBg,
  blackSearch: BlackSearch,
  edit: Edit,
  collections: Collections,
  calendar: Calendar,
  workload: Workload,
  myTask: MyTask,
  allTask: AllTask,
  sampleCategory: SampleCategory,
  submissionScreen: SubmissionScreen,
  setting: Setting,
  nodeWise: NodeWise,
  profileDelete: ProfileDelete,
  chart: Chart,
  request: Request,
  tshirt: Tshirt,
  ranking: Ranking,
  addSquare: AddSquare,
  bubble: Bubble,
  GarmentFit: GarmentFit,
  CostRangeMaster: CostRangeMaster,
  IdealConsumption: IdealConsumption,
};

const iconSizeMap = {
  xl: "76px",
  l: "74px",
  "2large": "60px",
  large: "42px",
  normal: "36px",
  small: "24px",
  xs: "19px",
  "2xs": "16px",
  tiny: "13px",
  "2tiny": "10px",
};

const StyledIcon = styled.div`
  height: ${(props) => iconSizeMap[props.iconSize]};
  width: ${(props) => iconSizeMap[props.iconSize]};
  cursor: ${(props) => (props.onClick ? "pointer" : null)};
  display: flex;

  & > svg {
    transform: ${(props) => props.svgTransform ?? "none"};
  }

  ${space}
  ${position}
  ${layout}
  ${color}
  ${border}

  ${system({
    transform: {
      property: "transform",
    },
    cursor: {
      property: "cursor",
    },
  })}
`;

// IconSize is predefined sizes

const IconWrapper = ({
  iconName,
  onClick,
  iconSize,
  noWrapper,
  stroke,
  hoverColor,
  ...rest
}) => {
  const theme = useTheme();

  const [ref, isIconHovered] = useHover();

  const IconSVG = iconMap[iconName] || QuestionMark;
  const iconProps = {};
  if (rest.color) {
    iconProps.fill = getThemeColor(
      theme,
      isIconHovered && hoverColor ? hoverColor : rest.color
    );
  }
  if (iconSize || rest.height) {
    iconProps.height = iconSizeMap[iconSize] || rest.height;
  }
  if (iconSize || rest.width) {
    iconProps.width = iconSizeMap[iconSize] || rest.width;
  }

  if (stroke) {
    iconProps.stroke = stroke;
  }

  if (rest.iconClass) {
    iconProps.className = rest.iconClass;
  }

  if (!noWrapper) {
    return (
      <>
        <StyledIcon {...rest} ref={ref} iconSize={iconSize} onClick={onClick}>
          <IconSVG {...iconProps} />
        </StyledIcon>
      </>
    );
  }
  return (
    <>
      <IconSVG {...rest} ref={ref} {...iconProps} onClick={onClick} />
    </>
  );
};

IconWrapper.propTypes = {
  onClick: PropTypes.func,
  iconName: PropTypes.string.isRequired,
  iconSize: PropTypes.oneOf([
    "xl",
    "2large",
    "large",
    "normal",
    "small",
    "xs",
    "2xs",
    "tiny",
    "2tiny",
    "l",
  ]),
  noWrapper: PropTypes.bool,
  stroke: PropTypes.string,
};

IconWrapper.defaultProps = {
  noWrapper: false,
};

export default IconWrapper;
