import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  // GetPreviewSkuActions,
  GetProductDataActions,
} from "../../store/user/actions";
import { Button } from "antd";
import DataTable from "../../component/DataTable/Table.component";
import Loader from "../../component/Loader";
import axios from "axios";
import { getToken } from "../../util/Common";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

const Header = ["Sr.No.", "Pictures", "Product Group", "Color", "SKU’s"];

const ProductData = () => {
  const navigate = useNavigate();
  const { id, form_type } = useParams();
  const dispatch = useDispatch();

  // Product Data State
  const ProductDataList = useSelector((state) => state.ProductDataList);

  useEffect(() => {
    dispatch(GetProductDataActions(id));
  }, [dispatch, id]);

  const [Product_Data, setProduct_Data] = useState([]);

  useEffect(() => {
    if (ProductDataList && ProductDataList?.isSuccess === true) {
      console.log("PRODUCT DATA LIST: ", ProductDataList);
      const transformed_arr = [
        {
          name: "Parent Category",
          value: ProductDataList?.data?.parent_category,
        },
        {
          name: "Product Group",
          value: ProductDataList?.data?.product_group,
        },
        {
          name: "Child Category",
          value: ProductDataList?.data?.child_category,
        },
        {
          name: "Title",
          value: ProductDataList?.data?.title,
        },
        {
          name: "Colors",
          value: ProductDataList?.data?.color,
        },
        {
          name: "Size",
          value: ProductDataList?.data?.size,
        },
      ];
      setProduct_Data(transformed_arr);
    }
  }, [ProductDataList]);

  const [Sku_data, setSku_data] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [isEnabled, setIsEnabled] = useState(true);

  const handlePreviewSKU = async () => {
    setIsLoading(true);
    await axios({
      method: "POST",
      url:
        process.env.REACT_APP_API_URL +
        `/sample_request/product_data_preview/${id}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        console.log("RESPONSE: ", response);
        if (response && response?.data?.status === 1) {
          // console.log("RESPONSE: ", response?.data?.data);
          setSku_data(response?.data?.data);
          if (response?.data?.data?.length > 0) {
            setIsEnabled(false);
          } else {
            setIsEnabled(true);
          }
          setIsLoading(false);
        } else if (response && response?.data?.status === 0) {
          toast.error(response && response?.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
      });
  };

  console.log("PRODUCT DATA LIST:", form_type);

  const [isSubmit_Loading, setisSubmit_Loading] = useState(false);

  const handleSubmitCreateSKU = async () => {
    setisSubmit_Loading(true);
    await axios({
      method: "POST",
      url:
        process.env.REACT_APP_API_URL +
        `/sample_request/product_data_submit/${id}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        console.log("RESPONSE: ", response);
        if (response && response?.data?.status === 1) {
          // setSku_data(response?.data?.data);
          toast.success(response && response?.data?.message);
          setisSubmit_Loading(false);
          navigate("/pending-task");
        } else if (response && response?.data?.status === 0) {
          toast.error(response && response?.message);
          setisSubmit_Loading(false);
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
        toast.error(error?.response?.data?.message);
        setisSubmit_Loading(false);
      });
  };

  return (
    <div className="create-fit-wrapper">
      <div className="d-flex align-items-baseline justify-content-between pb-2">
        <div className="heading heading-row-master-fit">
          <img
            onClick={() => navigate(-1)}
            src="/Images/arrow-left.svg"
            alt="Back_Icon"
          />
          <h2>Product Data</h2>
        </div>
        <div className="download-but-excel"></div>
      </div>

      {ProductDataList?.isLoading === true ? (
        <div className="loader_icons">
          <Loader />
        </div>
      ) : (
        <div className="Product-data-row">
          {Product_Data &&
            Product_Data?.map((i, index) => (
              <div className="product-data-grid-row" key={index}>
                <div>{i.name}</div>
                <div>:</div>
                <div>
                  {typeof i.value === "object"
                    ? JSON.stringify(i.value)
                    : i.value}
                </div>
              </div>
            ))}

          <div className="preview-sku-cta">
            {parseInt(form_type) === 2 ? (
              <>
                <Button onClick={handlePreviewSKU}>
                  {isLoading ? <Loader /> : "Preview SKU’s"}
                </Button>
                <Button
                  disabled={isEnabled}
                  style={{ width: "263px" }}
                  onClick={handleSubmitCreateSKU}
                >
                  {isSubmit_Loading ? <Loader /> : "submit and create SKU’s"}
                </Button>
              </>
            ) : parseInt(form_type) === 0 ? (
              <Button onClick={handlePreviewSKU}>
                {isLoading ? <Loader /> : "Preview SKU’s"}
              </Button>
            ) : parseInt(form_type) === 1 ? (
              <Button
                style={{ width: "263px" }}
                onClick={handleSubmitCreateSKU}
                disabled={isEnabled}
              >
                {isSubmit_Loading ? <Loader /> : "submit and create SKU’s"}
              </Button>
            ) : null}
          </div>
        </div>
      )}

      <div className="table-row-component">
        <h2>Preview SKU’s</h2>

        <div className="table-container-pagination mt-3">
          {isLoading ? (
            <div className="loader_icons">
              <Loader />
            </div>
          ) : (
            <DataTable header={Header} PreviewSKU={Sku_data} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductData;
