import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../../component/Table/Table";
import { Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getcollectionDelay } from "../../../store/user/actions";
import Loader from "../../../component/Loader";

const CollectionTable = (props) => {
  const [current, setCurrent] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [tableData, setTableData] = useState([]);
  const collectionDelay = useSelector((state) => state.collectionDelay);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getcollectionDelay());
  }, []);

  const onChange = (page) => {
    setCurrent(page);
    dispatch(getcollectionDelay({ ...props.selectBox, page }));
  };

  console.log("TOTAL DATA: ", collectionDelay?.data?.total);

  useEffect(() => {
    setTotalData(collectionDelay?.data?.total);
    let sno = (current - 1) * 10 + 1;
    const FakeTableData =
      collectionDelay &&
      collectionDelay.data.list.map((item, i) => {
        return [
          <>
            <h6>{i + 1} </h6>
          </>,
          <div>
            <h6>{item.name}</h6>
          </div>,
          <>
            <h6>{item.totalSample}</h6>
          </>,
          <>
            <h6>{item.instances.delayCount}</h6>
          </>,
          <div>
            <h6>{item.totalCompleteSample}</h6>
          </div>,
          <div className="sample-but">
            <Link to={"/gallery/" + item.id}>View Samples</Link>
          </div>,
        ];
      });
    setTableData(FakeTableData);
  }, [collectionDelay]);

  const TemplateTableData = [
    {
      heading: "Sr.No.",
    },
    {
      heading: "Collection Name",
    },
    {
      heading: "Total Samples in Collection",
    },
    {
      heading: "Total Delay Instances",
    },
    {
      heading: "Total Completed Samples",
    },
    {
      heading: "Actions",
    },
  ];

  console.log("COLLECTION TABLE DATA: ", collectionDelay.data?.list?.length);
  return (
    <div className="position-relative">
      {collectionDelay.isLoading && (
        <div className="loader_bg">
          <Loader />
        </div>
      )}

      <div className="table-bg">
        <Table
          gridTemplateColumnsHeader="10% 15% 20% 20% 20% 15%"
          gridTemplateColumnsBody="10% 15% 20% 20% 20% 15%"
          columns={TemplateTableData}
          data={tableData}
        />
        <Pagination current={current} onChange={onChange} total={totalData} />
      </div>
    </div>
  );
};

export default CollectionTable;
