import React, { useState } from "react";
import Box from "../../../component/Box";
import NodeTarget from "./NodeTarget";
import PredictionParameters from "./PredictionParameters";
import CategorySizeRatio from "./CategorySizeRatio";
import NodeAutoSubmission from "./NodeAutoSubmission";
import NodeSubmission from "./NodeSubmission";
import MainData from "./MainData";

const SettingTab = () => {
  const [selectedSegment, setselectedSegment] = useState("current");
  const setSegment = (segment) => {
    setselectedSegment(segment);
  };
  return (
    <>
      <h1 className="px-4">Settings</h1>
      <div className="pt-2 px-4">
        <Box
          display="flex"
          alignItems="center"
          cursor="pointer"
          justifyContent="left"
          className="tab-module-row-settings"
        >
          <Box fontSize="22px" fontWeight={700}>
            {selectedSegment === "current"}
          </Box>
          <ul className="d-flex tab_setting">
            <li
              className="current parameters_current"
              style={{
                fontWeight: selectedSegment === "current" ? "600" : "600",
                backgroundColor:
                  selectedSegment === "current" ? "#33DABD" : "#D9D9D9",
                color: selectedSegment === "current" ? "#1C1C1C" : "#1C1C1C",
                marginRight: selectedSegment === "current" ? "7px" : "7px",
                padding: selectedSegment === "current" ? "7px" : "7px",
                borderRadius:
                  selectedSegment === "current"
                    ? "5px 5px 0px 0px"
                    : "5px 5px 0px 0px",
              }}
              onClick={() => setSegment("current")}
            >
              Node Prediction form Parameters{" "}
            </li>
            <li
              className="screen-details"
              style={{
                fontWeight: selectedSegment === "upcomming" ? "600" : "600",
                backgroundColor:
                  selectedSegment === "upcomming" ? "#33DABD" : "#D9D9D9",
                color: selectedSegment === "upcomming" ? "#1C1C1C" : "#1C1C1C",
                borderRadius:
                  selectedSegment === "upcomming"
                    ? "5px 5px 0px 0px"
                    : "5px 5px 0px 0px",
              }}
              onClick={() => setSegment("upcomming")}
            >
              Node Target{" "}
            </li>
            <li
              className="screen-details"
              style={{
                fontWeight: selectedSegment === "category" ? "600" : "600",
                backgroundColor:
                  selectedSegment === "category" ? "#33DABD" : "#D9D9D9",
                color: selectedSegment === "category" ? "#1C1C1C" : "#1C1C1C",
                borderRadius:
                  selectedSegment === "category"
                    ? "5px 5px 0px 0px"
                    : "5px 5px 0px 0px",
              }}
              onClick={() => setSegment("category")}
            >
              Category Size Ratio
            </li>
            <li
              className="screen-details"
              style={{
                fontWeight:
                  selectedSegment === "node_auto_submission" ? "600" : "600",
                backgroundColor:
                  selectedSegment === "node_auto_submission"
                    ? "#33DABD"
                    : "#D9D9D9",
                color:
                  selectedSegment === "node_auto_submission"
                    ? "#1C1C1C"
                    : "#1C1C1C",
                borderRadius:
                  selectedSegment === "node_auto_submission"
                    ? "5px 5px 0px 0px"
                    : "5px 5px 0px 0px",
              }}
              onClick={() => setSegment("node_auto_submission")}
            >
              Node Auto Submission
            </li>
            <li
              className="screen-details"
              style={{
                fontWeight:
                  selectedSegment === "node_submission" ? "600" : "600",
                backgroundColor:
                  selectedSegment === "node_submission" ? "#33DABD" : "#D9D9D9",
                color:
                  selectedSegment === "node_submission" ? "#1C1C1C" : "#1C1C1C",
                borderRadius:
                  selectedSegment === "node_submission"
                    ? "5px 5px 0px 0px"
                    : "5px 5px 0px 0px",
              }}
              onClick={() => setSegment("node_submission")}
            >
              Node Submission
            </li>
            <li
              className="screen-details"
              style={{
                fontWeight: selectedSegment === "main_data" ? "600" : "600",
                backgroundColor:
                  selectedSegment === "main_data" ? "#33DABD" : "#D9D9D9",
                color: selectedSegment === "main_data" ? "#1C1C1C" : "#1C1C1C",
                borderRadius:
                  selectedSegment === "main_data"
                    ? "5px 5px 0px 0px"
                    : "5px 5px 0px 0px",
              }}
              onClick={() => setSegment("main_data")}
            >
              Main Data
            </li>
          </ul>
        </Box>
        <Box className="tab_body">
          {selectedSegment === "current" ? (
            <PredictionParameters
              setSegment={setSegment}
              tab={selectedSegment}
            />
          ) : null}
          {selectedSegment === "upcomming" ? (
            <NodeTarget setSegment={setSegment} tab={selectedSegment} />
          ) : null}
          {selectedSegment === "category" ? (
            <CategorySizeRatio setSegment={setSegment} tab={selectedSegment} />
          ) : null}
          {selectedSegment === "node_auto_submission" ? (
            <NodeAutoSubmission setSegment={setSegment} tab={selectedSegment} />
          ) : null}
          {selectedSegment === "node_submission" ? (
            <NodeSubmission setSegment={setSegment} tab={selectedSegment} />
          ) : null}
          {selectedSegment === "main_data" ? (
            <MainData setSegment={setSegment} tab={selectedSegment} />
          ) : null}
        </Box>
      </div>
    </>
  );
};

export default SettingTab;
