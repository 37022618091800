import React, { Fragment, useEffect, useState } from "react";
import "../../css/masterfit.css";
import { Button, Pagination } from "antd";
import DataTable from "../../component/DataTable/Table.component";
import { useDispatch, useSelector } from "react-redux";
import { getfilterData, getMasterFitDataShow } from "../../store/user/actions";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../component/Loader";
import { Helmet } from "react-helmet";
import { convertDropdown } from "../CreateFit/CreateFit";
import { Select } from "antd";

const Header = [
  "Sr.No.",
  "Fit Name",
  "Product Category",
  <>
    Fit Reference <br /> URL
  </>,
  "Reference Size",
  "Action",
];

const MasterFit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const filterData = useSelector((state) => state.filterData);

  const [cost_range_arr, setCostRange_arr] = useState([]);
  const [product_cat_arr, setProductCat_arr] = useState([]);
  const [reference_size_arr, setReferenceSize_arr] = useState([]);

  const MasterFitShow = useSelector((state) => state.MasterFitShow);

  // Filter Keys
  const [fit_name, setFitName] = useState("");
  const [categorie_id, setCategorie_id] = useState("");
  const [size_id, setSize_id] = useState("");
  const [reference_url, setReference_url] = useState("");
  // Filter Keys

  const handleFitName = (val) => {
    setFitName(val);
  };

  const handleCategorie = (val) => {
    setCategorie_id(val);
  };

  const handleReferenceUrl = (val) => {
    setReference_url(val);
  };

  const handleReferenceSize = (val) => {
    setSize_id(val);
  };

  // onchange Pagination Component starts here
  const [page, setPage] = useState(1);

  const onChange = (page) => {
    const params = { page: page };
    if (fit_name) {
      params.fit_name = fit_name;
    }
    if (categorie_id) {
      params.categorie_id = categorie_id;
    }
    if (size_id) {
      params.size_id = size_id;
    }
    if (reference_url) {
      params.reference_url = reference_url;
    }
    setPage(page);
    dispatch(getMasterFitDataShow(params));
  };
  // onChange Pagination Component ends here

  useEffect(() => {
    dispatch(getMasterFitDataShow({ page: 1 }));
    dispatch(getfilterData());
  }, [dispatch]);

  useEffect(() => {
    const params = { page: 1 };
    if (fit_name) {
      params.fit_name = fit_name;
    }
    if (categorie_id) {
      params.categorie_id = categorie_id;
    }
    if (size_id) {
      params.size_id = size_id;
    }
    if (reference_url) {
      params.reference_url = reference_url;
    }
    if (fit_name || categorie_id || size_id || reference_url) {
      dispatch(getMasterFitDataShow(params));
    }
  }, [fit_name, categorie_id, size_id, reference_url]);

  useEffect(() => {
    if (filterData && filterData?.isSuccess === true) {
      let costRange_arr = convertDropdown(filterData?.data?.cost_range);
      setCostRange_arr(costRange_arr);

      let productCat_arr = convertDropdown(
        filterData?.data?.product_categories
      );
      setProductCat_arr(productCat_arr);

      let Reference_SizeArr = convertDropdown(filterData?.data?.size);
      setReferenceSize_arr(Reference_SizeArr);
    }
  }, [filterData]);

  console.log("MASTER FIT SHOW: ", MasterFitShow?.data?.total);

  const handleClear = () => {
    setFitName("");
    setCategorie_id("");
    setSize_id("");
    setReference_url("");
    dispatch(getMasterFitDataShow({ page: 1 }));
  };

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Master Fit | Sample Dashboard</title>
      </Helmet>
      {/* Header Row starts here */}
      <div className="master-fitsc-row">
        <div>
          <h2>Master Fit</h2>
        </div>
        <div>
          <Button onClick={() => navigate("/master-fit")}>
            create master fit
          </Button>
        </div>
      </div>
      {/* Header Row ends here */}

      {/* Dropdown Component starts here */}
      <div className="dropdown-row-wrapper mt-3">
        <div className="row">
          <div className="col-md-3 col-lg-3 col-12 mb-2">
            {/* <label className="select-label-bold">Fit Name</label> */}
            <Select
              // defaultValue="Trial"
              placeholder="Fit Name"
              value={fit_name || "Fit Name"}
              onChange={(val) => handleFitName(val)}
              options={cost_range_arr}
              showSearch
              optionFilterProp="children"
              filterOption={(input, userOption) =>
                ((userOption?.label).toLowerCase() ?? "").includes(
                  input.toLowerCase()
                )
              }
              filterSort={(userOption) =>
                (userOption?.label ?? "")
                  .toLowerCase()
                  .localeCompare((userOption?.label ?? "").toLowerCase())
              }
            />
          </div>
          <div className="col-md-3 col-lg-3 col-12 mb-2">
            <Select
              // defaultValue="Sample Category"
              placeholder="Product Category"
              value={categorie_id || "Product Category"}
              onChange={(val) => handleCategorie(val)}
              options={product_cat_arr}
              showSearch
              optionFilterProp="children"
              filterOption={(input, userOption) =>
                ((userOption?.label).toLowerCase() ?? "").includes(
                  input.toLowerCase()
                )
              }
              filterSort={(userOption) =>
                (userOption?.label ?? "")
                  .toLowerCase()
                  .localeCompare((userOption?.label ?? "").toLowerCase())
              }
            />
          </div>
          <div className="col-md-3 col-lg-3 col-12 mb-2">
            <input
              type="text"
              placeholder="Fit Reference URL"
              className="input-custom"
              value={reference_url}
              onChange={(e) => handleReferenceUrl(e.target.value)}
            />
          </div>
          <div className="col-md-3 col-lg-3 col-12 mb-2">
            <Select
              // defaultValue="Sample Category"
              placeholder="Reference Size"
              onChange={(val) => handleReferenceSize(val)}
              value={size_id || "Reference Size"}
              options={reference_size_arr}
              showSearch
              optionFilterProp="children"
              filterOption={(input, userOption) =>
                ((userOption?.label).toLowerCase() ?? "").includes(
                  input.toLowerCase()
                )
              }
              filterSort={(userOption) =>
                (userOption?.label ?? "")
                  .toLowerCase()
                  .localeCompare((userOption?.label ?? "").toLowerCase())
              }
            />
          </div>
          <div className="col-md-3 col-lg-3 col-12 mb-2">
            <div className="clearDatas mb-3">
              <Link onClick={() => handleClear()} className="clear_data">
                Clear Data
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Dropdown Component ends here */}

      {/* Table Component starts here */}
      {MasterFitShow?.isLoading === true ? (
        <div className="loader_icons">
          <Loader />
        </div>
      ) : (
        <>
          <div className="table-wrapper-pagination mt-4">
            <div className="table-container-pagination">
              <DataTable
                header={Header}
                masterFitData={MasterFitShow?.data?.data || []}
                itemsPerPage={10}
                current={page}
              />
            </div>
            <div className="mt-3">
              <Pagination
                current={page}
                onChange={onChange}
                total={MasterFitShow?.data?.total}
              />
            </div>
          </div>
        </>
      )}
      {/* Table Component ends here */}
    </Fragment>
  );
};

export default MasterFit;
