import React, { useEffect, useState } from "react";
import {
  getfilterData,
  getsettingParameter,
} from "../../../store/user/actions";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
const RequestSelect = ({ filterValue }) => {
  const initialValues = {
    sample_category_id: "",
    sample_node_id: "",
    trail: "",
    parameter: [],
  };
  const [formValues, setFormValue] = useState(initialValues);
  const filterData = useSelector((state) => state.filterData.data);
  const settingParameter = useSelector((state) => state.settingParameter);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getfilterData(formValues));
    dispatch(getsettingParameter({}));
    filterValue(formValues);
    // eslint-disable-next-line
  }, [formValues]);

  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };

  const sampleCategoriesOption = Object.entries(
    filterData.sample_categories
  ).map(([value, label]) => ({
    value: parseInt(value, 10),
    label,
  }));
  const sampleTrailOption = Object.entries(filterData.sample_trail).map(
    ([value, label]) => ({
      value: value,
      label,
    })
  );
  const sampleNodesOption = Object.entries(filterData.sample_nodes).map(
    ([value, label]) => ({
      value: parseInt(value, 10),
      label,
    })
  );

  const [isActiveIndex, setIsActiveIndex] = useState();

  const handleSelectChange = (id, value, ix) => {
    setIsActiveIndex(ix);
    console.log("CURRENT INDEX: ", ix);
    // const data = { ...formValues };
    // const oldData = data.parameter;
    // console.log("OLDDATA: ", oldData);
    // const index = oldData.findIndex((obj) => obj.id === id);
    // if (index >= 0) {
    //   oldData[index] = { id: id, value: value };
    // } else {
    //   oldData[oldData.length] = { id: id, value: value };
    // }
    // data.parameter = oldData;
    // console.log("UPDATED DATA:", data);
    // setFormValue(data);
    setFormValue((prevState) => {
      const updatedParameter = [...prevState.parameter];
      const index = updatedParameter.findIndex((obj) => obj.id === id);

      if (index >= 0) {
        updatedParameter[index] = { id: id, value: value };
      } else {
        updatedParameter.push({ id: id, value: value });
      }

      return {
        ...prevState,
        parameter: updatedParameter,
      };
    });
  };

  console.log("FORMVALUES: ", formValues?.parameter?.[isActiveIndex]?.value);

  return (
    <div className="mt-4">
      {/* <RequestSelect/> */}
      <div className="row">
        <div className="col-md-3 col-lg-3 col-12 mb-2">
          <label className="select-label-bold">Trial</label>
          <Select
            defaultValue="Trial"
            value={formValues.trail}
            onChange={(e) => handleChange("trail", e)}
            options={sampleTrailOption}
            showSearch
            optionFilterProp="children"
            filterOption={(input, userOption) =>
              ((userOption?.label).toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(userOption) =>
              (userOption?.label ?? "")
                .toLowerCase()
                .localeCompare((userOption?.label ?? "").toLowerCase())
            }
          />
        </div>
        <div className="col-md-3 col-lg-3 col-12 mb-2">
          <label className="select-label-bold">Sample Category</label>
          <Select
            defaultValue="Sample Category"
            value={formValues.sample_category_id}
            onChange={(e) => handleChange("sample_category_id", e)}
            options={sampleCategoriesOption}
            showSearch
            optionFilterProp="children"
            filterOption={(input, userOption) =>
              ((userOption?.label).toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(userOption) =>
              (userOption?.label ?? "")
                .toLowerCase()
                .localeCompare((userOption?.label ?? "").toLowerCase())
            }
          />
        </div>
        <div className="col-md-3 col-lg-3 col-12 mb-2">
          <label className="select-label-bold">Node</label>
          <Select
            defaultValue="Next Node"
            value={formValues.sample_node_id}
            onChange={(e) => handleChange("sample_node_id", e)}
            options={sampleNodesOption}
            showSearch
            optionFilterProp="children"
            filterOption={(input, userOption) =>
              ((userOption?.label).toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(userOption) =>
              (userOption?.label ?? "")
                .toLowerCase()
                .localeCompare((userOption?.label ?? "").toLowerCase())
            }
          />
        </div>
        {settingParameter.data.map((item, index) => {
          const valuesArray = item.value.split(",");

          const transformedArray = valuesArray.map((value) => ({
            id: value,
            value: value,
          }));
          return (
            <div key={index} className="col-md-3 col-lg-3 col-12 mb-2">
              <label className="select-label-bold">{item.text_value}</label>
              <Select
                style={{ color: "white" }}
                // value={item.text_value}
                // defaultValue={item.text_value}
                value={
                  formValues.parameter.find((param) => param.id === item.id)
                    ?.value
                }
                // value={formValues?.parameter?.[index]?.value}
                onChange={(e) =>
                  handleSelectChange(item.id, e, index, transformedArray)
                }
                options={transformedArray}
                // showSearch
                optionFilterProp="children"
                filterOption={(input, userOption) =>
                  ((userOption?.label).toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                filterSort={(userOption) =>
                  (userOption?.label ?? "")
                    .toLowerCase()
                    .localeCompare((userOption?.label ?? "").toLowerCase())
                }
              />
            </div>
          );
        })}
        {/* Sample Category Node Parameter Relationship */}

        <div className="clearDatas mb-3">
          <Link
            onClick={() => setFormValue(initialValues)}
            className="clear_data"
          >
            Clear Data
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RequestSelect;
