import React, { useState } from "react";
import TargetSelect from "./component/TargetSelect";
import TargetReport from "./component/TargetReport";
import { Helmet } from "react-helmet";

const NodeTargets = () => {
  const [selectBox, setSelectBox] = useState({});
  return (
    <div className="target_sec">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Node Targets | Sample Dashboard</title>
      </Helmet>
      <div className="col-lg-7">
        {/* <div className='assing_flex'>
        <h2>Node Target Report</h2>
        <div className='assign_timer'>
          <h6>Assign Target : <span>06</span></h6>
          <h6> Achieve Target : 04</h6>
        </div>
        </div> */}
      </div>
      <TargetSelect selectBox={setSelectBox} />
      <TargetReport selectBox={selectBox} />
    </div>
  );
};

export default NodeTargets;
