import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Table from "../../../component/Table/Table";
import DefaultModal from "../../../component/DefaultModal/DefaultModal";
import SubmissionModal from "./SubmissionModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getpendingData,
  getprevReSubmission,
} from "../../../store/user/actions";
import { Pagination } from "antd";
import Loader from "../../../component/Loader";
import RemarksModal from "../../Dashboard/component/RemarksModal";
import Timer from "./Timer";
import { resetprevReSubmissionState } from "../../../store/user/userSlice";
import SelectManger from "./SelectManger";
import ViewPrevNodeModal from "./ViewPrevNodeModal";
// import RemarksModal from "./RemarksModal";

const CompleteTask = (props) => {
  const [showsubmission, setShowsubmission] = useState(false);
  // const [showRejection, setShowRejection] = useState(false);
  const [trainId, setTrainId] = useState(0);
  const [showViewNode, setViewNode] = useState(false);

  const [show, setShow] = useState(false);
  const [current, setCurrent] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [currentRequest, setCurrentRequest] = useState(0);
  const [timeData, setTimeData] = useState({});
  const [transtionId, setTranstionId] = useState(0);
  const pendingData = useSelector((state) => state.pendingData);
  const prevReSubmission = useSelector((state) => state.prevReSubmission);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getpendingData({ ...props.selectBox, tab: props.tab }));
  }, [props.selectBox]);

  const onChange = (page) => {
    setCurrent(page);
    dispatch(getpendingData({ ...props.selectBox, page, tab: props.tab }));
  };
  const preNodeSubmission = (id) => {
    dispatch(getprevReSubmission({ id }));
  };
  const refreshNodeData = () => {
    dispatch(getpendingData({ ...props.selectBox, tab: props.tab }));
  };
  useEffect(() => {
    if (prevReSubmission.isSuccess) {
      dispatch(resetprevReSubmissionState());
      refreshNodeData();
    }
  }, [prevReSubmission]);

  const handleTimeUpdate = (updatedTime) => {
    setTimeData(updatedTime);
  };
  useEffect(() => {
    setTotalData(pendingData.data.totalTask);
    let sno = (current - 1) * 10 + 1;
    const FakeTableData =
      pendingData &&
      pendingData.data.task.map((item, i) => {
        const isHighPriority = item.priority === "high";
        return [
          <>
            <h6 className={isHighPriority ? "high-priority" : ""}>
              {sno + i}{" "}
            </h6>
          </>,
          <div>
            <h6>{item.SampleId}</h6>
          </div>,
          <div className="pending-table-img">
            <img src={process.env.REACT_APP_IMG_URL + item.image} />
            <h6>{item.taskName}</h6>
          </div>,
          <>
            <h6>{item.collectionName}</h6>
          </>,
          <>
            <h6>{item.nextTask}</h6>
          </>,
          <>
            <h6>
              Original
              <br />
              {item.start_date.original}
            </h6>
            <h6>
              Current <br />
              {item.start_date.current}
            </h6>
            <h6>
              Actual <br />
              {item.start_date.actual}
            </h6>
          </>,
          <>
            <h6>
              Original
              <br /> {item.end_date.original}
            </h6>
            <br />
            <h6>
              Current
              <br /> {item.end_date.current}
            </h6>
            <br />
            <h6>
              {" "}
              Actual
              <br /> {item.end_date.actual}
            </h6>
          </>,
          <>
            <h6>{item.delay} Days</h6>
          </>,

          <>
            <img src="/Images/notification.svg" />
          </>,
          <div>
            <h6> {item.submit} </h6>
          </div>,
          // <div>

          //   <Timer
          // initialDays={item.timmer.d}
          // hours={parseInt(item.timmer.h)}
          // minutes={item.timmer.i}
          // seconds={item.timmer.s}
          // timerType={item.timmerType}
          // onTimeUpdate={handleTimeUpdate}
          //    />

          // </div>,

          <div class="bg-image-sm">
            <div class="team-pic">
              <div className="team-member">
                <img src="Images/setting.svg" />
                <span class="tooltiptext-sm">
                  <Link
                    onClick={() => {
                      setShow(true);
                      setCurrentRequest(item.sampleRequestId);
                    }}
                  >
                    Add Remarks
                  </Link>
                  <Link to={"/node-workload/" + item.id}>
                    Current node Workload
                  </Link>
                  <Link
                    onClick={() => {
                      setTrainId(item.id);
                      setViewNode(true);
                    }}
                  >
                    View Previous Node Submission
                  </Link>
                </span>
              </div>
            </div>
          </div>,
        ];
      });
    setTableData(FakeTableData);
  }, [pendingData.data]);

  const TemplateTableData = [
    {
      heading: "Sr.No.",
    },
    {
      heading: "Id",
    },
    {
      heading: "Tasks",
    },
    {
      heading: "Collections Name",
    },
    {
      heading: "Next Task",
    },
    {
      heading: "Start Dates",
    },
    {
      heading: "End Dates",
    },
    {
      heading: "Delay",
    },
    {
      heading: "Notify",
    },
    {
      heading: "Submission",
    },
    // {
    //   heading: "Remaining Time",
    // },
    {
      heading: "Actions",
    },
  ];
  return (
    <div className="position-relative">
      {pendingData.isLoading && (
        <div className="loader_bg">
          <Loader />
        </div>
      )}
      {prevReSubmission.isLoading && (
        <div className="loader-load">
          <Loader />
        </div>
      )}
      <div className="over-scroll-row">
        <Table
          // gridTemplateColumnsHeader="6% 15% 25% 20% 15% 25% 25% 15% 12% 12% 8% 8%"
          // gridTemplateColumnsBody="6% 15% 25% 20% 15% 25% 25% 15% 12% 12% 8% 8%"
          gridTemplateColumnsHeader="6% 10% 17% 8% 12% 18% 18% 7% 8% 14% 8% 8%"
          gridTemplateColumnsBody="6% 10% 17% 8% 12% 18% 18% 7% 8% 14% 8% 8%"
          columns={TemplateTableData}
          data={tableData}
          isActives={true}
        />
        <Pagination current={current} onChange={onChange} total={totalData} />
      </div>
      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="remark-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Add Remarks</h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setShow(false)} />
          </div>
        </div>
        <RemarksModal requestId={currentRequest} mClose={setShow} />
      </DefaultModal>
      <DefaultModal
        show={showViewNode}
        onHide={() => setViewNode(false)}
        className="view_node_modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Submission</h4>
          <div className="close-icon">
            <img
              src="/Images/closeBlack.svg"
              alt=""
              onClick={() => setViewNode(false)}
            />
          </div>
        </div>
        <div className="heading-border"></div>

        <ViewPrevNodeModal trainId={trainId} />
      </DefaultModal>
    </div>
  );
};

export default CompleteTask;
