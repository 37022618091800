import { Button, Select } from "antd";
import React, { useEffect, useState } from "react";
import { getfilterData } from "../../../store/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { convertDropdown } from "../../CreateFit/CreateFit";
import { toast } from "react-toastify";
import axios from "axios";
import { getToken } from "../../../util/Common";
import Loader from "../../../component/Loader";

const CreateCostRangeModal = ({ mClose }) => {
  const dispatch = useDispatch();

  //   Filter Data state
  const filterData = useSelector((state) => state.filterData);

  const [name, setName] = useState("");
  const [category_id, setCategory_id] = useState("");
  const [cost_range_form, setcost_range_form] = useState("");
  const [cost_range_to, setcost_range_to] = useState("");

  // Dropdown Data
  const [product_categories, setProductCategories] = useState([]);
  // Dropdown Data

  useEffect(() => {
    dispatch(getfilterData({}));
  }, [dispatch]);

  useEffect(() => {
    if (filterData && filterData?.isSuccess === true) {
      let product_categories_arr = convertDropdown(
        filterData?.data?.product_categories
      );
      setProductCategories(product_categories_arr);

      console.log("FILTER DATA IN MODAL: ", filterData);
    }
  }, [filterData]);

  const handleProductCategories = (val) => {
    console.log("PRODUCT CATEGORIES: ", val);
    setCategory_id(val);
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (!name) {
      toast.error("name is required");
      return;
    }
    if (!category_id) {
      toast.error("category_id is required");
      return;
    }
    if (!cost_range_form) {
      toast.error("cost_range_form is required");
      return;
    }
    if (!cost_range_to) {
      toast.error("cost_range_to is required");
      return;
    }

    if (parseInt(cost_range_form) > parseInt(cost_range_to)) {
      return toast.error(
        "The Cost Range To Value Must Be Less Than Equal To Cost Range From Value."
      );
    }

    setIsLoading(true);
    const token = await getToken();
    const headers = {
      Authorization: "Bearer " + token,
    };

    const formData = new FormData();
    formData.set("name", name);
    formData.set("category_id", category_id);
    formData.set("cost_range_form", cost_range_form);
    formData.set("cost_range_to", cost_range_to);

    axios
      .post(
        process.env.REACT_APP_API_URL + `/sample/cost-range/add`,
        formData,
        { headers }
      )
      .then((response) => {
        if (response && response?.data?.status === 1) {
          toast.success(response && response?.data?.message);
          mClose(false);
          setIsLoading(false);
        } else if (response && response?.data?.status === 0) {
          toast.error(response && response?.data?.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });

    // handleApiRequest(
    //   process.env.REACT_APP_API_URL + `/sample/cost-range/add`,
    //   formData
    // )
    //   .then((response) => {
    //     console.log("CREATE RESPONSE: ", response);
    //     if (response && response?.data?.status === 1) {
    //     } else if (response && response?.data?.status === 0) {
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };
  return (
    <div className="remark-select mt-1">
      <form>
        <div className="input-create-fit">
          <label htmlFor="" className="select-label-bold">
            Cost Range Name
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="" className="select-label-bold">
            Category
          </label>
          <Select
            style={{ height: "54px" }}
            onChange={(val) => handleProductCategories(val)}
            options={product_categories}
            showSearch
            optionFilterProp="children"
          />
        </div>
        <div className="input-create-fit">
          <label htmlFor="" className="select-label-bold">
            Cost Range From
          </label>
          <input
            type="number"
            value={cost_range_form}
            onChange={(e) => setcost_range_form(e.target.value)}
          />
        </div>
        <div className="input-create-fit">
          <label htmlFor="" className="select-label-bold">
            Cost Range To
          </label>
          <input
            type="number"
            value={cost_range_to}
            onChange={(e) => setcost_range_to(e.target.value)}
          />
        </div>
        <div className="fitType-add-cta">
          <Button disabled={isLoading} onClick={handleSubmit} type="submit">
            {isLoading ? <Loader /> : "SUBMIT"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CreateCostRangeModal;
