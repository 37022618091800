import { Button, Select } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "./CreateFit.css";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import {
  getSizeMasterFitActions,
  getfilterData,
} from "../../store/user/actions";
import DefaultModal from "../../component/DefaultModal/DefaultModal";
import FitTypeAdd from "../Dashboard/component/FitTypeAdd";
import { getToken } from "../../util/Common";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../component/Loader";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

// Convert Array of Object into Dropdown like Format
// export const convertDropdown = (data) => {
//   return Object.entries(data && data)?.map(([key, value]) => ({
//     label: value,
//     value: key,
//     id: key,
//   }));
// };

export const convertDropdown = (data) => {
  if (!data || typeof data !== "object") {
    return [];
  }
  return Object.entries(data).map(([key, value]) => ({
    label: value,
    value: key,
    id: key,
  }));
};

export const convertDropdown_Alternate = (data) => {
  return Object.entries(data && data)?.map(([key, value]) => ({
    label: value + " - (" + key + ")",
    value: key,
    id: key,
  }));
};

const CreateFit = () => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const filterData = useSelector((state) => state.filterData);
  const MasterSize = useSelector((state) => state.MasterSize);

  const [referenceUrl, setReferenceUrl] = useState([""]);
  const [index, setIndex] = useState([]);
  const [fit_name, setFitName] = useState("");
  const [product_category_id, setproduct_category_id] = useState("");
  const [fabric_kind, setfabric_kind] = useState("");
  const [size_id, setsize_id] = useState("");
  const [fit_type, setFitType] = useState("");

  const handleAdd = (i) => {
    setReferenceUrl([...referenceUrl, ""]);
    if (!index.includes(i)) {
      setIndex([...index, i]);
    }
  };

  const handleInputChange = (j, value) => {
    const updatedReferenceUrl = [...referenceUrl];
    updatedReferenceUrl[j] = value;
    setReferenceUrl(updatedReferenceUrl);
  };

  const handleRemoveClick = (i) => {
    const updatedReferenceUrl = [...referenceUrl];
    updatedReferenceUrl.splice(i, 1);
    setReferenceUrl(updatedReferenceUrl);

    const updateIndex = [...index];
    updateIndex.splice(i, 1);
    setIndex(updateIndex);
  };

  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    multiple: true,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          alt=""
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    setFiles([...selectedFiles]);
  };

  useEffect(() => {
    if (!show) {
      dispatch(getfilterData({}));
    }
  }, [dispatch, show]);

  const [master_fit, setMasterFit] = useState("");

  const [product_cat, setProductCat] = useState([]);
  const [fabric_kind_arr, setFabricKind_arr] = useState([]);
  const [size_arr, setSize_arr] = useState([]);
  const [measurement_unit_arr, setMeasurement_unitArr] = useState([]);
  const [fit_type_arr, setFit_Type_arr] = useState([]);
  const [Measurement_Data, setMeasurementData] = useState([]);
  const [MasterFit_arr, setMasterFit_arr] = useState([]);

  useEffect(() => {
    if (filterData && filterData?.isSuccess === true) {
      let product_categories_arr = convertDropdown(
        filterData?.data?.product_categories
      );
      setProductCat(product_categories_arr);

      let fabric_kind_arr = convertDropdown(filterData?.data?.fabric_kind);
      setFabricKind_arr(fabric_kind_arr);

      let size_arr = convertDropdown(filterData?.data?.size);
      setSize_arr(size_arr);

      let Measurement_arr = convertDropdown(
        filterData?.data?.measurement_units
      );
      setMeasurement_unitArr(Measurement_arr);

      let FitType_arr = convertDropdown(filterData?.data?.fit_type);
      const Add_Option = {
        label: "Click To Add",
        value: "Click To Add",
        id: "Click To Add",
      };
      setFit_Type_arr([Add_Option, ...FitType_arr]);
      console.log("FIT Type Arr: ", FitType_arr);

      let Measurement_Data_arr = convertDropdown(
        filterData?.data?.measurement_points
      );
      setMeasurementData(Measurement_Data_arr);

      let Master_Fit_arr = convertDropdown(filterData?.data?.master_fit);
      console.log("Master Fit: ", Master_Fit_arr);
      setMasterFit_arr(Master_Fit_arr);
    }
  }, [filterData]);

  const handleProductCategory = (val) => {
    setproduct_category_id(val);
  };

  const handleFabricKind = (val) => {
    setfabric_kind(val);
  };

  const handleMasterFit = (val) => {
    dispatch(getSizeMasterFitActions(val));
    setMasterFit(val);
  };

  const handleReferenceSize = (val) => {
    setsize_id(val);
  };

  const handleFitType = (val) => {
    if (val === "Click To Add") return setShow(!show);
    setFitType(val);
  };

  const [measurementRows, setMeasurementRows] = useState([
    {
      name: "",
      from: "",
      to: "",
      measurement_value: "",
      unit: "",
      grading: "",
      remark: "",
      measurement_variations: "",
      grading_variations: "",
      master_garment_fit_measurement_id: "",
    },
  ]);

  const handleInputChanges = (index, field, value) => {
    const newMeasurementRows = [...measurementRows];
    if (field === "grading") {
      if (
        newMeasurementRows[index].measurement_value &&
        value > newMeasurementRows[index].measurement_value
      ) {
        alert("Grading value must be less than or equal to Measurement value");
        return;
      }
    }
    newMeasurementRows[index][field] = value;
    setMeasurementRows(newMeasurementRows);
  };

  const handleSelectChange = (index, field, selectedOption) => {
    const newMeasurementRows = [...measurementRows];
    if (field === "from" || field === "to") {
      const otherField = field === "from" ? "to" : "from";
      if (newMeasurementRows[index][otherField] === selectedOption) {
        alert("From and To values cannot be the same");
        return;
      }
    }
    newMeasurementRows[index][field] = selectedOption;
    setMeasurementRows(newMeasurementRows);
  };

  useEffect(() => {
    if (MasterSize && MasterSize?.isSuccess === true) {
      const mappedMeasurementRows =
        MasterSize &&
        MasterSize?.data?.measurement?.map((measurement) => ({
          name: measurement.name || "",
          from: measurement.from_quality_manager_measurement_point_id || "",
          to: measurement.to_quality_manager_measurement_point_id || "",
          measurement_value: measurement.measurements_value || "",
          unit: measurement.measurement_unit_id || "",
          grading: measurement.grading || "",
          remark: measurement.remark || "",
          measurement_variations: "",
          grading_variations: "",
          master_garment_fit_measurement_id: measurement?.id || "",
        }));
      setMeasurementRows(mappedMeasurementRows);
    }
  }, [MasterSize]);

  const [isLoading, setIsLoading] = useState(false);

  console.log("MEASUREMENT ROWS: ", measurementRows, MasterSize?.data);

  const handleCreateFit = async () => {
    if (!fit_name) {
      toast.error("Fit Name is required");
      return;
    }
    if (!product_category_id) {
      toast.error("Product Category is required");
      return;
    }
    if (!fit_type) {
      toast.error("Fit Type is required");
      return;
    }
    if (!fabric_kind) {
      toast.error("Fabric Kind is required");
      return;
    }
    if (!master_fit) {
      toast.error("Master Fit is required");
      return;
    }
    if (!size_id) {
      toast.error("Reference Size is required");
      return;
    }
    if (files.length === 0) {
      toast.error("At least one file must be uploaded");
      return;
    }

    // Validation for Measurement Rows
    for (let i = 0; i < measurementRows.length; i++) {
      const row = measurementRows[i];
      if (
        !row.remark ||
        !row.measurement_variations ||
        !row.master_garment_fit_measurement_id ||
        !row.grading_variations
      ) {
        toast.error(`All fields are required in Measurement Row ${i + 1}`);
        return;
      }
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.set("fit_name", fit_name);
    formData.set("master_garment_fit_id", master_fit);
    formData.set("product_category_id", product_category_id);
    formData.set("fit_type_id", fit_type);
    formData.set("size_id", size_id);
    formData.set("fabric_kind", fabric_kind);
    formData.set("reference_url", referenceUrl.join(", "));
    formData.set("image_document", files[0]);
    measurementRows.forEach((i, index) => {
      formData.append(
        `measurements[${index}][measurements_value_variation]`,
        i.measurement_variations
      );
      formData.append(
        `measurements[${index}][master_garment_fit_measurement_id]`,
        i.master_garment_fit_measurement_id
      );
      formData.append(
        `measurements[${index}][grading_variation]`,
        i.grading_variations
      );

      // formData.append(`measurements[${index}][name]`, i.name);
      // formData.append(`measurements[${index}][point_form_id]`, i.from);
      // formData.append(`measurements[${index}][point_to_id]`, i.to);
      // formData.append(`measurements[${index}][measurement_unit_id]`, i.unit);
      // formData.append(
      //   `measurements[${index}][measurements_value]`,
      //   i.measurement_value
      // );
      // formData.append(`measurements[${index}][grading]`, i.grading);
      formData.append(`measurements[${index}][remark]`, i.remark);
    });

    await axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL + `/sample/garment_fit/add`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: formData,
    })
      .then((response) => {
        console.log(response?.data);
        if (response?.data?.status === 1) {
          toast.success("Data Saved Successfully");
          setIsLoading(false);
        }
        if (response?.data?.status === 0) {
          // console.log(
          //   "RESPONSE: ",
          //   response?.data?.data?.error?.reference_url[0]
          // );
          // toast.success("Ok");
          toast.error(response?.data?.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
      });
  };

  console.log("MEASUREMENT ROWS: ", measurementRows);

  return (
    <div className="create-fit-wrapper">
      <div className="d-flex align-items-baseline justify-content-between pb-2">
        <div className="heading">
          <h2>Create New Fit</h2>
        </div>
        <div className="download-but-excel">
          {/* <button>ADD MEASUREMENTS</button> */}
        </div>
      </div>

      {/* Form Component */}
      <div className="garment-create-fit-container">
        <div className="row">
          <div className="input-create-fit">
            <label htmlFor="" className="select-label-bold">
              Fit Name
            </label>
            <br />
            <input
              value={fit_name}
              onChange={(e) => setFitName(e.target.value)}
              type="text"
              name=""
              id=""
            />
          </div>
          <div>
            <label className="select-label-bold">Product Category</label>
            <Select
              placeholder="Fit Reference URL"
              value={product_category_id}
              onChange={(val) => handleProductCategory(val)}
              options={product_cat}
              showSearch
              loading={filterData?.isLoading}
              optionFilterProp="children"
            />
          </div>
          <div>
            <label className="select-label-bold">Fit type</label>
            <Select
              placeholder="Fit type"
              value={fit_type}
              onChange={(val) => handleFitType(val)}
              options={fit_type_arr}
              loading={filterData?.isLoading}
              showSearch
              optionFilterProp="children"
            />
          </div>
          <div>
            <label className="select-label-bold">
              Designed for Fabric Kind
            </label>
            <Select
              placeholder="Designed for Fabric Kind"
              value={fabric_kind}
              onChange={(val) => handleFabricKind(val)}
              options={fabric_kind_arr}
              showSearch
              loading={filterData?.isLoading}
              optionFilterProp="children"
            />
          </div>
          <div>
            <label className="select-label-bold">Master Fit</label>
            <Select
              placeholder="Master Fit"
              // value={fabric_kind}
              onChange={(val) => handleMasterFit(val)}
              options={MasterFit_arr}
              showSearch
              loading={filterData?.isLoading}
              optionFilterProp="children"
            />
          </div>
          {referenceUrl &&
            referenceUrl.map((i, j) => (
              <div>
                <div key={j} className="input-create-fit">
                  <div className="label-row">
                    <label htmlFor="" className="select-label-bold">
                      Fit Reference URL
                    </label>
                    <Button
                      style={{
                        backgroundColor: index.includes(j)
                          ? "rgb(255, 0, 0,0.5)"
                          : "#4549e847",
                      }}
                      onClick={() =>
                        index.includes(j) ? handleRemoveClick(j) : handleAdd(j)
                      }
                      className="plus-icon"
                    >
                      {index.includes(j) ? (
                        <MinusOutlined style={{ fontSize: "10px" }} />
                      ) : (
                        <PlusOutlined style={{ fontSize: "10px" }} />
                      )}
                    </Button>
                  </div>{" "}
                  <input
                    type="text"
                    onChange={(e) => handleInputChange(j, e.target.value)}
                    placeholder="Reference URL"
                    value={i}
                  />
                </div>
              </div>
            ))}
          {/* Sample Category Node Parameter Relationship */}
        </div>

        <div className="upload-pdf-row">
          <div className="col-md-3 col-lg-4 col-12 mb-2">
            <div {...getRootProps({ className: "upload-file" })}>
              <input
                {...getInputProps()}
                multiple
                onChange={handleFileChange}
              />
              {files.length === 0 ? (
                <div>
                  <img src="/Images/cloud.svg" alt="" />
                  <h5>Upload Image</h5>
                  <p>
                    Drag and Drop file here Or <span>Choose File</span>
                  </p>
                  <small>Support formats: JPG, PNG | Minimum size: 5 MB</small>
                </div>
              ) : (
                <></>
              )}
              <aside style={thumbsContainer}>{thumbs}</aside>
            </div>
          </div>
        </div>
      </div>

      {/* 2nd White row container starts here */}
      <div className="bg-white">
        <h3>Add Measurements Variations</h3>
        {/* grid row starts here */}

        <div className="row">
          <div className="col-md-3 col-lg-4 col-12 mb-2">
            <label className="select-label-bold">Reference Size</label>
            <Select
              placeholder="Reference Size"
              value={size_id}
              onChange={(val) => handleReferenceSize(val)}
              options={size_arr}
              loading={filterData?.isLoading}
              showSearch
              optionFilterProp="children"
            />
          </div>
        </div>

        <div className="add-measurement-grid-row create-fit-row-grid mt-4">
          <div>
            <label htmlFor="" className="select-label-bold">
              Measurement Name
            </label>{" "}
            <br />
          </div>
          <div>
            <label htmlFor="" className="select-label-bold">
              Measurement Point From
            </label>{" "}
            <br />
          </div>
          <div>
            <label htmlFor="" className="select-label-bold">
              Measurement Point To
            </label>{" "}
            <br />
          </div>
          <div>
            <label htmlFor="" className="select-label-bold">
              Measurement Length
            </label>{" "}
            <br />
          </div>
          <div>
            <label htmlFor="" className="select-label-bold">
              Measurement Variation in Inches
            </label>{" "}
            <br />
          </div>
          <div>
            <label htmlFor="" className="select-label-bold">
              Grading
            </label>{" "}
            <br />
          </div>
          <div>
            <label htmlFor="" className="select-label-bold">
              Grading Variation in Inches
            </label>{" "}
            <br />
          </div>
          <div>
            <label htmlFor="" className="select-label-bold">
              Remarks
            </label>{" "}
            <br />
          </div>
        </div>

        {measurementRows &&
          measurementRows?.map((measurementRow, index) => (
            <div
              className="add-measurement-grid-row measurement-col-row create-fit-row-col"
              key={index}
            >
              <div>
                {/* <Button onClick={() => handleRemoveRow(index)}>
                <MinusOutlined style={{ fontSize: "10px" }} />
              </Button> */}
              </div>
              <div className="input-create-fit">
                <input
                  disabled={true}
                  type="text"
                  value={measurementRow.name}
                  onChange={(e) =>
                    handleInputChanges(index, "name", e.target.value)
                  }
                />
              </div>
              <div>
                <Select
                  disabled={true}
                  placeholder="Measurement Point From"
                  value={measurementRow.from}
                  onChange={(selectedOption) =>
                    handleSelectChange(index, "from", selectedOption)
                  }
                  options={Measurement_Data}
                  showSearch
                  optionFilterProp="children"
                />
              </div>
              <div>
                <Select
                  disabled={true}
                  placeholder="Measurement Point To"
                  value={measurementRow?.to}
                  onChange={(selectedOption) =>
                    handleSelectChange(index, "to", selectedOption)
                  }
                  options={Measurement_Data}
                  showSearch
                  optionFilterProp="children"
                />
              </div>
              <div>
                <div className="input-create-fit d-flexY">
                  <input
                    disabled={true}
                    type="number"
                    value={measurementRow.measurement_value}
                    onChange={(e) =>
                      handleInputChanges(
                        index,
                        "measurement_value",
                        e.target.value
                      )
                    }
                  />
                  <Select
                    // className="w-100"
                    disabled={true}
                    options={measurement_unit_arr}
                    value={measurementRow.unit}
                    onChange={(selectedOption) =>
                      handleSelectChange(index, "unit", selectedOption)
                    }
                  />
                </div>
              </div>
              <div className="input-create-fit">
                <input
                  type="number"
                  min={0}
                  // max={row.measurement_value}
                  // value={measurementRow.unit}
                  onChange={(e) =>
                    handleInputChanges(
                      index,
                      "measurement_variations",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="input-create-fit">
                <input
                  disabled={true}
                  type="text"
                  value={measurementRow.grading}
                  onChange={(e) =>
                    handleInputChanges(index, "grading", e.target.value)
                  }
                />
              </div>
              <div className="input-create-fit">
                <input
                  type="number"
                  min={0}
                  // max={row.measurement_value}
                  // value={measurementRow.unit}
                  onChange={(e) =>
                    handleInputChanges(
                      index,
                      "grading_variations",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="input-create-fit">
                <input
                  type="text"
                  value={measurementRow.remark}
                  onChange={(e) =>
                    handleInputChanges(index, "remark", e.target.value)
                  }
                />
              </div>
            </div>
          ))}

        <div className="create-fit-row-cta">
          <div>
            {/* <Button onClick={addMeasurementRow}>Add</Button> */}
            <Button onClick={handleCreateFit}>
              {isLoading ? <Loader /> : "submit"}
            </Button>
          </div>
        </div>

        {/* grid row ends here */}
      </div>
      {/* 2nd White row container ends here */}

      {/* Form Component */}

      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="remark-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Fit Type</h4>
          <div className="close-icon">
            <img
              src="/Images/closeBlack.svg"
              alt=""
              onClick={() => setShow(false)}
            />
          </div>
        </div>
        <FitTypeAdd mClose={setShow} />
      </DefaultModal>
    </div>
  );
};

export default CreateFit;
