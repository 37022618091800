import React, { useState } from "react";
import RequestSelect from "./component/RequestSelect";
import RequestTable from "./component/RequestTable";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const GarmentsFit = () => {
  const navigate = useNavigate();
  const [selectBox, setSelectBox] = useState({});

  const [fit_name, setFit_Name] = useState("");
  const [categorie_id, setCategorie_id] = useState("");
  const [fit_type_id, setFit_type_id] = useState("");
  const [created_by, setCreated_by] = useState("");
  const [size_id, setSize_id] = useState("");
  const [fabric_kind, setFabric_kind] = useState("");
  const [reference_url, setReference_Url] = useState("");

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Garment Fit | Sample Dashboard</title>
      </Helmet>
      <div className="d-flex align-items-baseline justify-content-between pb-2">
        <div className="heading">
          <h2>Garments Fit</h2>
        </div>
        <div className="download-but-excel">
          <button onClick={() => navigate("/master-fit")}>
            {/* <img src="Images/document-download.svg" /> */}
            Create Master Fit
          </button>
          <button onClick={() => navigate("/create-new-fit")}>
            {/* <img src="Images/document-download.svg" /> */}
            Create New Fit
          </button>
        </div>
      </div>
      <RequestSelect
        filterValue={setSelectBox}
        setFit_Name={setFit_Name}
        setCategorie_id={setCategorie_id}
        setFit_type_id={setFit_type_id}
        setCreated_by={setCreated_by}
        setSize_id={setSize_id}
        setfabric_kind={setFabric_kind}
        setReference_Url={setReference_Url}
        fit_name={fit_name}
        categorie_id={categorie_id}
        fit_type_id={fit_type_id}
        created_bys={created_by}
        type_id={size_id}
        fabric_kinds={fabric_kind}
        reference_url={reference_url}
      />
      <RequestTable
        selectBox={selectBox}
        fit_name={fit_name}
        categorie_id={categorie_id}
        fit_type_id={fit_type_id}
        created_by={created_by}
        type_id={size_id}
        fabric_kind={fabric_kind}
        reference_url={reference_url}
      />
    </div>
  );
};

export default GarmentsFit;
