import React, { useState } from "react";
import DashboardSelect from "../Dashboard/component/DashboardSelect";
import { Link } from "react-router-dom";
// import PendingTable from "./components/PendingTable";
import { Helmet } from "react-helmet";
import { getUser } from "../../util/Common";
import PendinTab from "./components/PendinTab";

const MyPendingTask = () => {
  const [selectBox, setSelectBox] = useState(true);

  const filterValue = (selectValue) => {
    setSelectBox(selectValue);
  };
  const downloadExcel = async () => {
    let excel = process.env.REACT_APP_WEB_URL + "panding-task?";
    const user = await getUser();
    selectBox.userId = user.id;
    for (const key in selectBox) {
      if (selectBox.hasOwnProperty(key)) {
        excel += `${key}=${selectBox[key]}&`;
      }
    }
    window.open(excel, "_blank");
  };

  console.log("FILTER VALUE: ", selectBox);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MyPendingTask | Sample Dashboard</title>
      </Helmet>
      <div className="d-flex align-items-baseline justify-content-between pb-2">
        <div className="heading">
          <h2>My Pending Task</h2>
        </div>
        <div className="download-but">
          <button onClick={() => downloadExcel()}>
            <img src="Images/document-download.svg" alt="" />
            Download Excel
          </button>
          <div className="view-but">
            <Link to="/workload">view workload</Link>
          </div>
        </div>
      </div>
      <DashboardSelect hiddenInput={{ user: true }} filterValue={filterValue} />
      {/* <PendingTable
        
      /> */}
      <PendinTab selectBox={selectBox} />
    </div>
  );
};

export default MyPendingTask;
