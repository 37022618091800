import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { getnodeWiseCompletions } from "../../../store/user/actions";
import { toast } from "react-toastify";

const NodeComplete = () => {
  const nodeWiseCompletions = useSelector((state) => state.nodeWiseCompletions);
  const dispatch = useDispatch();
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Days Delays",
        data: nodeWiseCompletions.data.map((item) => item.delay),
      },
      {
        name: "Pending Samples",
        data: nodeWiseCompletions.data.map((item) => item.node),
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "32%",
          borderRadius: 7,
          borderRadiusApplication: "end",
          endingShape: "",
        },
      },
      colors: ["#FFBA32", "#2ED47E"],

      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: "Pending samples",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  });
  const optionsSelect = [
    {
      value: "Today",
      lable: "Today",
    },
    {
      value: "This Week",
      lable: "This Week",
    },
    {
      value: "Last Week",
      lable: "Last Week",
    },
    {
      value: "This Month",
      lable: "This Month",
    },
    {
      value: "Last Month",
      lable: "Last Month",
    },
    {
      value: "Last 3 Month",
      lable: "Last 3 Month",
    },
  ];

  const [IsLarge, setIsLarge] = useState("Today");

  const persionValue = (value) => {
    // toast.success(value);
    setIsLarge(value);
    dispatch(getnodeWiseCompletions({ period: value }));
  };
  useEffect(() => {
    dispatch(getnodeWiseCompletions({}));
  }, []);
  useEffect(() => {
    setChartData((prevData) => ({
      ...prevData,
      series: [
        {
          name: "Days Delays",
          data: nodeWiseCompletions.data.map((item) => item.delay),
        },
        {
          name: "Pending Samples",
          data: nodeWiseCompletions.data.map((item) => item.node),
        },
      ],
      options: {
        ...prevData.options,
        xaxis: {
          categories: nodeWiseCompletions.data.map((item) => item.name),
        },
      },
    }));
  }, [nodeWiseCompletions]);

  console.log("ISLARGE: ", IsLarge);
  return (
    <div className="node_wise_graph">
      <div className="wordload-card">
        <h6>Statistics</h6>
        <div className="heading_select">
          <h2>Node Wise Completions</h2>
          <div className="custom-select">
            <select
              className="selectArea"
              onChange={(e) => persionValue(e.target.value)}
            >
              {optionsSelect &&
                optionsSelect.map((item) => (
                  <option value={item.value}>{item.lable}</option>
                ))}
            </select>
          </div>
        </div>
        <div className="border-wordload"></div>

        <div className="chart-line-bar-container">
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            height={350}
            width={
              IsLarge === "Today"
                ? 965
                : IsLarge === "This Week"
                ? 2500
                : IsLarge === "Last Week"
                ? 2000
                : 4500
            }
          />
        </div>
      </div>
    </div>
  );
};

export default NodeComplete;
