import { configureStore } from "@reduxjs/toolkit";
import {
  RemarkSlice,
  allpendingSlice,
  cardSlice,
  collectionDelaySlice,
  collectionGraphSlice,
  filterSlice,
  loaderSlice,
  pendingSlice,
  sampleNodeSlice,
  tableSlice,
  workloadSlice,
  workloadNodeSlice,
  historySlice,
  inactiveNodeSlice,
  categorySlice,
  trailSlice,
  createTrailSlice,
  addNodeSlice,
  responsibleSlice,
  sampleNodeFlowSlice,
  submissionSlice,
  assignNodeSlice,
  nodeFormSlice,
  prevReSubmissionSlice,
  monthPendingSlice,
  calendarSlice,
  activeNodeSlice,
  nodeDateChangeSlice,
  // nodeSubmitSlice,
  statusSampleNodeSlice,
  sampleNodeEdgeTypeSlice,
  sampleNodeEdgeConnectSlice,
  persionWiseDataSlice,
  nodeWiseCompletionsDataSlice,
  collectionWiseCompletionSlice,
  userlistWithWorkloadSlice,
  submissionDataDetailsSlice,
  nodeSubmitDataSlice,
  requestListSlice,
  userListByDesignationSlice,
  userWorkLoadDataSlice,
  nodeUserUpdateBulkSlice,
  newCalendarSlice,
  nodeCriticalityUpdateBulkSlice,
  sampleLogSlice,
  rejectionListSlice,
  preNodeSubmissionSlice,
  criticalityListSlice,
  currentNodeSubmissionSlice,
  resubmissionRequiestStatusSlice,
  addTranNodeSlice,
  sampleTranNodeTodoConnectSlice,
  nodeBufferTimeChangeSlice,
  nodeLeadTimeChangeSlice,
  nodeStatusChangeSlice,
  requestStatusHoldSlice,
  calenderNewDetailSlice,
  nodeGroupSlice,
  importSubmissionSlice,
  deadlineSlice,
  sampleRequestAddSlice,
  sampleRequestPreviewSlice,
  sampleCategoryListSlice,
  settingParameterSlice,
  sampleRequestCreateSlice,
  sampleCategoryParameterAddSlice,
  settingNodeTargetSlice,
  nodeTargetSlice,
  settingParameterAddSlice,
  settingParameterDeleteSlice,
  settingNodeTargetListSlice,
  sampleRequestCheckSlice,
  getGarmentFitListSlice,
  getCostRangeMasterListSlice,
  createCategorySizeRatioSlice,
  nodeAutoSubmissionSlice,
  getnodeAutoSubmissionSlice,
  SendDatesRelationshipDataSlice,
  getCategorySizeDataSlice,
  getSizeMasterFitSlice,
  ImageColorAdditionSlice,
  TriggerFunctionMainDataAddSlice,
  TriggerFunctionMainDataListSlice,
  getImageSelectionDataSlice,
  getProductDataSlice,
  GetPreviewSKUSlice,
  GetBatchLevelDataSlice,
  ProductDataSubmitSlice,
  IdealConsumptionListSlice,
  IdealConsumptionAddProductSlice,
  costRangeWiseSampleSlice,
  ImageRequestViewSlice,
  GetGarmentFitShowSlice,
  getSampleRequestSlice,
  getMasterFitDataShowSlice,
  SampleRequestUpdateSlice,
  getMasterFitGarmentUpdateSlice,
  getNewLaunchesReportSlice,
  AddSampleCollectionSlice,
} from "../store/user/userSlice";

export const store = configureStore({
  reducer: {
    // user: userReducer,
    loader: loaderSlice,
    dashboardCard: cardSlice,
    collectionData: tableSlice,
    filterData: filterSlice,
    pendingData: pendingSlice,
    allpendingData: allpendingSlice,
    workloadData: workloadSlice,
    workloadNode: workloadNodeSlice,
    collectionDelay: collectionDelaySlice,
    collectionGraph: collectionGraphSlice,
    sampleNodeData: sampleNodeSlice,
    remarkData: RemarkSlice,
    historyData: historySlice,
    inactiveNode: inactiveNodeSlice,
    categoryNode: categorySlice,
    trailData: trailSlice,
    createTrailData: createTrailSlice,
    addNode: addNodeSlice,
    responsiblePerson: responsibleSlice,
    submissionData: submissionSlice,
    sampleNodeFlowData: sampleNodeFlowSlice,
    assignNodeData: assignNodeSlice,
    nodeFormData: nodeFormSlice,
    prevReSubmission: prevReSubmissionSlice,
    monthPending: monthPendingSlice,
    calendarData: calendarSlice,
    activeNode: activeNodeSlice,
    nodeDateChange: nodeDateChangeSlice,
    nodeSubmitData: nodeSubmitDataSlice,
    statusSampleNode: statusSampleNodeSlice,
    sampleNodeEdgeType: sampleNodeEdgeTypeSlice,
    sampleNodeEdgeConnect: sampleNodeEdgeConnectSlice,
    persionWiseData: persionWiseDataSlice,
    nodeWiseCompletions: nodeWiseCompletionsDataSlice,
    collectionWiseCompletion: collectionWiseCompletionSlice,
    userlistWithWorkload: userlistWithWorkloadSlice,
    submissionDataDetails: submissionDataDetailsSlice,
    requestList: requestListSlice,
    userListByDesignation: userListByDesignationSlice,
    userWorkLoadData: userWorkLoadDataSlice,
    nodeUserUpdateBulk: nodeUserUpdateBulkSlice,
    newCalendar: newCalendarSlice,
    nodeCriticalityUpdateBulk: nodeCriticalityUpdateBulkSlice,
    sampleLog: sampleLogSlice,
    rejectionList: rejectionListSlice,
    preNodeSubmission: preNodeSubmissionSlice,
    criticalityList: criticalityListSlice,
    currentNodeSubmission: currentNodeSubmissionSlice,
    resubmissionRequiestStatus: resubmissionRequiestStatusSlice,
    addTranNode: addTranNodeSlice,
    sampleTranNodeTodoConnect: sampleTranNodeTodoConnectSlice,
    nodeBufferTimeChange: nodeBufferTimeChangeSlice,
    nodeLeadTimeChange: nodeLeadTimeChangeSlice,
    nodeStatusChange: nodeStatusChangeSlice,
    requestStatusHold: requestStatusHoldSlice,
    calenderNewDetail: calenderNewDetailSlice,
    nodeGroup: nodeGroupSlice,
    importSubmission: importSubmissionSlice,
    deadline: deadlineSlice,
    sampleRequestAdd: sampleRequestAddSlice,
    sampleRequestPreview: sampleRequestPreviewSlice,
    sampleCategoryList: sampleCategoryListSlice,
    settingParameter: settingParameterSlice,
    sampleRequestCreate: sampleRequestCreateSlice,
    sampleCategoryParameterAdd: sampleCategoryParameterAddSlice,
    settingNodeTarget: settingNodeTargetSlice,
    nodeTarget: nodeTargetSlice,
    settingParameterAdd: settingParameterAddSlice,
    settingParameterDelete: settingParameterDeleteSlice,
    settingNodeTargetList: settingNodeTargetListSlice,
    sampleRequestCheck: sampleRequestCheckSlice,
    garmentFitList: getGarmentFitListSlice,
    costRangeList: getCostRangeMasterListSlice,
    createCategory: createCategorySizeRatioSlice,
    nodeAuto: nodeAutoSubmissionSlice,
    nodeAutoList: getnodeAutoSubmissionSlice,
    AddDatesRelationshipData: SendDatesRelationshipDataSlice,
    getCategorySizeData: getCategorySizeDataSlice,
    MasterSize: getSizeMasterFitSlice,
    ImageColorAddition: ImageColorAdditionSlice,
    MainDataAdd: TriggerFunctionMainDataAddSlice,
    MainDataList: TriggerFunctionMainDataListSlice,
    ImageSelectionData: getImageSelectionDataSlice,
    ProductDataList: getProductDataSlice,
    previewSKU: GetPreviewSKUSlice,
    BatchLevelData: GetBatchLevelDataSlice,
    ProductDataSubmit: ProductDataSubmitSlice,
    IdealConsumptionList: IdealConsumptionListSlice,
    IdealConsumptionAddProduct: IdealConsumptionAddProductSlice,
    costRangeWiseSample: costRangeWiseSampleSlice,
    ImagerequestView: ImageRequestViewSlice,
    garmentFitShow: GetGarmentFitShowSlice,
    sampleRequestShow: getSampleRequestSlice,
    MasterFitShow: getMasterFitDataShowSlice,
    SampleReqUpdate: SampleRequestUpdateSlice,
    masterGarmentFitUpdateGet: getMasterFitGarmentUpdateSlice,
    newLaunchesConsolidated: getNewLaunchesReportSlice,
    addsamplecollect: AddSampleCollectionSlice,
  },
});
