import React, { useState } from "react";

import DashboardSelect from "./component/DashboardSelect";
import DashboardTable from "./component/DashboardTable";
import { Helmet } from "react-helmet";

const Dashboard = () => {
  // const [show, setShow] = useState(true);
  // const [isShow, setIsShow] = useState(true);
  const [selectBox, setSelectBox] = useState(true);

  // const Hide = (workload) => {
  //   setShow(workload)
  // }
  // const Snode = (sample) => {
  //   setIsShow(sample)
  // }
  const filterValue = (selectValue) => {
    setSelectBox(selectValue);
  };
  return (
    <div className="dashboard-bg">
      <Helmet>
        <meta charSet="utf-8" />
        <title>NodeWiseTask | Sample Dashboard</title>
      </Helmet>

      <div>
        <h1>Node Wise Task</h1>
        <DashboardSelect filterValue={filterValue} />
        <DashboardTable
          selectBox={selectBox}
          // Hide={Hide}
          // Snode={Snode}
        />
      </div>

      {/* {!show && <Workload Hide={Hide} />}
      {!isShow && <SampleNode Snode={Snode} />} */}
    </div>
  );
};

export default Dashboard;
