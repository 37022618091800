import React, { useState, useEffect } from "react";
import SideBarLinks from "../constants/SideBarLinksAdmin.json";
import { Link, NavLink, useLocation } from "react-router-dom";
import Icon from "./Icon/Icon";

const LeftSideBar = () => {
  const location = useLocation();
  const [linkRefs, setlinRefs] = useState(SideBarLinks);

  const active = (i) => {
    let newLinkRefs = SideBarLinks.map((link) => {
      link.active = false;
      return link;
    });
    SideBarLinks[i].active = true;
    setlinRefs(newLinkRefs);
  };

  useEffect(() => {
    const link = location?.pathname;
    const index = SideBarLinks.findIndex((item) => item.link === link);
    if (location?.pathname === `/`) {
      active(0);
    }
    if (index >= 0) {
      active(index);
    }
  }, []);

  return (
    <div className="sidebar_bg">
      <div className="sidbaar-scroll">
        <div className="logo-im-sidebbar">
          <Link to="/">
            {" "}
            <img src="/Images/sidebaar-logo.svg" />
          </Link>
        </div>
        <div className="left-side-scroll">
          {SideBarLinks &&
            SideBarLinks.map((item, i) => (
              <>
                <div className="li-content">
                  <NavLink
                    to={item.link}
                    onClick={() => active(i)}
                    className={item.active ? "activeClass" : null}
                  >
                    <Icon
                      iconName={item.icon}
                      width="30px"
                      height="30px"
                      color={item.active ? "#fff" : "#D0004B"}
                    ></Icon>
                    <h5>{item.name}</h5>
                  </NavLink>
                </div>
                <div className="border_bootom"></div>
              </>
            ))}
        </div>
      </div>
    </div>
  );
};

export default LeftSideBar;
