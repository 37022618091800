import React, { useState, useEffect } from "react";

const Timer = ({
  initialDays,
  hours,
  minutes,
  seconds,
  onTimeUpdate,
  timerType,
  hold,
}) => {
  const [time, setTime] = useState({
    days: initialDays,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  });

  useEffect(() => {
    const totalHours = initialDays * 24 + hours;
    if (hold == 0) {
      const intervalId = setInterval(() => {
        setTime((prevTime) => {
          let { days, hours, minutes, seconds } = prevTime;

          if (timerType == "erly") {
            if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
              clearInterval(intervalId);
            } else if (hours === 0 && minutes === 0 && seconds === 0) {
              days -= 1;
              hours = 23;
              minutes = 59;
              seconds = 59;
            } else if (minutes === 0 && seconds === 0) {
              hours -= 1;
              minutes = 59;
              seconds = 59;
            } else if (seconds === 0) {
              minutes -= 1;
              seconds = 59;
            } else {
              seconds -= 1;
            }

            onTimeUpdate({ days, hours, minutes, seconds });
          } else {
            if (hours === 23 && minutes === 59 && seconds === 59) {
              days += 1;
              hours = 0;
              minutes = 0;
              seconds = 0;
            } else if (minutes === 59 && seconds === 59) {
              hours += 1;
              minutes = 0;
              seconds = 0;
            } else if (seconds === 59) {
              minutes += 1;
              seconds = 0;
            } else {
              seconds += 1;
            }

            onTimeUpdate({ days, hours, minutes, seconds });
          }

          return { days, hours, minutes, seconds };
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [initialDays, onTimeUpdate]);

  useEffect(() => {
    setTime({
      days: initialDays,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    });
  }, [initialDays, hours, minutes, seconds]);
  const formatTime = (num) => (num < 10 ? `0${num}` : num);

  console.log("TIMER TYPE: ", timerType);

  return (
    <div>
      <h6 style={timerType == "erly" ? { color: "green" } : { color: "red" }}>
        {time.days > 0 && `${time.days}d `}
        {formatTime(time.hours)}:{formatTime(time.minutes)}:
        {formatTime(time.seconds)}
      </h6>
    </div>
  );
};

export default Timer;
