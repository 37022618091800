import React, { useState, useEffect, useRef } from "react";

const AudioPlayer = ({ audioSrc, disabled }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isValidAudio, setIsValidAudio] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;
    const handleEnded = () => {
      setIsPlaying(false);
    };

    const checkAudioSrc = () => {
      const audioExtensions = ["mp3", "wav", "ogg", "aac"];
      const extension = audioSrc.split(".").pop().toLowerCase();
      setIsValidAudio(audioExtensions.includes(extension));
    };

    audio.addEventListener("ended", handleEnded);

    checkAudioSrc();
    // Cleanup event listener on component unmount
    return () => {
      audio.removeEventListener("ended", handleEnded);
    };
  }, [audioSrc]);

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div>
      <audio ref={audioRef} src={audioSrc} />
      <button
        className="audio-icon-play"
        disabled={audioSrc === "" || !isValidAudio ? true : false}
        onClick={handlePlayPause}
      >
        {isPlaying ? (
          <div
            style={{ backgroundColor: audioSrc === "" ? "#c7c7c7" : "" }}
            className={`play_but ${disabled ? "disabled-audio" : ""}`}
          >
            <i className="fa fa-pause" aria-hidden="true"></i>
          </div>
        ) : (
          <div
            style={{ backgroundColor: audioSrc === "" ? "#c7c7c7" : "" }}
            className={`play_but ${disabled ? "disabled-audio" : ""}`}
          >
            <i className="fa fa-play" aria-hidden="true"></i>
          </div>
        )}
      </button>
    </div>
  );
};

export default AudioPlayer;
