import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../../component/Table/Table";
import DefaultModal from "../../../component/DefaultModal/DefaultModal";
import SubmissionModal from "./SubmissionModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getpendingData,
  // getprevReSubmission,
} from "../../../store/user/actions";
import { Pagination } from "antd";
import Loader from "../../../component/Loader";
import RemarksModal from "../../Dashboard/component/RemarksModal";
import Timer from "./Timer";
import { resetprevReSubmissionState } from "../../../store/user/userSlice";
import SelectManger from "./SelectManger";
import ViewPrevNodeModal from "./ViewPrevNodeModal";
import RejectionModal from "./RejectionModal";
import { toast } from "react-toastify";
// import RemarksModal from "./RemarksModal";

const PendingTable = (props) => {
  const [showsubmission, setShowsubmission] = useState(false);
  const [showRejection, setShowRejection] = useState(false);
  const [showViewNode, setViewNode] = useState(false);
  const [rejectionModal, setRejectionModal] = useState(false);
  const [show, setShow] = useState(false);
  const [current, setCurrent] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [currentRequest, setCurrentRequest] = useState(0);
  const [timeData, setTimeData] = useState({});
  const [managerDesignation, setManagerDesignation] = useState(0);
  const [transtionId, setTranstionId] = useState(0);
  const pendingData = useSelector((state) => state.pendingData);
  const prevReSubmission = useSelector((state) => state.prevReSubmission);
  const dispatch = useDispatch();
  const [id, setId] = useState(0);
  const [prevId, setPrevId] = useState(0);
  const [trainId, setTrainId] = useState(0);

  useEffect(() => {
    dispatch(getpendingData({ ...props.selectBox, tab: props.tab }));
  }, [props.selectBox]);

  const onChange = (page) => {
    setCurrent(page);
    dispatch(getpendingData({ ...props.selectBox, page, tab: props.tab }));
  };
  // const preNodeSubmission = (id) => {
  //   dispatch(getprevReSubmission({ id }));
  // };
  const refreshNodeData = () => {
    dispatch(getpendingData({ ...props.selectBox, tab: props.tab }));
  };
  useEffect(() => {
    if (prevReSubmission.isSuccess) {
      dispatch(resetprevReSubmissionState());
      refreshNodeData();
    }
  }, [prevReSubmission]);

  const handleTimeUpdate = (updatedTime) => {
    setTimeData(updatedTime);
  };
  const rejectionNodeManager = (id, manager_designation) => {
    console.log("ID MANAGER DESIGNATION: ", id, manager_designation);
    if (id === 0) {
      toast.error("Please Select CheckBox");
      return;
    }
    if (manager_designation === 0) {
      toast.error("Please Select CheckBox");
      return;
    }
    setId(id);
    setManagerDesignation(manager_designation);
    setRejectionModal(false);
    setShowRejection(true);
  };

  const navigate = useNavigate();

  const handleSubmissionNode = (i) => {
    if (i.static_form_id === "ideal_consumption") {
      if (i.form_type.includes(0) && i.form_type.includes(1)) {
        navigate(`/ideal-consumption/${i.id}/1`);
        return;
      }
      if (i.form_type.includes(0)) {
        navigate(`/ideal-consumption/${i.id}/1`);
        return;
      }
      if (i.form_type.includes(0)) {
        navigate(`/ideal-consumption/${i.id}/0`);
        return;
      }
    }
    if (i.static_form_id === "product_data") {
      // images_addition
      if (i.form_type.includes(0) && i.form_type.includes(1)) {
        navigate(`/product-data/${i.id}/2`);
        return;
      }
      if (i.form_type.includes(0)) {
        navigate(`/product-data/${i.id}/0`);
        return;
      }
      if (i.form_type.includes(1)) {
        navigate(`/product-data/${i.id}/1`);
        return;
      }
    }
    if (i.static_form_id === "manufacture_order") {
      //images_addition
      if (i.form_type.includes(0) && i.form_type.includes(1)) {
        navigate(`/batch-level/${i.id}/2`);
        return;
      }
      if (i.form_type.includes(0)) {
        navigate(`/batch-level/${i.id}/0`);
        return;
      }
      if (i.form_type.includes(1)) {
        navigate(`/batch-level/${i.id}/1`);
        return;
      }
      // navigate(`/batch-level/${i.id}`);
      // return;
    }
    if (i.static_form_id === "images_selection") {
      navigate(`/image-color-selection/${i.id}`);
      return;
    }
    if (i.static_form_id === "images_addition") {
      navigate(`/image-color-addition/${i.id}`);
      return;
    }
    setShowsubmission(true);
    setTranstionId(i.id);
  };

  useEffect(() => {
    setTotalData(pendingData.data.totalTask);
    let sno = (current - 1) * 10 + 1;
    const FakeTableData =
      pendingData &&
      pendingData.data.task.map((item, i) => {
        const isHighPriority = item.priority === "high";
        return [
          <>
            <h6 className={isHighPriority ? "high-priority" : ""}>
              {sno + i}{" "}
            </h6>
          </>,
          <div>
            <h6>{item.SampleId}</h6>
          </div>,
          <div className="pending-table-img">
            <img src={process.env.REACT_APP_IMG_URL + item.image} alt="" />
            <h6>{item.taskName}</h6>
          </div>,
          <>
            <h6>{item.collectionName}</h6>
          </>,
          <>
            <h6>{item.nextTask}</h6>
          </>,
          <>
            <h6>
              Original
              <br />
              {item.start_date.original}
            </h6>
            <h6>
              Current <br />
              {item.start_date.current}
            </h6>
            <h6>
              Actual <br />
              {item.start_date.actual}
            </h6>
          </>,
          <>
            <h6>
              Original
              <br /> {item.end_date.original}
            </h6>
            <br />
            <h6>
              Current
              <br /> {item.end_date.current}
            </h6>
            <br />
            <h6>
              {" "}
              Actual
              <br /> {item.end_date.actual}
            </h6>
          </>,
          <>
            <h6>{item.delay} Days</h6>
          </>,

          <>
            <img src="/Images/notification.svg" alt="" />
          </>,
          <div>
            <h6> {item.submission} </h6>
          </div>,
          <div>
            <h6>{item.rejection_manager}</h6>
          </div>,
          <div>
            <Timer
              initialDays={item.timmer.d}
              hours={parseInt(item.timmer.h)}
              minutes={item.timmer.i}
              seconds={item.timmer.s}
              timerType={item.timmerType}
              onTimeUpdate={handleTimeUpdate}
              hold={item.hold}
            />
            {/* <h6>{item.timmer.weekday_behavior}</h6> */}
          </div>,

          <div class="bg-image-sm">
            <div class="team-pic">
              <div className="team-member">
                <img src="Images/setting.svg" alt="" />
                <span class="tooltiptext-sm">
                  <Link
                    onClick={() => {
                      setShow(true);
                      setCurrentRequest(item.sampleRequestId);
                    }}
                  >
                    Add Remarks
                  </Link>
                  {/* <Link to={"/node-workload/"+item.id}>Current node Workload</Link> */}
                  {/* <Link onClick={() => preNodeSubmission(item.id)}>Pre. Node Submission</Link> */}
                  {/* <Link to={"/submission-form/"+item.id}>Add Submission Current Node</Link> */}
                  <button
                    onClick={() => {
                      handleSubmissionNode(item);
                    }}
                  >
                    Add Submission Current Node
                  </button>
                  {/* <Link onClick={()=>{ setId(item.id);setManagerDesignation(item.manager_designation);setShowRejection(true)}}>Reject Previous Node submission</Link> */}
                  {parseInt(item.hold) === 0 && (
                    <Link
                      onClick={() => {
                        setTrainId(item.id);
                        setRejectionModal(true);
                        setPrevId(item.id);
                      }}
                    >
                      Reject Previous Node submission
                    </Link>
                  )}
                  <Link
                    onClick={() => {
                      setTrainId(item.id);
                      setViewNode(true);
                    }}
                  >
                    View Previous Node Submission
                  </Link>
                </span>
              </div>
            </div>
          </div>,
        ];
      });
    setTableData(FakeTableData);
  }, [pendingData.data]);

  const TemplateTableData = [
    {
      heading: "Sr.No.",
    },
    {
      heading: "Id",
    },
    {
      heading: "Tasks",
    },
    {
      heading: "Collections Name",
    },
    {
      heading: "Next Task",
    },
    {
      heading: "Start Dates",
    },
    {
      heading: "End Dates",
    },
    {
      heading: "Delay",
    },

    {
      heading: "Notify",
    },
    {
      heading: "Submission",
    },
    {
      heading: "Rejection Manager",
    },
    {
      heading: "Remaining Time",
    },
    {
      heading: "Actions",
    },
  ];
  return (
    <div className="position-relative">
      {pendingData.isLoading && (
        <div className="loader_bg">
          <Loader />
        </div>
      )}
      {prevReSubmission.isLoading && (
        <div className="loader-load">
          <Loader />
        </div>
      )}
      <div className="over-scroll-row">
        <Table
          gridTemplateColumnsHeader="5% 12% 15% 10% 10% 15% 15% 10% 10% 10% 10% 12% 10%"
          gridTemplateColumnsBody="5% 12% 15% 10% 10% 15% 15% 10% 10% 10% 10% 12% 10%"
          columns={TemplateTableData}
          data={tableData}
          isActives={true}
        />
        <Pagination current={current} onChange={onChange} total={totalData} />
      </div>
      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="remark-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Add Remarks</h4>
          <div className="close-icon">
            <img
              src="/Images/closeBlack.svg"
              onClick={() => setShow(false)}
              alt=""
            />
          </div>
        </div>
        <RemarksModal requestId={currentRequest} mClose={setShow} />
      </DefaultModal>
      <DefaultModal
        show={showsubmission}
        onHide={() => setShowsubmission(false)}
        className="remark-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Add Submission</h4>
          <div className="close-icon">
            <img
              src="/Images/closeBlack.svg"
              onClick={() => setShowsubmission(false)}
              alt=""
            />
          </div>
        </div>
        <SubmissionModal
          transtionId={transtionId}
          mClose={setShowsubmission}
          refreshNodeData={refreshNodeData}
        />
      </DefaultModal>
      <DefaultModal
        show={showRejection}
        onHide={() => setShowRejection(false)}
        className="person-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Select Manager</h4>
          <div className="close-icon">
            <img
              src="/Images/closeBlack.svg"
              onClick={() => setShowRejection(false)}
              alt=""
            />
          </div>
        </div>
        <div className="heading-border"></div>

        <SelectManger
          id={id}
          managerDesignation={managerDesignation}
          setShowRejection={setShowRejection}
          refreshNodeData={refreshNodeData}
          prevId={prevId}
        />
      </DefaultModal>
      <DefaultModal
        show={showViewNode}
        onHide={() => setViewNode(false)}
        className="view_node_modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Submission</h4>
          <div className="close-icon">
            <img
              src="/Images/closeBlack.svg"
              alt=""
              onClick={() => setViewNode(false)}
            />
          </div>
        </div>
        <div className="heading-border"></div>

        <ViewPrevNodeModal trainId={trainId} />
      </DefaultModal>
      <DefaultModal
        show={rejectionModal}
        onHide={() => setRejectionModal(false)}
        className="view_node_modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Submission</h4>
          <div className="close-icon">
            <img
              src="/Images/closeBlack.svg"
              onClick={() => setRejectionModal(false)}
              alt=""
            />
          </div>
        </div>
        <div className="heading-border"></div>

        <RejectionModal
          trainId={trainId}
          rejectionNodeManager={rejectionNodeManager}
        />
      </DefaultModal>
    </div>
  );
};

export default PendingTable;
