import React, { useEffect, useState } from "react";
import "./Styles/ImageColorAddition.css";
import { useDispatch, useSelector } from "react-redux";
import {
  ImageColorAdditionActions,
  getfilterData,
  // ImageColorAdditionAddActions,
} from "../../store/user/actions";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Button, Select } from "antd";
import Dropzone from "react-dropzone";
import axios from "axios";
import { getToken } from "../../util/Common";
import { toast } from "react-toastify";
// import { color, variant } from "styled-system";
import { convertDropdown } from "../CreateFit/CreateFit";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../component/Loader";
import { GrSubtractCircle } from "react-icons/gr";

const thumbsContainer = {
  marginTop: 16,
};
const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  marginBottom: 8,
  width: 90,
  objectFit: "cover",
  height: 90,
  overflowClipMargin: "unset",
  overflow: "hidden",
  boxSizing: "border-box",
};
const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};
const img = {
  display: "block",
  width: "100%",
  borderRadius: "5px",
  height: "100%",
  overflowClipMargin: "unset",
  overflow: "hidden",
};

const ImageColorAddition = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  // Get Image color Addition State
  const ImageColorAddition = useSelector((state) => state.ImageColorAddition);

  const filterData = useSelector((state) => state.filterData);

  useEffect(() => {
    dispatch(ImageColorAdditionActions(id));
    dispatch(getfilterData());
  }, [dispatch, id]);

  const [variations, setVariations] = useState([{ color_id: "", images: [] }]);

  const handleAddVariation = () => {
    setVariations([
      ...variations,
      {
        color_id: "",
        images: [],
      },
    ]);
  };

  const handleRemoveVariation = (index) => {
    setVariations(variations.filter((_, i) => i !== index));
  };

  const handleNameChange = (index, event) => {
    console.log("EVENT: ", event);
    const newVariations = [...variations];
    newVariations[index].color_id = event;
    setVariations(newVariations);
  };

  const handleFileChange = (index, acceptedFiles) => {
    const newVariations = [...variations];
    newVariations[index].images = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setVariations(newVariations);
  };

  const handleRemoveImage = (variationIndex, imageIndex) => {
    console.log("variations:", variations);
    console.log("variationIndex:", variationIndex);
    console.log("images:", variations[variationIndex]?.images);

    if (variations[variationIndex]) {
      const newVariations = [...variations];
      newVariations[variationIndex].images = newVariations[
        variationIndex
      ].images.filter((_, i) => i !== imageIndex);
      setVariations(newVariations);
    } else {
      console.error("Variation index is out of bounds");
    }
  };

  const renderThumbs = (images = [], variationIndex) =>
    images.map((file, index) => (
      <div className="render-thumbs-row" style={thumb} key={file.name}>
        <div style={thumbInner}>
          <Button onClick={() => handleRemoveImage(variationIndex, index)}>
            <GrSubtractCircle />
          </Button>
          <img
            alt=""
            src={file.preview}
            style={img}
            onLoad={() => {
              URL.revokeObjectURL(file.preview);
            }}
          />
        </div>
      </div>
    ));

  console.log("VARIATIONS: ", variations);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    const formData = new FormData();
    variations.forEach((i, index) => {
      formData.append(`data[${index}][color_id]`, i.color_id);
      i.images.forEach((j, ix) => {
        formData.append(`data[${index}][image][${ix}]`, j);
      });
    });

    setIsLoading(true);
    await axios({
      method: "POST",
      url:
        process.env.REACT_APP_API_URL +
        `/sample_request/color_image_addition_add/${id}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: formData,
    })
      .then((response) => {
        if (response?.data?.status === 0) {
          toast.error(response?.data?.message);
          setIsLoading(false);
        } else if (response?.data?.status === 1) {
          toast.success("Data Saved Successfully");
          setIsLoading(false);
          navigate("/pending-task");
        }
        // console.log(response);
        // toast.success(response?.data?.message);
      })
      .catch((error) => {
        setIsLoading(false);
        // console.log(error.response.data.message);
        toast.error(error?.response?.data?.message);
      });
  };

  console.log("FILTER DATA: ", filterData);

  const [color_arr, setColor_arr] = useState([]);

  useEffect(() => {
    if (filterData && filterData?.isSuccess === true) {
      let color_data = convertDropdown(filterData && filterData?.data?.color);
      setColor_arr(color_data);
    }
  }, [filterData]);

  console.log("I: ", ImageColorAddition);

  return (
    <div className="create-fit-wrapper">
      <div className="d-flex align-items-baseline justify-content-between pb-2">
        <div className="heading heading-row-master-fit">
          <img
            onClick={() => navigate(-1)}
            src="/Images/arrow-left.svg"
            alt="Back_Icon"
          />
          <h2>Image & Color Addition</h2>
        </div>
        <div className="download-but-excel"></div>
      </div>

      {ImageColorAddition?.isLoading ? (
        <div className="loader_icons">
          <Loader />
        </div>
      ) : (
        <>
          <div className="select-addition-wrapper">
            <div className="select-addition-grid-row">
              <div>
                <label className="labelx" htmlFor="">
                  Sample Collection Name
                </label>
                <br />
                <input
                  disabled={true}
                  type="text"
                  value={ImageColorAddition?.data?.collection}
                />
              </div>
              <div>
                <label className="labelx" htmlFor="">
                  Product Group
                </label>
                <br />
                <input
                  disabled={true}
                  type="text"
                  value={ImageColorAddition?.data?.product_group}
                />
              </div>
              <div>
                <label className="labelx" htmlFor="">
                  Child Category
                </label>
                <br />
                <input
                  disabled={true}
                  type="text"
                  value={ImageColorAddition?.data?.categorie}
                />
              </div>
            </div>
          </div>

          {variations.map((variation, index) => (
            <div key={index} className="color-variation-wrapper">
              <div
                className="color-variation-grid-row"
                style={{ gridTemplateColumns: "2fr 4fr" }}
              >
                <div>
                  <div style={{ justifyContent: "space-between" }}>
                    <label className="labelx" htmlFor="">
                      Color Variation Name
                    </label>
                    <Button onClick={handleAddVariation}>
                      <PlusOutlined />
                    </Button>
                    {variations.length > 1 && (
                      <Button onClick={() => handleRemoveVariation(index)}>
                        <MinusOutlined />
                      </Button>
                    )}
                  </div>
                  {/* <input
                type="text"
                value={variation.name}
                onChange={(e) => handleNameChange(index, e)}
              /> */}

                  <Select
                    className="mt-2"
                    options={color_arr}
                    onChange={(e) => handleNameChange(index, e)}
                  />
                </div>
                <div className="dropzone-row-wrapper">
                  <div>
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        handleFileChange(index, acceptedFiles)
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps({
                            className: "upload-file upload-images",
                          })}
                        >
                          <input {...getInputProps()} multiple />
                          <div>
                            <Button className="drop-zone-button">
                              <PlusOutlined />
                            </Button>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="aside-grid-row" style={thumbsContainer}>
                      {renderThumbs(variation.images || [], index)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="image-color-addition-cta">
            <Button onClick={handleSubmit}>
              {isLoading ? <Loader /> : "SUBMIT"}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default ImageColorAddition;
